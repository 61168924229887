import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "antd/dist/antd.min.css";
import "react-image-gallery/styles/css/image-gallery.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "./component/ScrollToTop";
import App from "./App.js";
import Camera from "./component/hardWareSubMenuPage/Camera";
import LiveStreming from "./component/hardWareSubMenuPage/LiveStreming";
import KeyCabinet from "./component/hardWareSubMenuPage/KeyCabinet";
import YouCheLai from "./component/softWareSubMenuPage/YouCheLai";
import Ecology from "./component/ecology/Ecology";
import Data from "./component/data/Data";
import About from "./component/aboutUs/About";
import EclologyCreate from "./component/ecology-create/EclologyCreate";
import Mobile from "./mobile/Mobile";
import MAbout from "./mobile/component/about/About";
import MYouchelai from "./mobile/component/youchelai/YouCheLai";
import MKey from "./mobile/component/hardWarePages/KeyCabinet";
import MLivng from "./mobile/component/hardWarePages/Living";
import MEclologyCreate from "./mobile/component/ecology-create/EclologyCreate";
import MData from "./mobile/component/data/Data";
import MHardWare from "./mobile/component/hardWarePages/HardWare";
import MSoftWare from "./mobile/component/softWare/SoftWare";
import MCarema from "./mobile/component/hardWarePages/Camera";
import MScrollToTop from "./mobile/MScrollToTop";
import MEcology from "./mobile/component/ecology/Ecology";

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <React.StrictMode>
      <Router>
        <MScrollToTop>
          <Routes>
            <Route path="/" exact element={<Mobile />}></Route>
            <Route path="/about" element={<MAbout />}></Route>
            <Route path="/youchelai" element={<MYouchelai />}></Route>
            <Route path="/key" element={<MKey />}></Route>
            <Route path="/living" element={<MLivng />}></Route>
            <Route path="/ecologyCreate" element={<MEclologyCreate />}></Route>
            <Route path="/data" element={<MData />}></Route>
            <Route path="/hard_ware" element={<MHardWare />}></Route>
            <Route path="/camera" element={<MCarema />}></Route>
            <Route path="/soft_ware" element={<MSoftWare />}></Route>
            <Route path="/ecology" element={<MEcology />}></Route>
          </Routes>
        </MScrollToTop>
      </Router>
    </React.StrictMode>
  );
} else {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <React.StrictMode>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/" exact element={<App />}></Route>
            <Route path="/hardWara/camera" element={<Camera />}></Route>
            <Route path="/hardWara/living" element={<LiveStreming />}></Route>
            <Route path="/hardWara/key" element={<KeyCabinet />}></Route>
            <Route path="/softWara/youchelai" element={<YouCheLai />}></Route>
            <Route path="/ecology/ecology" element={<Ecology />}></Route>
            <Route path="/data" element={<Data />}></Route>
            <Route path="/about" element={<About />}></Route>
            <Route
              path="/ecology-create/EcologyCreate"
              element={<EclologyCreate />}
            ></Route>
          </Routes>
        </ScrollToTop>
      </Router>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
