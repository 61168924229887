import "./style/pageStyle.css";
import "./style/homePage.css";
import { Space, Image } from "antd";
import SliderImage from "./SliderImage";
import React from "react";
import subLogo from "../static/slider/page_four_sub_logo@2x.png";
import contentImg from "../static/slider/page_six_right_img@2x.png";
// import SliderSix from "../static/slider/home_page_six@2x.png";
import SliderSix from "../static/slider/jpg/home_page_six@2x.jpg";

import sliderPreview from "../static/slider/home_page_six_preview.jpg";
import LearnMore from "./LearnMore";
import SubTitle from "./fontComponent/SubTitle";

const HomePageSix = (props) => {
  const { next, prev, animate } = props;
  return (
    <SliderImage
      img={SliderSix}
      placeholderImg={sliderPreview}
      next={next}
      prev={prev}
    >
      <Space direction="vertical" size={20}>
        <Image
          src={subLogo}
          width={117}
          height={58}
          preview={false}
          className={animate ? "slide-left-a" : ""}
        />
        <Space
          direction="vertical"
          className={`page_one_title ${animate ? "slide-left-c" : ""}`}
          size={1}
          style={{ whiteSpace: "nowrap" }}
        >
          <div>工位直播摄像头</div>
        </Space>
        <Space
          size={[20, 0]}
          wrap
          className={animate ? "slide-left-d" : ""}
          style={{ width: "300px" }}
        >
          <SubTitle color="#FFFFFF">施工实况直播</SubTitle>
          <SubTitle color="#FFFFFF">施工技师识别</SubTitle>
          <SubTitle color="#FFFFFF">车辆施工提醒</SubTitle>
          <SubTitle color="#FFFFFF">智能统计分析</SubTitle>
        </Space>
        <LearnMore
          href={"/hardWara/living"}
          top="75px"
          className={animate ? "slide-left-e" : ""}
        />
      </Space>
      <Image
        className={animate ? "slide-left-d" : ""}
        src={contentImg}
        width={508}
        preview={false}
        style={{ height: "860px" }}
      />
    </SliderImage>
  );
};

export default HomePageSix;
