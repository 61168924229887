import { useEffect, useState } from "react";
import { Image } from "antd";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import ask from "../static/menu/about/ask_to.png";

const ScrollToTop = (props) => {
  const { children } = props;
  const { pathname } = useLocation();
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    // window.scrollTo(0, 0);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, [pathname]);

  return (
    <>
      {pathname === "/about" ? (
        <a
          href="#ask"
          className={animate ? "slide-top" : ""}
          onMouseOver={() => {
            setAnimate(true);
          }}
          onMouseLeave={() => {
            setAnimate(false);
          }}
          style={{
            position: "fixed",
            bottom: 54,
            right: 50,
            zIndex: 20,
          }}
        >
          <Image src={ask} width={50} height={73} preview={false} />
        </a>
      ) : (
        <Link
          style={{
            position: "fixed",
            bottom: 54,
            right: 50,
            zIndex: 20,
          }}
          to={"/about#ask"}
          className={animate ? "slide-top" : ""}
          onMouseOver={() => {
            setAnimate(true);
          }}
          onMouseLeave={() => {
            setAnimate(false);
          }}
        >
          <a href="#ask">
            <Image src={ask} width={50} height={73} preview={false} />
          </a>
        </Link>
      )}
      {children}
    </>
  );
};

export default ScrollToTop;
