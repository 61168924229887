import React from "react";
import ImageWithContent from "./component/ImageWithContent";
import SLIDER_THREE from "../static/mobile/slider/slider_three.png";
import LOGO from "../static/mobile/slider/slide_three/logo.png";
import Text from "./component/Text";
import { Image } from "antd-mobile";
import SliderGo from "./component/SliderGo";
import { Link } from "react-router-dom";

const SliderThree = (props) => {
  const { height, top, className } = props;
  return (
    <Link to="/youchelai">
      <ImageWithContent
        className={className}
        top={top}
        img={SLIDER_THREE}
        marginRight={8}
        height={height}
        paddingTop={60}
        paddingLeft={30}
        paddingRight={30}
        contentJustify="flex-start"
        contentDirection="column"
      >
        <Image src={LOGO} width={108} height={35} />
        <Text fontFamily="bold" fontSize={28} color="#FFFFFF" top={10}>
          数智汽服操作系统
        </Text>
        <Text fontFamily="bold" fontSize={14} color="#FFFFFF" top={15}>
          洗美店、快修快保店、轮胎店
        </Text>
        <Text fontFamily="bold" fontSize={14} color="#FFFFFF">
          装潢店、综合修理厂
        </Text>
      </ImageWithContent>
      <SliderGo href="/youchelai" />
    </Link>
  );
};

export default SliderThree;
