/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import Menu from "./component/Menu";

const Layout = (props) => {
  const { children, background } = props;
  return (
    <Menu>
      <div
        style={{
          position: "absolute",
          top: -40,
          background: background || "white",
        }}
      >
        {children}
      </div>
    </Menu>
  );
};

export default Layout;
