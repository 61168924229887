/* eslint-disable react-hooks/exhaustive-deps */
import { Progress, Space } from "antd";
import React, { useEffect, useState, useImperativeHandle } from "react";
import Description from "./fontComponent/Description";
import Path from "./path/path";

let timer_one;
let timer_two;
let timer_three;
let timer_four;
let timer_five;
let timer_six;
const CountDown = (props, ref) => {
  const { startIndex = 0, goTo } = props;
  const [time_one, setTime_one] = useState(0);
  const [time_two, setTime_two] = useState(0);
  const [time_three, setTime_three] = useState(0);
  const [time_four, setTime_four] = useState(0);
  const [time_five, setTime_five] = useState(0);
  const [time_six, setTime_six] = useState(0);
  const [timeOneColor, setTimeOneColor] = useState(false);
  const [timeTwoColor, setTimeTwoColor] = useState(false);
  const [timeThreeColor, setTimeThreeColor] = useState(false);
  const [timeFourColor, setTimeFourColor] = useState(false);
  const [timeFiveColor, setTimeFiveColor] = useState(false);
  const [timeSixColor, setTimeSixColor] = useState(false);

  useImperativeHandle(ref, () => ({
    Go: (index) => {
      initTimeAndGo(index);
    },
  }));

  const initTimeAndGo = (index) => {
    setTimeOneColor(false);
    setTimeTwoColor(false);
    setTimeThreeColor(false);
    setTimeFourColor(false);
    setTimeFiveColor(false);
    setTimeSixColor(false);
    switchIndex(index);
  };

  const switchIndex = (Index) => {
    switch (Index) {
      case 0:
        setTimeOneColor(true);
        setTime_one(0);
        goTo(0);
        break;
      case 1:
        setTimeTwoColor(true);
        setTime_two(0);
        goTo(1);
        break;
      case 2:
        setTimeThreeColor(true);
        setTime_three(0);
        goTo(2);
        break;
      case 3:
        setTimeFourColor(true);
        setTime_four(0);
        goTo(3);
        break;
      case 4:
        setTimeFiveColor(true);
        setTime_five(0);
        goTo(4);
        break;
      case 5:
        setTimeSixColor(true);
        setTime_six(0);
        goTo(5);
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    switchIndex(startIndex);
    timer_one = setInterval(timeOne, 100);
    timer_two = setInterval(timeTwo, 100);
    timer_three = setInterval(timeThree, 100);
    timer_four = setInterval(timeFour, 100);
    timer_five = setInterval(timeFive, 100);
    timer_six = setInterval(timeSix, 100);
    return () => {
      clearInterval(timer_one);
      clearInterval(timer_two);
      clearInterval(timer_three);
      clearInterval(timer_four);
      clearInterval(timer_five);
      clearInterval(timer_six);
    };
  }, [startIndex]);

  useEffect(() => {
    if (time_one === 100 && timeOneColor) {
      setTimeOneColor(false);
      setTime_two(0);
      setTimeTwoColor(true);
      goTo(1);
    }
  }, [time_one]);

  useEffect(() => {
    if (time_two === 100 && timeTwoColor) {
      setTimeTwoColor(false);
      setTime_three(0);
      setTimeThreeColor(true);
      goTo(2);
    }
  }, [time_two]);

  useEffect(() => {
    if (time_three === 100 && timeThreeColor) {
      setTimeThreeColor(false);
      setTime_four(0);
      setTimeFourColor(true);
      goTo(3);
    }
  }, [time_three]);

  useEffect(() => {
    if (time_four === 100 && timeFourColor) {
      setTimeFourColor(false);
      setTime_five(0);
      setTimeFiveColor(true);
      goTo(4);
    }
  }, [time_four]);

  useEffect(() => {
    if (time_five === 100 && timeFiveColor) {
      setTimeFiveColor(false);
      setTime_six(0);
      setTimeSixColor(true);
      goTo(5);
    }
  }, [time_five]);

  useEffect(() => {
    if (time_six === 100 && timeSixColor) {
      setTimeSixColor(false);
      setTime_one(0);
      setTimeOneColor(true);
      goTo(0);
    }
  }, [time_six]);

  const timeOne = () => {
    setTime_one((prevCounter) => {
      return prevCounter + 2.5;
    });
  };

  const timeTwo = () => {
    setTime_two((prevCounter) => {
      return prevCounter + 2.5;
    });
  };

  const timeThree = () => {
    setTime_three((prevCounter) => {
      return prevCounter + 2.5;
    });
  };

  const timeFour = () => {
    setTime_four((prevCounter) => {
      return prevCounter + 2.5;
    });
  };

  const timeFive = () => {
    setTime_five((prevCounter) => {
      return prevCounter + 2.5;
    });
  };

  const timeSix = () => {
    setTime_six((prevCounter) => {
      return prevCounter + 2.5;
    });
  };

  return (
    <div
      style={{
        width: "100vw",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        position: "absolute",
        bottom: "50px",
        zIndex: 14,
      }}
    >
      <Space
        style={{
          width: 1200,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <div
          style={{
            width: "660px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Progress
            onClick={() => initTimeAndGo(0)}
            style={{ marginRight: 10, width: 110 }}
            percent={time_one}
            showInfo={false}
            strokeColor={timeOneColor ? "#12C194" : "gray"}
            trailColor="gray"
          />
          <Progress
            onClick={() => initTimeAndGo(1)}
            style={{ marginRight: 10, width: 110 }}
            percent={time_two}
            showInfo={false}
            strokeColor={timeTwoColor ? "#12C194" : "gray"}
            trailColor="gray"
          />
          <Progress
            onClick={() => initTimeAndGo(2)}
            style={{ marginRight: 10, width: 110 }}
            percent={time_three}
            showInfo={false}
            strokeColor={timeThreeColor ? "#12C194" : "gray"}
            trailColor="gray"
          />
          <Progress
            onClick={() => initTimeAndGo(3)}
            style={{ marginRight: 10, width: 110 }}
            percent={time_four}
            showInfo={false}
            strokeColor={timeFourColor ? "#12C194" : "gray"}
            trailColor="gray"
          />
          <Progress
            onClick={() => initTimeAndGo(4)}
            style={{ marginRight: 10, width: 110 }}
            percent={time_five}
            showInfo={false}
            strokeColor={timeFiveColor ? "#12C194" : "gray"}
            trailColor="gray"
          />
          <Progress
            onClick={() => initTimeAndGo(5)}
            style={{ marginRight: 10, width: 110 }}
            percent={time_six}
            showInfo={false}
            strokeColor={timeSixColor ? "#12C194" : "gray"}
            trailColor="gray"
          />
        </div>
        <Description
          fontSize="10px"
          color="#D1D3DA"
          height={10}
          lineHeight="0px"
          opacity={0.4}
        >
          <Path />
        </Description>
      </Space>
    </div>
  );
};

export default React.forwardRef(CountDown);
