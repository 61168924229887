import { Image } from "antd";
import React, { useState } from "react";
import arrowRight from "../static/slider/default_arrow_right.png";
import hightLightArrowRight from "../static/slider/hightlight_arrow_right.png";
import arrowLeft from "../static/slider/default_arrow_left.png";
import hightLightArrowLeft from "../static/slider/hightlight_arrow_left.png";

import "./style/pageStyle.css";

const SliderImage = (props) => {
  const { img, placeholderImg, children, next, prev } = props;
  const [onBlurLeftArrow, setOnBlurLeftArrow] = useState(false);
  const [onBlurRightArrow, setOnBlurRightArrow] = useState(false);
  return (
    <div
      style={{
        width: "100vw",
        position: "relative",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Image
        src={img}
        width="100vw"
        height="100vh"
        preview={false}
        placeholder={
          <Image
            preview={false}
            width="100vw"
            height="100vh"
            src={placeholderImg}
          />
        }
      />
      <div
        style={{
          position: "absolute",
          width: "100vw",
          height: "100vh",
          top: "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "1200px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {children}
        </div>
      </div>
      <div
        style={{ position: "absolute", top: "50%", right: "50px" }}
        onClick={() => next()}
        onMouseOver={() => {
          setOnBlurRightArrow(true);
        }}
        onMouseLeave={() => {
          setOnBlurRightArrow(false);
        }}
      >
        <Image
          src={onBlurRightArrow ? hightLightArrowRight : arrowRight}
          width={46}
          height={46}
          preview={false}
        />
      </div>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50px",
        }}
        onClick={() => prev()}
        onMouseOver={() => {
          setOnBlurLeftArrow(true);
        }}
        onMouseLeave={() => {
          setOnBlurLeftArrow(false);
        }}
      >
        <Image
          src={onBlurLeftArrow ? hightLightArrowLeft : arrowLeft}
          width={46}
          height={46}
          preview={false}
        />
      </div>
    </div>
  );
};

export default SliderImage;
