import React from "react";
import Path from "../../component/path/path";

const Footer = (props) => {
  const { style } = props;
  return (
    <div
      style={{
        ...style,
        fontSize: 11,
        color: "#343744",
        textAlign: "center",
        fontWeight: 400,
      }}
    >
      <Path />
    </div>
  );
};

export default Footer;
