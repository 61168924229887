import React, { useState, useEffect } from "react";
import Layout from "../../Layout";
import BG from "../../../static/mobile/pages/data/bg.png";
import ImageWithContent from "../ImageWithContent";
import { Image } from "antd-mobile";
import { Space } from "antd";
import Text from "../Text";
import LOGO from "../../../static/mobile/pages/data/logo.png";
import SERVE_ONE from "../../../static/mobile/pages/data/serve_one.png";
import SERVE_TWO from "../../../static/mobile/pages/data/serve_two.png";
import SERVE_THREE from "../../../static/mobile/pages/data/serve_three.png";
import SERVE_FOUR from "../../../static/mobile/pages/data/serve_four.png";
import ALO_ONE from "../../../static/mobile/pages/data/alo_one.png";
import ALO_TWO from "../../../static/mobile/pages/data/alo_two.png";
import ALO_THREE from "../../../static/mobile/pages/data/alo_three.png";
import ALO_FOUR from "../../../static/mobile/pages/data/alo_four.png";
import ALO_FIVE from "../../../static/mobile/pages/data/alo_five.png";
import ALO_SIX from "../../../static/mobile/pages/data/alo_six.png";
import ALO_SEVEN from "../../../static/mobile/pages/data/alo_seven.png";
import ALO_EIGHT from "../../../static/mobile/pages/data/alo_eight.png";

import SHARE_ONE from "../../../static/mobile/pages/data/share_one.png";
import SHARE_TWO from "../../../static/mobile/pages/data/share_two.png";
import SHARE_THREE from "../../../static/mobile/pages/data/share_three.png";

import S_ONE from "../../../static/mobile/pages/data/s_one.png";
import S_TWO from "../../../static/mobile/pages/data/s_two.png";

import PaddingBox from "../PaddingBox";
import Footer from "../Footer";

const serve = [
  {
    img: SERVE_ONE,
    title: "核验产品",
    subTitle: "车辆信息准确性验证服务",
  },
  {
    img: SERVE_TWO,
    title: "车牌产品",
    subTitle: "车辆精准化画像服务",
  },
  {
    img: SERVE_THREE,
    title: "VIN产品",
    subTitle: "车型车款精准解析服务",
  },
  {
    img: SERVE_FOUR,
    title: "车型产品",
    subTitle: "车型信息、维保手册、全车件",
  },
];

const algorithm = [
  {
    img: ALO_ONE,
    title: "OCR产品",
    subTitle: "车牌、VIN、行驶证、驾驶证图像降准识别服务,支持SDK",
  },
  {
    img: ALO_TWO,
    title: "二手车产品",
    subTitle: "二手车过户指数、置换指数、估值服务",
  },
  {
    img: ALO_THREE,
    title: "维保指数",
    subTitle: "车辆维修保养方案选择倾向评估",
  },
  {
    img: ALO_FOUR,
    title: "运营车辆指数",
    subTitle: "家用车运营指数分析服务",
  },
  {
    img: ALO_FIVE,
    title: "高速指数",
    subTitle: "高速指数算法服务、运力评估等",
  },
  {
    img: ALO_SIX,
    title: "年检指数",
    subTitle: "年检到期及年检方案查询服务",
  },
  {
    img: ALO_SEVEN,
    title: "洗车指数",
    subTitle: "洗车指数查询服务",
  },
  {
    img: ALO_EIGHT,
    title: "车产指数",
    subTitle: "企业及个人车产评估",
  },
];

const share = [
  {
    img: SHARE_ONE,
    title: "汽服标准库",
    subTitle: "汽车服务标准分类及服务规范",
  },
  {
    img: SHARE_TWO,
    title: "汽服项目库",
    subTitle: "汽车服务项目资料共享库",
  },
  {
    img: SHARE_THREE,
    title: "汽配商品库",
    subTitle: "汽车配件商品资料共享库、配件资料及适配信息共享库",
  },
];

const s = [
  {
    img: S_ONE,
    title: "标准API接口",
  },
  {
    img: S_TWO,
    title: "定制API接口",
  },
];

const Data = () => {
  const [osWidth, setOSWidth] = useState();
  const [width, setwidth] = useState();

  useEffect(() => {
    setwidth(document.documentElement.clientWidth);
    setOSWidth((document.documentElement.clientWidth - 40) / 2);
  }, []);
  return (
    <Layout>
      <ImageWithContent
        img={BG}
        paddingTop={135}
        paddingLeft={40}
        paddingRight={20}
        contentJustify="flex-start"
        contentDirection="column"
      >
        <Image src={LOGO} width={71} height={35} />
        <Text fontFamily="bold" fontSize={28} color="#FFFFFF">
          汽服大数据及算法服务
        </Text>
        <Text fontFamily="bold" fontSize={14} color="#FFFFFF" top={13}>
          汽车后市场数智底座
        </Text>
      </ImageWithContent>
      <Text
        fontFamily="bold"
        fontSize={20}
        color="#343744"
        top={50}
        style={{ textAlign: "center" }}
      >
        汽服数据服务
      </Text>
      <PaddingBox top={30}>
        <Space
          size={10}
          direction="vertical"
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          {serve.map((item) => {
            return (
              <ImageWithContent
                key={Math.random()}
                img={item.img}
                imgWidth={osWidth}
                paddingBottom={16}
                contentHeight="100%"
                contentDirection="column"
                contentJustify="flex-end"
                contentAlignItems="center"
              >
                <Text fontFamily="bold" fontSize={14} color="#343744">
                  {item.title}
                </Text>
                <Text
                  fontFamily="pingFang"
                  fontSize={10}
                  color="#343744"
                  opacity={0.5}
                  top={8}
                >
                  {item.subTitle}
                </Text>
              </ImageWithContent>
            );
          })}
        </Space>
      </PaddingBox>
      <Text
        fontFamily="bold"
        fontSize={20}
        color="#343744"
        top={50}
        style={{ textAlign: "center" }}
      >
        汽服算法服务
      </Text>
      <PaddingBox top={30}>
        <Space
          size={10}
          direction="vertical"
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          {algorithm.map((item) => {
            return (
              <div
                key={Math.random()}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: osWidth,
                  background: "#F4F8FB",
                  paddingTop: 30,
                  paddingLeft: 15,
                  paddingRight: 15,
                  paddingBottom: 22,
                  borderRadius: 9,
                }}
              >
                <Image src={item.img} width={60} height={60} />
                <Text fontFamily="bold" fontSize={14} color="#343744" top={20}>
                  {item.title}
                </Text>
                <Text
                  fontFamily="pingFang"
                  fontSize={10}
                  color="#343744"
                  style={{ textAlign: "center" }}
                  top={15}
                  opacity={0.5}
                >
                  {item.subTitle}
                </Text>
              </div>
            );
          })}
        </Space>
      </PaddingBox>

      <div
        style={{
          background: "#12C194",
          paddingTop: 60,
          paddingBottom: 50,
          marginTop: 20,
        }}
      >
        <Text
          fontFamily="bold"
          fontSize={24}
          color="#FFFFFF"
          style={{ textAlign: "center" }}
        >
          汽服标准共享平台
        </Text>
        <PaddingBox top={40}>
          <Space direction="vertical" size={20}>
            {share.map((item) => {
              return (
                <div
                  key={Math.random()}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Image src={item.img} width={130} height={130} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: width - 180,
                    }}
                  >
                    <Text fontFamily="bold" fontSize={18} color="#FFFFFF">
                      {item.title}
                    </Text>
                    <Text
                      fontFamily="pingFang"
                      fontSize={14}
                      color="#FFFFFF"
                      top={15}
                    >
                      {item.subTitle}
                    </Text>
                  </div>
                </div>
              );
            })}
          </Space>
        </PaddingBox>
      </div>

      <Text
        fontFamily="bold"
        fontSize={20}
        color="#343744"
        top={50}
        style={{ textAlign: "center" }}
      >
        服务模式
      </Text>

      <PaddingBox top={30}>
        <Space size={20} direction="vertical">
          {s.map((item) => {
            return (
              <div
                key={Math.random()}
                style={{
                  background: "#FFFFFF",
                  width: width - 30,
                  border: "3px solid rgba(244,248,251,1)",
                  borderRadius: 10,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingTop: 20,
                  paddingBottom: 20,
                  paddingLeft: 35,
                  paddingRight: 25,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Text fontFamily="bold" fontSize={16} color="#343744">
                    {item.title}
                  </Text>
                </div>
                <Image src={item.img} width={120} height={120} />
              </div>
            );
          })}
        </Space>
      </PaddingBox>
      <Footer style={{ marginTop: 65, marginBottom: 15 }} />
    </Layout>
  );
};

export default Data;
