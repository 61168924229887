import { Image } from "antd";
import React from "react";
import Layout from "../Layout";

const PageImage = (props) => {
  const { img, children, slImg, width, offsetX, className } = props;
  return (
    <Layout>
      <div
        className={className}
        style={{
          width: width || "100vw",
          position: "relative",
          height: "680px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {img && (
          <Image
            src={img}
            width={width || "100vw"}
            style={{ height: "680px", position: "relative", right: offsetX }}
            preview={false}
            placeholder={
              <Image preview={false} src={slImg} width="100vw" height={680} />
            }
          />
        )}
        <div
          style={{
            position: "absolute",
            width: "100vw",
            height: "680px",
            top: "0",
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "1200px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {children}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PageImage;
