/* eslint-disable react-hooks/exhaustive-deps */
import { Space, Image } from "antd";
import React, { useEffect, useState } from "react";
import PageImage from "../PageImage";
// import backgroundImg from "../../static/menu/ecology/ecology_background.png";
import backgroundImgSl from "../../static/menu/ecology/ecology_background_sl.jpg";

import subLogo from "../../static/menu/ecology/ecology_logo.png";

import EcologyOne from "../../static/menu/ecology/ecology_one_background.png";
import EcologyTwo from "../../static/menu/ecology/ecology_two_background.png";
import EcologyThree from "../../static/menu/ecology/ecology_three_background.png";
import EcologyFour from "../../static/menu/ecology/ecology_four_background.png";
import EcologyFive from "../../static/menu/ecology/ecology_five_background.png";

import rightImg_one from "../../static/menu/ecology/ecology_one_right_img.png";
import rightImg_two from "../../static/menu/ecology/ecology_two_right_img.png";
import rightImg_three from "../../static/menu/ecology/ecology_three_right_img.png";
import rightImg_four from "../../static/menu/ecology/ecology_four_right_img.png";
import rightImg_five from "../../static/menu/ecology/ecology_five_right_img.png";

import logoOne from "../../static/menu/ecology/ecology_one_logo.png";
import logoTwo from "../../static/menu/ecology/ecology_two_logo.png";
import logoThree from "../../static/menu/ecology/ecology_three_logo.png";
import logoFour from "../../static/menu/ecology/ecology_four_logo.png";
import logoFive from "../../static/menu/ecology/ecology_five_logo.png";

import ImageWithContent from "../ImageWithContent";
import Title from "../fontComponent/Title";
import SubTitle from "../fontComponent/SubTitle";
import LearnMoreRadius from "../../component/LearnMoreRadius";

import PageFooter from "../PageFooter";

function Ecology() {
  const handleScroll = () => {
    const rightOneEle = document.getElementById("right_one");
    const rightTwoEle = document.getElementById("right_two");
    const rightThreeEle = document.getElementById("right_three");
    const rightFourEle = document.getElementById("right_four");
    const rightFiveEle = document.getElementById("right_five");

    isInViewPort(rightOneEle) && setAnimateOne(true);
    isInViewPort(rightTwoEle) && setAnimateTwo(true);
    isInViewPort(rightThreeEle) && setAnimateThree(true);
    isInViewPort(rightFourEle) && setAnimateFour(true);
    isInViewPort(rightFiveEle) && setAnimateFive(true);
  };

  const isInViewPort = (element) => {
    // const viewWidth = window.innerWidth || document.documentElement.clientWidth;
    const viewHeight =
      window.innerHeight || document.documentElement.clientHeight;
    const { top } = element?.getBoundingClientRect();
    return top < viewHeight;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
    return window.removeEventListener("scroll", handleScroll);
  }, []);

  const [rightOne, setAnimateOne] = useState(false);
  const [rightTwo, setAnimateTwo] = useState(false);
  const [rightThree, setAnimateThree] = useState(false);
  const [rightFour, setAnimateFour] = useState(false);
  const [rightFive, setAnimateFive] = useState(false);

  return (
    <>
      <PageImage
        // img={backgroundImg}
        slImg={backgroundImgSl}
        // width={1920}
        className="ecology_background"
      >
        <Space
          direction="vertical"
          style={{ width: "426px" }}
          size={20}
          className="slide-right"
        >
          <Space>
            <Title color="white">基于</Title>
            <Image src={subLogo} width={114} height={44} preview={false} />
          </Space>
          <Space direction="vertical" size={1} style={{ whiteSpace: "nowrap" }}>
            <Title color="white" textAlign="start">
              构建以车主为中心的
            </Title>
            <Title color="white">网络即时协同C2S2b汽服生态系</Title>
          </Space>
        </Space>
      </PageImage>

      <Space
        size={100}
        direction="vertical"
        style={{
          marginTop: 100,
          width: "100vw",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginBottom: 140,
        }}
      >
        <ImageWithContent
          width={1200}
          height={610}
          img={EcologyOne}
          id="right_one"
          contentAlignItems="center"
          containerAlignItems="flex-start"
          contentJustify="space-between"
          paddingRight={130}
          paddingTop={70}
          paddingLeft={150}
          className={rightOne ? "slide-in-bottom" : ""}
        >
          <Space
            direction="vertical"
            size={60}
            style={{ position: "relative", top: -30 }}
          >
            <Image src={logoOne} width={230} height={55} preview={false} />
            <Space direction="vertical">
              <Title>养车智能顾问APP</Title>
              <SubTitle textAlign="start" fontSize="25px" lineHeight="40px">
                让用车养车明明白白
              </SubTitle>
            </Space>
          </Space>
          <Image src={rightImg_one} width={383} height={530} preview={false} />
        </ImageWithContent>

        <ImageWithContent
          width={1200}
          height={610}
          img={EcologyTwo}
          paddingLeft={150}
          paddingRight={130}
          id="right_two"
          contentAlignItems="center"
          contentJustify="space-between"
          className={rightTwo ? "slide-in-bottom" : ""}
          containerAlignItems="center"
        >
          <Space direction="vertical" size={60}>
            <Image src={logoTwo} width={250} height={55} preview={false} />
            <Space direction="vertical">
              <Title color="white" textAlign="start" nowrap>
                社区一站式便捷养车连锁
              </Title>
              <SubTitle textAlign="start" fontSize="25px" color="#FFFFFF">
                重新定义养车门店
              </SubTitle>
            </Space>
          </Space>
          <Image src={rightImg_two} width={418} height={476} preview={false} />
          <LearnMoreRadius href={"/ecology-create/EcologyCreate"} />
        </ImageWithContent>

        <ImageWithContent
          width={1200}
          height={610}
          img={EcologyThree}
          id="right_three"
          paddingLeft={150}
          paddingRight={100}
          paddingBottom={11}
          contentAlignItems="center"
          contentJustify="space-between"
          className={rightThree ? "slide-in-bottom" : ""}
        >
          <Space
            direction="vertical"
            size={60}
            style={{ position: "relative", top: -50 }}
          >
            <Image src={logoThree} width={198} height={55} preview={false} />
            <Space direction="vertical" size={15}>
              <Title nowrap color="#404558">
                办公极致洗护上门服务
              </Title>
              <SubTitle textAlign="start" lineHeight="0px">
                洗美社区店增收创收
              </SubTitle>
            </Space>
          </Space>
          <Image
            src={rightImg_three}
            width={383}
            height={530}
            preview={false}
          />
        </ImageWithContent>

        <ImageWithContent
          width={1200}
          height={610}
          img={EcologyFour}
          id="right_four"
          paddingLeft={150}
          paddingRight={100}
          contentAlignItems="center"
          contentJustify="space-between"
          containerAlignItems="center"
          className={rightFour ? "slide-in-bottom" : ""}
        >
          <Space direction="vertical" size={60}>
            <Image src={logoFour} width={231} height={55} preview={false} />
            <Space direction="vertical" size={10}>
              <Title style={{ whiteSpace: "nowrap" }}>
                本地共享服务中央工厂
              </Title>
              <SubTitle textAlign="start" fontSize={25}>
                改装｜装潢｜大修｜钣金喷漆
              </SubTitle>
            </Space>
          </Space>
          <Image src={rightImg_four} width={450} height={450} preview={false} />
        </ImageWithContent>

        <ImageWithContent
          width={1200}
          height={610}
          img={EcologyFive}
          id="right_five"
          paddingLeft={150}
          paddingBottom={38}
          contentAlignItems="center"
          contentJustify="space-between"
          className={rightFive ? "slide-in-bottom" : ""}
        >
          <Space
            direction="vertical"
            size={60}
            style={{ position: "relative", top: -50 }}
          >
            <Image src={logoFive} width={121} height={55} preview={false} />
            <Space direction="vertical">
              <Title>配件先人一步</Title>
              <SubTitle textAlign="start" fontSize={25}>
                易损件前置服务
              </SubTitle>
            </Space>
          </Space>
          <Image src={rightImg_five} width={650} height={435} preview={false} />
        </ImageWithContent>
      </Space>
      <PageFooter />
    </>
  );
}

export default Ecology;
