import React from "react";
import ImageWithContent from "./component/ImageWithContent";
import SLIDER_FOUR from "../static/mobile/slider/slider_four.png";
import LOGO from "../static/mobile/slider/slide_four/logo.png";
import Text from "./component/Text";
import { Image } from "antd-mobile";
import SliderGo from "./component/SliderGo";
import { Link } from "react-router-dom";

const SliderFour = (props) => {
  const { height, top, className } = props;

  return (
    <Link to="/key">
      <ImageWithContent
        className={className}
        top={top}
        img={SLIDER_FOUR}
        height={height}
        marginRight={8}
        paddingTop={60}
        paddingLeft={30}
        paddingRight={30}
        contentJustify="flex-start"
        contentDirection="column"
      >
        <Image src={LOGO} width={81} height={35} />
        <Text fontFamily="bold" fontSize={28} color="#FFFFFF" top={10}>
          智能养车钥匙柜
        </Text>
        <Text fontFamily="bold" fontSize={14} color="#FFFFFF" top={15}>
          取代收银前台
        </Text>
        <Text fontFamily="bold" fontSize={14} color="#FFFFFF">
          门店无人值守接还车
        </Text>
      </ImageWithContent>
      <SliderGo href="/key" />
    </Link>
  );
};

export default SliderFour;
