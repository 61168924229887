import React, { useEffect, useState } from "react";
import Layout from "../../Layout";
import BG from "../../../static/mobile/pages/key/bg.png";
import ImageWithContent from "../ImageWithContent";
import { Image, Swiper } from "antd-mobile";
import Text from "../Text";
import LOGO from "../../../static/mobile/slider/slide_four/logo.png";
import KEY_ONE from "../../../static/mobile/pages/key/key_one.png";
import KEY_TWO from "../../../static/mobile/pages/key/key_two.png";
import KEY_THREE from "../../../static/mobile/pages/key/key_three.png";
import KEY_FOUR from "../../../static/mobile/pages/key/key_four.png";

import KEY_FIVE from "../../../static/mobile/pages/key/key_five.png";
import KEY_SIX from "../../../static/mobile/pages/key/key_six.png";

import SL_ONE from "../../../static/mobile/pages/key/sl_one.png";
import SL_TWO from "../../../static/mobile/pages/key/sl_two.png";

import S_ONE from "../../../static/mobile/pages/key/key_serve_one.png";
import S_TWO from "../../../static/mobile/pages/key/key_serve_two.png";

import PaddingBox from "../PaddingBox";
import Footer from "../Footer";
import { Space } from "antd";
import KeyParams from "./KeyParams";

const sl = [
  {
    img: SL_ONE,
  },
  {
    img: SL_TWO,
  },
];

const items = [
  {
    img: KEY_FIVE,
    title: ["7*24小时监控值守", "确保钥匙安全"],
    desc: ["多家门店超10万单安全验证", "钥匙安全零风险"],
  },
  {
    img: KEY_SIX,
    title: ["智能柜多行业已普及", "客户接受度100%"],
    desc: ["无需教育无需培训", "客户使用顺畅自然"],
  },
];

const serve = [
  {
    img: S_ONE,
    title: "硬件+标准应用",
    subTitle: "适用于汽服门店",
  },
  {
    img: S_TWO,
    title: "硬件+API接口",
    subTitle: "适用于具有开发能力的客户",
  },
];

const KeyCabinet = () => {
  const [osWidth, setOSWidth] = useState();
  const [bgWidth, setBgWidth] = useState();

  useEffect(() => {
    setOSWidth(document.documentElement.clientWidth - 30);
    setBgWidth((document.documentElement.clientWidth - 40) / 2);
  }, []);

  return (
    <Layout>
      <ImageWithContent
        img={BG}
        paddingTop={135}
        paddingLeft={40}
        paddingRight={45}
        contentJustify="flex-start"
        contentDirection="column"
      >
        <Image src={LOGO} width={81} height={35} />
        <Text fontFamily="bold" fontSize={28} color="#FFFFFF" top={3}>
          智能养车钥匙柜
        </Text>
        <Text fontFamily="bold" fontSize={14} color="#FFFFFF" top={12}>
          取代收银前台
        </Text>
        <Text fontFamily="bold" fontSize={14} color="#FFFFFF" top={0}>
          门店无人值守接还车
        </Text>
      </ImageWithContent>
      <PaddingBox top={20}>
        <ImageWithContent
          img={KEY_ONE}
          paddingTop={43}
          paddingLeft={35}
          contentDirection="column"
          contentJustify="flex-start"
        >
          <Text
            fontFamily="bold"
            fontSize={18}
            color="#343744"
            style={{ width: 147 }}
          >
            钥匙柜还车，无需前台收银，一年节省5万元
          </Text>
          <Text
            fontFamily="normal"
            fontSize={10}
            top={10}
            color="#343744"
            opacity={0.65}
          >
            施工完毕后统一使用钥匙柜还车
          </Text>
          <Text
            fontFamily="normal"
            fontSize={10}
            color="#343744"
            opacity={0.65}
          >
            车主先支付后开箱取钥匙
          </Text>
        </ImageWithContent>
      </PaddingBox>
      <PaddingBox top={20} bottom={20}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <ImageWithContent
            img={KEY_TWO}
            imgWidth={bgWidth}
            paddingTop={40}
            contentDirection="column"
            contentJustify="flex-start"
            contentAlignItems="center"
          >
            <Text fontSize={18} color="#343744" fontFamily="bold">
              24小时接车还车
            </Text>
            <Text
              fontSize={10}
              top={10}
              color="#343744"
              fontFamily="normal"
              opacity={0.65}
            >
              开门前自动接车
            </Text>
            <Text
              fontSize={10}
              color="#343744"
              fontFamily="normal"
              opacity={0.65}
            >
              闭店后自动还车
            </Text>
          </ImageWithContent>
          <ImageWithContent
            img={KEY_THREE}
            imgWidth={bgWidth}
            paddingTop={40}
            contentDirection="column"
            contentJustify="flex-start"
            contentAlignItems="center"
          >
            <Text fontSize={18} color="#343744" fontFamily="bold">
              钥匙存入柜
            </Text>
            <Text fontSize={18} color="#343744" fontFamily="bold">
              客户更安心
            </Text>
            <Text
              fontSize={10}
              top={5}
              color="#343744"
              fontFamily="normal"
              opacity={0.65}
            >
              避免技师动车
            </Text>
            <Text
              fontSize={10}
              color="#343744"
              fontFamily="normal"
              opacity={0.65}
            >
              只能车主开箱取钥匙
            </Text>
          </ImageWithContent>
        </div>
      </PaddingBox>

      <ImageWithContent
        img={KEY_FOUR}
        paddingTop={60}
        contentDirection="column"
        contentAlignItems="center"
      >
        <Text fontFamily="bold" fontSize={20} color="#FFFFFF">
          钥匙柜前置社区楼宇电梯厅
        </Text>
        <Text fontFamily="bold" fontSize={20} color="#FFFFFF">
          停车时段 顺手养车
        </Text>
        <Text
          fontFamily="normal"
          fontSize={12}
          color="#FFFFFF"
          opacity={0.5}
          top={15}
        >
          写字楼车主停车后，等电梯顺手养车
        </Text>
        <Text fontFamily="normal" fontSize={12} color="#FFFFFF" opacity={0.5}>
          住宅小区车主回家停车后，等电梯顺手养车
        </Text>
        <PaddingBox top={50}>
          <Swiper
            indicatorProps={{
              color: "white",
              style: {
                "--active-dot-size": "34px",
                "--dot-color": "rgba(52,55,68,0.5)",
                "--dot-spacing": "8px",
                "--dot-size": "10px",
              },
            }}
            style={{
              "--track-padding": " 0 0 40px",
            }}
          >
            {sl.map((item) => {
              return (
                <Swiper.Item>
                  <Image src={item.img} />
                </Swiper.Item>
              );
            })}
          </Swiper>
        </PaddingBox>
      </ImageWithContent>
      <PaddingBox top={20}>
        <Space direction="vertical" size={20}>
          {items.map((item) => {
            return (
              <ImageWithContent
                img={item.img}
                paddingTop={40}
                paddingLeft={138}
                contentDirection="column"
              >
                <div>
                  {item.title.map((el) => {
                    return (
                      <Text fontSize={20} fontFamily="bold" color="#FFFFFF">
                        {el}
                      </Text>
                    );
                  })}
                </div>
                <div style={{ marginTop: 15 }}>
                  {item.desc.map((el) => {
                    return (
                      <Text fontSize={12} fontFamily="normal" color="#FFFFFF">
                        {el}
                      </Text>
                    );
                  })}
                </div>
              </ImageWithContent>
            );
          })}
        </Space>
      </PaddingBox>
      <Text
        fontFamily="bold"
        fontSize={20}
        color="#343744"
        top={50}
        bottom={45}
        style={{ textAlign: "center" }}
      >
        技术参数
      </Text>
      <KeyParams />

      <Text
        color="#343744"
        fontFamily="bold"
        fontSize={20}
        top={50}
        style={{ textAlign: "center" }}
      >
        服务模式
      </Text>
      <PaddingBox top={30}>
        <Space size={20} direction="vertical">
          {serve.map((item) => {
            return (
              <div
                style={{
                  background: "#FFFFFF",
                  width: osWidth,
                  border: "3px solid rgba(244,248,251,1)",
                  borderRadius: 10,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingTop: 13,
                  paddingBottom: 12,
                  paddingLeft: 35,
                  paddingRight: 25,
                }}
              >
                <Space direction="vertical" size={0}>
                  <Text
                    fontFamily="bold"
                    fontSize={16}
                    color="#343744"
                    top={43}
                  >
                    {item.title}
                  </Text>
                  <Text
                    fontFamily="pingFang"
                    fontSize={12}
                    color="#343744"
                    top={8}
                  >
                    {item.subTitle}
                  </Text>
                  <Text fontFamily="pingFang" fontSize={12} color="#343744">
                    支持LOGO定制
                  </Text>
                </Space>
                <Image src={item.img} width={120} height={135} />
              </div>
            );
          })}
        </Space>
      </PaddingBox>

      <Footer style={{ marginTop: 63, marginBottom: 15 }}></Footer>
    </Layout>
  );
};

export default KeyCabinet;
