import React, { useEffect, useState } from "react";
import Layout from "../../Layout";
import { Image } from "antd-mobile";
import { Space } from "antd";
import Text from "../Text";

import ONE from "../../../static/mobile/menu/hardWare/h_one.png";
import TWO from "../../../static/mobile/menu/hardWare/h_two.png";
import THREE from "../../../static/mobile/menu/hardWare/h_three.png";
import FOUR from "../../../static/mobile/menu/hardWare/h_four.png";
import FIVE from "../../../static/mobile/menu/hardWare/h_five.png";
import SIX from "../../../static/mobile/menu/hardWare/h_six.png";
import SEVEN from "../../../static/mobile/menu/hardWare/h_seven.png";
import EIGHT from "../../../static/mobile/menu/hardWare/h_eight.png";
import { Link } from "react-router-dom";

import PaddingBox from "../PaddingBox";
import Footer from "../Footer";

const HARD_WARE_SUB_MENU = [
  {
    img: ONE,
    text: "车辆画像摄像头",
    subText: "4G版",
    href: "/camera",
  },
  {
    img: TWO,
    text: "工位直播摄像头",
    href: "/living",
  },
  {
    img: THREE,
    text: "智能养车钥匙柜",
    subText: "G18",
    href: "/key",
  },
  {
    img: FOUR,
    text: "智能养车钥匙柜",
    subText: "X18",
    href: "/key",
  },
  {
    img: FIVE,
    text: "智能养车钥匙柜",
    subText: "X32",
    href: "/key",
  },
  {
    img: SIX,
    text: "商机/任务看板",
    subText: "Y32",
    href: "",
  },
  {
    img: SEVEN,
    text: "云打印机",
    href: "",
  },
  {
    img: EIGHT,
    text: "门店实况摄像头",
    subText: "P20A2",
    href: "",
  },
];

const HardWare = () => {
  const [bgWidth, setBgWidth] = useState();

  useEffect(() => {
    setBgWidth((document.documentElement.clientWidth - 60) / 2);
  }, []);

  return (
    <Layout background="#151618">
      <PaddingBox pad={20} top={105}>
        <Space
          direction="vertical"
          size={20}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          {HARD_WARE_SUB_MENU.map((item) => {
            return (
              <Link
                key={Math.random()}
                to={item.href}
                style={{
                  background: "#FFFFFF",
                  borderRadius: 10,
                  width: bgWidth,
                  paddingTop: 20,
                  paddingBottom: 20,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Image src={item.img} width={110} height={150} />
                <Text
                  fontSize={14}
                  fontFamily="pingFang"
                  color="#343744"
                  top={8}
                >
                  {item.text}
                </Text>
                <Text fontSize={12} fontFamily="pingFang" color="#343744">
                  {item.subText ? item.subText : ""}
                  &nbsp;
                </Text>
              </Link>
            );
          })}
        </Space>
      </PaddingBox>
      <Footer style={{ marginTop: 45, marginBottom: 15 }}></Footer>
    </Layout>
  );
};

export default HardWare;
