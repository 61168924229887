import React, { useEffect, useState } from "react";
import { Space } from "antd";
import Text from "../Text";
import SplitLine from "./SplitLine";

import PaddingBox from "../PaddingBox";

const techologyParams = [
  {
    title: "算法参数",
    content: [],
  },
  {
    title: "光学镜头",
    type: "size",
    content: [
      [
        "综合识别率：99.93%",
        "新能源车牌识别率：≥99.9%",
        "适应车速范围：0~30Kmh",
        "车牌识别特征：车牌号码、车牌颜色、车辆类型、车标、车款",
      ],
      [
        "识别车牌类型：蓝牌、新能源、警车、武警、单层军牌、双层军牌、单层黄牌、双层黄牌、挂车、港牌、澳牌、使馆、领馆、教练牌、民航牌、应急车牌",
        "触发方式：视频触发、外部触发",
      ],
    ],
  },
  {
    title: "视频参数",
    type: "size",
    content: [
      ["传感器:1\\2.8”CMOS", "宽动态：≥100db", "有效像素：2304*1296"],
      [
        "镜头：定焦6mm镜头",
        "最低照度：0.1LUX 彩色（补白光）",
        "信噪比：≥50db(AGC OFF)",
      ],
    ],
  },
  {
    title: "通讯",
    type: "size",
    content: [
      [
        "压缩输出码率：512Kbps~5000Kbps",
        "OSD信息叠加：支持可定义时间，地点，码流信息等",
        "录像：支持PCSD卡NVR录像",
      ],
      ["视频压缩标准：H.264H.265MJPEG", "视频分辨率：1920*1080、2304*1296"],
    ],
  },
  {
    type: "size",
    content: [
      [
        "通讯协议：SDK、ONVIF、HTTP、RTSP、TCPIP、UDP、RS485、IO、NTP",
        "FTP：支持通过FTP上传抓拍图片至服务器",
        "HTTP推送：支持上传识别结果、离线重传",
      ],
      [
        "UPNP端口映射：支持自动映射HTTPRSTP通讯端口",
        "4G扩展：通过USB扩展全网通4G模块",
        "云管理：远程管理单台相机、通过账号集中管理多台相机、支持云SDK开发管理平台",
      ],
    ],
  },
];

const LivingParams = () => {
  const [osWidth, setOSWidth] = useState();

  useEffect(() => {
    setOSWidth((document.documentElement.clientWidth - 45) / 2);
  }, []);

  const renderSize = (items) => {
    return (
      <>
        <PaddingBox>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {items.content.map((item) => {
              return (
                <div key={Math.random()} style={{ width: osWidth }}>
                  <Space direction="vertical" size={0}>
                    <Text color="#404558" fontSize={10} fontFamily="pingFang">
                      {item[0]}
                    </Text>
                    <Text color="#404558" fontSize={10} fontFamily="pingFang">
                      {item[1]}
                    </Text>
                    <Text color="#404558" fontSize={10} fontFamily="pingFang">
                      {item[2]}
                    </Text>
                    <Text color="#404558" fontSize={10} fontFamily="pingFang">
                      {item[3]}
                    </Text>
                    <Text color="#404558" fontSize={10} fontFamily="pingFang">
                      {item[4]}
                    </Text>
                  </Space>
                </div>
              );
            })}
          </div>
        </PaddingBox>
        {items.title ? (
          <SplitLine name={items.title} top={40} bottom={20} />
        ) : (
          ""
        )}
      </>
    );
  };

  return techologyParams.map((item) => {
    return <div key={Math.random()}>{renderSize(item)}</div>;
  });
};

export default LivingParams;
