/* eslint-disable react-hooks/exhaustive-deps */
import "./style/hardware.css";
import { Space, Image } from "antd";
import React, { useEffect, useState } from "react";
import PageImage from "../PageImage";
import backgroundImg from "../../static/slider/jpg/home_page_four@2x.jpg";
import ImageGallery from "react-image-gallery";
import subLogo from "../../static/slider/page_four_sub_logo@2x.png";
import contentImg from "../../static/menu/hardware/pages/key_right_img@2x.png";
import SubMenu from "../SubMenu";
import KeyOne from "../../static/menu/hardware/pages/key_one.png";
import KeyTwo from "../../static/menu/hardware/pages/key_two.png";
import KeyThree from "../../static/menu/hardware/pages/key_three.png";
import KeyFour from "../../static/menu/hardware/pages/key_four.png";
import KeyFive from "../../static/menu/hardware/pages/key_five.png";

import rightImg_one from "../../static/menu/hardware/pages/key_one_right_img.png";
import rightImg_two from "../../static/menu/hardware/pages/key_two_right_img.png";
import rightImg_three from "../../static/menu/hardware/pages/key_three_right_img.png";
import rightImg_four from "../../static/menu/hardware/pages/key_four_right_img.png";
import rightImg_five from "../../static/menu/hardware/pages/key_five_right_img.png";

import ImageWithContent from "../ImageWithContent";
import Title from "../fontComponent/Title";
import SubTitle from "../fontComponent/SubTitle";
import Description from "../fontComponent/Description";

import serve_mode_one from "../../static/menu/hardware/pages/key_serve_one.png";
import serve_mode_two from "../../static/menu/hardware/pages/key_serve_two.png";
import PageFooter from "../PageFooter";

import pictureOne from "../../static/menu/hardware/pages/key_picture_one.png";
import pictureOneSmall from "../../static/menu/hardware/pages/key_picture_one_small.png";
import pictureTwo from "../../static/menu/hardware/pages/key_picture_two.png";
import pictureTwoSmall from "../../static/menu/hardware/pages/key_picture_two_small.png";

import RightNav from "./RightNav";
import LeftNav from "./LeftNav";
import KeyParams from "./keyParams";

const items = [
  {
    desc: "feature",
    name: "功能介绍",
  },
  {
    desc: "params",
    name: "技术参数",
  },
  {
    desc: "serve",
    name: "服务模式",
  },
];

const serveMode = [
  {
    title: "硬件+标准应用",
    desc: "适用于汽服门店",
    img: serve_mode_one,
  },
  {
    title: "硬件+API接口",
    desc: "适用于具有开发能力的客户",
    img: serve_mode_two,
  },
];

function KeyCabinet() {
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
    return window.removeEventListener("scroll", handleScroll);
  }, []);

  const isInViewPort = (element) => {
    // const viewWidth = window.innerWidth || document.documentElement.clientWidth;
    const viewHeight =
      window.innerHeight || document.documentElement.clientHeight;
    const { top } = element?.getBoundingClientRect();
    return top < viewHeight;
  };

  const [rightOne, setAnimateOne] = useState(false);
  const [rightTwo, setAnimateTwo] = useState(false);
  const [rightThree, setAnimateThree] = useState(false);
  const [rightFour, setAnimateFour] = useState(false);

  const handleScroll = (e) => {
    const rightOneEle = document.getElementById("right_one");
    const rightTwoEle = document.getElementById("right_two");
    const rightThreeEle = document.getElementById("right_three");
    const rightFourEle = document.getElementById("right_four");

    isInViewPort(rightOneEle) && setAnimateOne(true);
    isInViewPort(rightTwoEle) && setAnimateTwo(true);
    isInViewPort(rightThreeEle) && setAnimateThree(true);
    isInViewPort(rightFourEle) && setAnimateFour(true);
  };

  const renderPicture = (item) => {
    return (
      <ImageWithContent
        img={item.original}
        width="100vw"
        height="740px"
        id="right_three"
        className={rightThree ? "slide-in-bottom" : ""}
        containerAlignItems="flex-start"
        contentJustify="flex-start"
        paddingTop={160}
      >
        <Space
          direction="vertical"
          size={40}
          style={{
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <Space direction="vertical" size={25}>
            <Title color="white" height={46}>
              {item.title}
            </Title>
            <Title color="#12C194" height={46} textAlign="start">
              {item.subTitle}
            </Title>
          </Space>
          <Space direction="vertical" size={10}>
            <SubTitle color="white" textAlign="start">
              {item.desc}
            </SubTitle>
            <SubTitle color="white" textAlign="start">
              {item.subDesc}
            </SubTitle>
          </Space>
        </Space>
      </ImageWithContent>
    );
  };

  const images = [
    {
      original: pictureOne,
      thumbnail: pictureOneSmall,
      renderItem: renderPicture,
      title: "钥匙柜前置社区楼宇电梯厅",
      subTitle: "停车时段 顺手养车",
      desc: "写字楼车主停车后，等电梯顺手养车",
      subDesc: "住宅小区车主回家停车后，等电梯顺手养车",
    },
    {
      original: pictureTwo,
      thumbnail: pictureTwoSmall,
      renderItem: renderPicture,
      title: "钥匙柜前置社区楼宇电梯厅",
      subTitle: "停车时段 顺手养车",
      desc: "写字楼车主停车后，等电梯顺手养车",
      subDesc: "住宅小区车主回家停车后，等电梯顺手养车",
    },
  ];

  return (
    <>
      <PageImage img={backgroundImg}>
        <Space
          direction="vertical"
          style={{ width: "426px" }}
          size={20}
          className="slide-right"
        >
          <Image src={subLogo} width={134} height={58} preview={false} />
          <Space direction="vertical" className="page_one_title" size={1}>
            <div>智能养车钥匙柜</div>
          </Space>
          <Space size={[30, 0]} wrap className="page_four_subTitle">
            <div>取代收银前台，门店无人值守接还车</div>
          </Space>
        </Space>
        <Image
          className="slide-left"
          src={contentImg}
          width={781}
          preview={false}
          style={{ height: "680px" }}
        />
      </PageImage>
      <SubMenu items={items} title="智能钥匙柜" id="subMenu" />
      <div style={{ height: 120, marginTop: -120 }} id="feature"></div>
      <Space
        size={100}
        direction="vertical"
        style={{
          width: "100vw",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginBottom: 140,
        }}
      >
        <ImageWithContent
          width={1200}
          height={610}
          img={KeyOne}
          id="right_one"
          paddingLeft={120}
          className={rightOne ? "slide-in-bottom" : ""}
          paddingRight={174}
          contentJustify="space-between"
        >
          <Space direction="vertical" size={40} style={{ paddingTop: 30 }}>
            <Space direction="vertical" size={0}>
              <Title>钥匙柜还车，无需前台/收银</Title>
              <Title color="#12C194" textAlign="start">
                一年节省5万元
              </Title>
            </Space>
            <Space direction="vertical">
              <SubTitle lineHeight="40px" textAlign="start">
                施工完毕后统一使用钥匙柜还车
              </SubTitle>
              <SubTitle lineHeight="40px" textAlign="start">
                车主先支付后开箱取钥匙
              </SubTitle>
            </Space>
          </Space>
          <Image src={rightImg_one} width={236} height={499} preview={false} />
        </ImageWithContent>
        <Space width={1200} size={40} id="right_two">
          <ImageWithContent
            width={580}
            height={750}
            img={KeyTwo}
            paddingTop={100}
            contentHeight={750}
            contentJustify="space-between"
            contentDirection="column"
            contentAlignItems="center"
            className={rightTwo ? "slide-in-bottom" : ""}
          >
            <Space direction="vertical" size={35}>
              <Space>
                <Title color="#12C194" fontSize="40px">
                  24小时
                </Title>
                <Title fontSize="40px">接车/还车</Title>
              </Space>
              <Space direction="vertical" size={1}>
                <SubTitle>开门前自动接车，开门即有订单数</SubTitle>
                <SubTitle>闭店后自动还车，无需留守等客户</SubTitle>
              </Space>
            </Space>
            <Image
              src={rightImg_two}
              width={420}
              height={450}
              preview={false}
            />
          </ImageWithContent>
          <ImageWithContent
            width={580}
            height={750}
            img={KeyThree}
            paddingTop={100}
            contentHeight={750}
            contentJustify="space-between"
            contentDirection="column"
            contentAlignItems="center"
            className={rightTwo ? "slide-in-bottom" : ""}
          >
            <Space direction="vertical" size={30}>
              <Space
                direction="vertical"
                size={0}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Title color="#12C194" fontSize="40px">
                  钥匙存入柜
                </Title>
                <Title fontSize="40px">客户更安心</Title>
              </Space>
              <Space direction="vertical" size={1}>
                <SubTitle>避免技师动车，只能车主开箱取钥匙</SubTitle>
              </Space>
            </Space>
            <Image
              src={rightImg_three}
              width={420}
              height={450}
              preview={false}
            />
          </ImageWithContent>
        </Space>
        <ImageGallery
          items={images}
          lazyLoad
          additionalClass="image-gallery-container"
          autoPlay={false}
          showPlayButton={false}
          showFullscreenButton={false}
          renderLeftNav={(onClick, disabled) => (
            <LeftNav onClick={onClick} disabled={disabled} />
          )}
          renderRightNav={(onClick, disabled) => (
            <RightNav onClick={onClick} disabled={disabled} />
          )}
        ></ImageGallery>

        <Space
          width={1200}
          size={40}
          id="right_four"
          className={rightFour ? "slide-in-bottom" : ""}
        >
          <ImageWithContent
            width={580}
            height={360}
            img={KeyFour}
            containerAlignItems="flex-start"
            contentJustify="space-between"
            paddingLeft={60}
            paddingTop={87}
            paddingRight={99}
          >
            <Image
              src={rightImg_four}
              width={130}
              height={130}
              preview={false}
            />
            <Space direction="vertical" size={30} width={241}>
              <Space direction="vertical">
                <SubTitle
                  color="white"
                  fontSize={30}
                  yuanTi={true}
                  fontWeight={700}
                  textAlign="start"
                >
                  7*24小时监控值守
                </SubTitle>
                <SubTitle
                  color="white"
                  yuanTi={true}
                  fontSize={30}
                  fontWeight={700}
                  textAlign="start"
                >
                  确保钥匙安全
                </SubTitle>
              </Space>
              <Space direction="vertical" size={1}>
                <SubTitle
                  color="#FFFFFF"
                  fontSize={18}
                  fontWeight={400}
                  textAlign="start"
                >
                  多家门店超10万单安全验证
                </SubTitle>
                <SubTitle
                  color="#FFFFFF"
                  fontSize={18}
                  fontWeight={400}
                  textAlign="start"
                >
                  钥匙安全0风险
                </SubTitle>
              </Space>
            </Space>
          </ImageWithContent>
          <ImageWithContent
            width={580}
            height={360}
            img={KeyFive}
            containerAlignItems="flex-start"
            contentJustify="space-between"
            paddingLeft={60}
            paddingTop={87}
            paddingRight={70}
          >
            <Image
              src={rightImg_five}
              width={130}
              height={130}
              preview={false}
            />
            <Space direction="vertical" size={30}>
              <Space direction="vertical">
                <SubTitle
                  color="white"
                  fontSize={30}
                  fontWeight={700}
                  yuanTi={true}
                  textAlign="start"
                >
                  智能柜多行业已普及
                </SubTitle>
                <SubTitle
                  color="white"
                  fontSize={30}
                  fontWeight={700}
                  yuanTi={true}
                  textAlign="start"
                >
                  客户接受度100%
                </SubTitle>
              </Space>
              <Space direction="vertical" size={1}>
                <SubTitle
                  color="white"
                  fontSize={18}
                  fontWeight={400}
                  textAlign="start"
                >
                  无需教育无需培训
                </SubTitle>
                <SubTitle
                  color="white"
                  fontSize={18}
                  fontWeight={400}
                  textAlign="start"
                >
                  客户使用顺畅自然
                </SubTitle>
              </Space>
            </Space>
          </ImageWithContent>
        </Space>
      </Space>
      <div style={{ height: 120, marginTop: -120 }} id="params"></div>
      <KeyParams />
      <div style={{ height: 120, marginTop: -120 }} id="serve"></div>
      <Space
        size={70}
        id="serve"
        direction="vertical"
        style={{
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "140px",
        }}
      >
        <Title>服务模式</Title>
        <Space
          style={{
            width: 1200,
          }}
          wrap
          size={20}
        >
          {serveMode.map((item) => {
            return (
              <div className="serve_mode_container" key={item.desc}>
                <Space direction="vertical" size={25}>
                  <SubTitle
                    fontWeight={700}
                    fontSize="24px"
                    lineHeight="0px"
                    textAlign="start"
                  >
                    {item.title}
                  </SubTitle>
                  <Description textAlign="start">{item.desc}</Description>
                  <Description textAlign="start">支持LOGO定制</Description>
                </Space>
                <Image
                  src={item.img}
                  width={200}
                  height={250}
                  preview={false}
                />
              </div>
            );
          })}
        </Space>
      </Space>
      <PageFooter />
    </>
  );
}

export default KeyCabinet;
