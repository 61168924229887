import { Space, Image } from "antd";
import React, { useEffect, useState } from "react";
import PageImage from "../PageImage";
import backgroundImg from "../../static/menu/data/data_background.png";

import subLogo from "../../static/menu/data/data_sub_logo.png";
import contentImg from "../../static/menu/data/data_right_img.png";
import subMenuLogo from "../../static/menu/data/data_sub_logo_white.png";
import SubMenu from "../SubMenu";

import serveOne from "../../static/menu/data/data_serve_one.png";
import serveTwo from "../../static/menu/data/data_serve_two.png";
import serveThree from "../../static/menu/data/data_serve_three.png";
import serveFour from "../../static/menu/data/data_serve_four.png";

import aloOne from "../../static/menu/data/data_t_serve_one.png";
import aloTwo from "../../static/menu/data/data_t_serve_two.png";
import aloThree from "../../static/menu/data/data_t_serve_three.png";
import aloFour from "../../static/menu/data/data_t_serve_four.png";
import aloFive from "../../static/menu/data/data_t_serve_five.png";
import aloSix from "../../static/menu/data/data_t_serve_six.png";
import aloSeven from "../../static/menu/data/data_t_serve_seven.png";
import aloEight from "../../static/menu/data/data_t_serve_eight.png";

import shareOne from "../../static/menu/data/data_share_one.png";
import shareTwo from "../../static/menu/data/data_share_two.png";
import shareThree from "../../static/menu/data/data_share_three.png";

import ImageWithContent from "../ImageWithContent";
import Title from "../fontComponent/Title";
import SubTitle from "../fontComponent/SubTitle";
import Description from "../fontComponent/Description";

import serve_mode_one from "../../static/menu/data/data_api_one.png";
import serve_mode_two from "../../static/menu/data/data_api_two.png";

import PageFooter from "../PageFooter";

const items = [
  {
    desc: "data",
    name: "汽服数据",
  },
  {
    desc: "algorithm",
    name: "汽服算法",
  },
  {
    desc: "standard",
    name: "汽服标准",
  },
  {
    desc: "serve",
    name: "服务模式",
  },
];

const serve = [
  {
    img: serveOne,
    title: "核验产品",
    subTitle: "车辆信息准确性验证服务",
  },
  {
    img: serveTwo,
    title: "车牌产品",
    subTitle: "车辆精准化画像服务",
  },
  {
    img: serveThree,
    title: "VIN产品",
    subTitle: "车型车款精准解析服务",
  },
  {
    img: serveFour,
    title: "车型产品",
    subTitle: "车型配置信息、维保手册、全车件等查询服务",
  },
];

const algorithm = [
  {
    img: aloOne,
    title: "OCR产品",
    subTitle: "车牌、VIN、行驶证、驾驶证图像降准识别服务,支持SDK",
  },
  {
    img: aloTwo,
    title: "二手车产品",
    subTitle: "二手车过户指数、置换指数、估值服务",
  },
  {
    img: aloThree,
    title: "维保指数",
    subTitle: "车辆维修保养方案选择倾向评估",
  },
  {
    img: aloFour,
    title: "运营车辆指数",
    subTitle: "家用车运营指数分析服务",
  },
  {
    img: aloFive,
    title: "高速指数",
    subTitle: "高速指数算法服务、运力评估等",
  },
  {
    img: aloSix,
    title: "年检指数",
    subTitle: "年检到期及年检方案查询服务",
  },
  {
    img: aloSeven,
    title: "洗车指数",
    subTitle: "洗车指数查询服务",
  },
  {
    img: aloEight,
    title: "车产指数",
    subTitle: "企业及个人车产评估",
  },
];

const share = [
  {
    img: shareOne,
    title: "汽服标准库",
    subTitle: "汽车服务标准分类及服务规范",
    width: 400,
    height: 700,
  },
  {
    img: shareTwo,
    title: "汽服项目库",
    subTitle: "汽车服务项目资料共享库",
    width: 400,
    height: 700,
  },
  {
    img: shareThree,
    title: "汽配商品库",
    subTitle: "汽车配件商品资料共享库、配件资料及适配信息共享库",
    width: 400,
    height: 700,
  },
];

const serveMode = [
  {
    title: "标准API接口",
    img: serve_mode_one,
  },
  {
    title: "定制API接口",
    img: serve_mode_two,
  },
];

function Data() {
  const handleScroll = (e) => {
    const rightOneEle = document.getElementById("right_one");
    const rightTwoEle = document.getElementById("right_two");
    const rightThreeEle = document.getElementById("right_three");

    const isInViewPort = (element) => {
      // const viewWidth = window.innerWidth || document.documentElement.clientWidth;
      const viewHeight =
        window.innerHeight || document.documentElement.clientHeight;
      const { top } = element.getBoundingClientRect();
      return top < viewHeight;
    };

    isInViewPort(rightOneEle) && setAnimateOne(true);
    isInViewPort(rightTwoEle) && setAnimateTwo(true);
    isInViewPort(rightThreeEle) && setAnimateThree(true);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
    return window.removeEventListener("scroll", handleScroll);
  }, []);

  const [rightOne, setAnimateOne] = useState(false);
  const [rightTwo, setAnimateTwo] = useState(false);
  const [rightThree, setAnimateThree] = useState(false);

  return (
    <>
      <PageImage img={backgroundImg}>
        <ImageWithContent
          img={contentImg}
          width={1200}
          height={740}
          imgWidth={1074}
          imgHeight={740}
          imgOffset={150}
          imgJustify="flex-end"
          containerAlignItems="center"
          contentDirection="column"
          animate="slide-left"
        >
          <Space
            direction="vertical"
            style={{ width: "470px" }}
            size={30}
            className="slide-right"
          >
            <Image src={subLogo} width={117} height={58} preview={false} />
            <Space direction="vertical" size={44}>
              <Space direction="vertical" className="page_one_title" size={1}>
                <div>汽服大数据及算法服务</div>
              </Space>
              <SubTitle
                fontSize="20px"
                color="#FFFFFF"
                textAlign="start"
                lineHeight="0px"
              >
                汽车后市场数智底座
              </SubTitle>
            </Space>
          </Space>
        </ImageWithContent>
      </PageImage>

      <SubMenu
        items={items}
        title={
          <Image width={57} height={22} src={subMenuLogo} preview={false} />
        }
        id="subMenu"
      />
      <div
        style={{ height: 0, position: "relative", top: -60 }}
        id="data"
      ></div>

      <Space
        size={70}
        direction="vertical"
        style={{
          width: "100vw",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginTop: 182,
          marginBottom: 122,
        }}
      >
        <ImageWithContent
          width={1200}
          height={400}
          id="right_one"
          className={rightOne ? "slide-in-bottom" : ""}
        >
          <Space direction="vertical" size={70}>
            <SubTitle fontSize={30} yuanTi>
              汽服数据服务
            </SubTitle>
            <Space wrap size={20}>
              {serve.map((item) => {
                return (
                  <ImageWithContent
                    width={285}
                    height={400}
                    img={item.img}
                    key={Math.random()}
                    paddingBottom={41}
                    paddingTop={308}
                  >
                    <Space direction="vertical">
                      <SubTitle
                        fontSize={16}
                        yuanTi
                        position="relative"
                        top={7}
                      >
                        {item.title}
                      </SubTitle>
                      <Description
                        height="17px"
                        fontSize="12px"
                        position="relative"
                        top={-10}
                      >
                        {item.subTitle}
                      </Description>
                    </Space>
                  </ImageWithContent>
                );
              })}
            </Space>
          </Space>
        </ImageWithContent>
        <div style={{ height: 60, marginTop: -60 }} id="algorithm"></div>
        <Space
          direction="vertical"
          size={70}
          id="algorithm"
          className={rightTwo ? "slide-in-bottom" : ""}
        >
          <SubTitle yuanTi fontSize={30} id="right_two">
            汽服算法服务
          </SubTitle>
          <Space
            wrap
            size={[0, 20]}
            style={{
              width: 1200,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {algorithm.map((item) => {
              return (
                <div
                  key={Math.random()}
                  style={{
                    width: 285,
                    height: 320,
                    background: "#F4F8FB",
                    borderRadius: 5,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    paddingLeft: 37,
                    paddingRight: 37,
                    paddingTop: 60,
                  }}
                >
                  <Image
                    src={item.img}
                    width={90}
                    height={90}
                    preview={false}
                  />
                  <Space direction="vertical" style={{ marginTop: 45 }}>
                    <SubTitle yuanTi fontSize={16}>
                      {item.title}
                    </SubTitle>
                    <Description height={17} fontSize={12} lineHeight="17px">
                      {item.subTitle}
                    </Description>
                  </Space>
                </div>
              );
            })}
          </Space>
        </Space>
        <div style={{ height: 60, marginTop: -60 }} id="standard"></div>

        <ImageWithContent
          width="100vw"
          height={700}
          background="linear-gradient(rgba(18,193,148),rgba(19,204,156))"
          containerAlignItems="center"
          contentAlignItems="center"
          contentJustify="center"
          contentDirection="column"
          className={rightThree ? "slide-in-bottom" : ""}
          id="right_three"
        >
          <div style={{ position: "absolute", top: 100 }}>
            <Title fontSize={30} color="white">
              汽服标准共享平台
            </Title>
          </div>
          <Space direction="vertical" size={100} id="standard">
            <Space
              style={{
                width: "1200px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {share.map((item) => {
                return (
                  <ImageWithContent
                    key={Math.random()}
                    width={item.width}
                    height={item.height}
                    img={item.img}
                    paddingBottom={115}
                  >
                    <Space
                      direction="vertical"
                      style={{ height: 60, width: 260 }}
                    >
                      <SubTitle yuanTi fontSize={20} color="white">
                        {item.title}
                      </SubTitle>
                      <SubTitle
                        height={20}
                        fontSize={14}
                        lineHeight="20px"
                        color="#FFFFFF"
                        fontWeight={400}
                      >
                        {item.subTitle}
                      </SubTitle>
                    </Space>
                  </ImageWithContent>
                );
              })}
            </Space>
          </Space>
        </ImageWithContent>
      </Space>
      <div style={{ height: 120, marginTop: -120 }} id="serve"></div>

      <Space
        size={80}
        id="serve"
        direction="vertical"
        style={{
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "140px",
        }}
      >
        <Title fontSize={30}>服务模式</Title>
        <Space
          style={{
            width: 1200,
          }}
          wrap
          size={20}
        >
          {serveMode.map((item) => {
            return (
              <div className="serve_mode_container" key={Math.random()}>
                <Space size={25} direction="vertical">
                  <Image
                    src={item.img}
                    width={226}
                    height={140}
                    preview={false}
                  />
                  <SubTitle fontWeight={700} fontSize="20px" lineHeight="0px">
                    {item.title}
                  </SubTitle>
                </Space>
              </div>
            );
          })}
        </Space>
      </Space>
      <PageFooter />
    </>
  );
}

export default Data;
