import "./style/pageStyle.css";
import React from "react";
import SliderImage from "./SliderImage";
import { Space, Image } from "antd";
// import SliderTwo from "../static/slider/home_page_two@2x.png";
import SliderTwo from "../static/slider/jpg/home_page_two@2x.jpg";

import subLogo from "../static/slider/page_two_sub_logo@2x.png";
import contentImg from "../static/slider/page_two_right_img@2x.png";
import sliderPreview from "../static/slider/home_page_two_preview.jpg";
import LearnMore from "./LearnMore";

const HomePageTwo = (props) => {
  const { next, prev, animate } = props;
  return (
    <SliderImage
      img={SliderTwo}
      placeholderImg={sliderPreview}
      next={next}
      prev={prev}
    >
      <Space direction="vertical" size={15}>
        <Image
          src={subLogo}
          width={250}
          height={55}
          preview={false}
          className={animate ? "slide-left-a" : ""}
        />
        <Space
          direction="vertical"
          className={`page_one_title ${animate ? "slide-left-c" : ""}`}
          size={1}
        >
          <div>社区一站式便捷养车连锁</div>
        </Space>
        <div className={`page_one_subTitle ${animate ? "slide-left-d" : ""}`}>
          重新定义养车门店
        </div>
        <LearnMore
          href={"/ecology-create/EcologyCreate"}
          top="75px"
          className={animate ? "slide-left-e" : ""}
        />
      </Space>
      <Image
        className={animate ? "slide-left-d" : ""}
        src={contentImg}
        width={492}
        preview={false}
        style={{ height: "860px" }}
      />
    </SliderImage>
  );
};

export default HomePageTwo;
