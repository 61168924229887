import "./style/pageStyle.css";
import React, { useEffect, useState } from "react";
import carLai from "../static/menu/software/car_lai@2x.png";
import carLaiLogo from "../static/menu/software/car_lai_logo@2x.png";
import carJian from "../static/menu/software/car_jian@2x.png";
import carJianLogo from "../static/menu/software/car_jian_logo@2x.png";
import { Image, Space } from "antd";
import { Link } from "react-router-dom";

const SOFT_WARE_SUB_MENU = [
  {
    img: carLai,
    logo: carLaiLogo,
    text: "汽服门店数智操作系统，满足你关于门店数智化的所有想象。洗美店、快修快保店、装潢店、综合修理厂...",
    subText: "",
    hrefText: "了解详情 >",
    href: "/softWara/youchelai",
  },
  {
    img: carJian,
    logo: carJianLogo,
    text: "数智汽配操作系统",
    subText: "汽配品牌商、分销商、汽配门店",
    hrefText: "",
    href: "",
  },
];

const SoftwareSubMenu = (props) => {
  const { hideSoftWareSubMenu } = props;

  const [hideBoxHeight, setHideBoxHeight] = useState();

  useEffect(() => {
    setHideBoxHeight(document.documentElement.clientHeight - 400);
  }, []);

  const splitLine = () => {
    return <div className="soft_ware_split_line"></div>;
  };
  return (
    <>
      <div className="soft_ware_sub_menu_container fade-in-top">
        <Space split={splitLine()} style={{ width: "1200px" }}>
          {SOFT_WARE_SUB_MENU.map((item) => {
            return (
              <Space direction="horizontal" size={5} key={item.text}>
                <Image
                  src={item.img}
                  width={300}
                  height={220}
                  preview={false}
                />
                <Link
                  to={item.href}
                  style={{ cursor: item.href ? "pointer" : "auto" }}
                >
                  <Space
                    direction="vertical"
                    size={25}
                    className="soft_ware_text_item_container"
                  >
                    <Image
                      src={item.logo}
                      width={129}
                      height={43}
                      preview={false}
                    />
                    <div className="soft_ware_text">
                      <div>{item.text}</div>
                      <div>{item.subText}</div>
                    </div>
                    <Link className="soft_ware_text" to={item.href}>
                      {item.hrefText}
                    </Link>
                  </Space>
                </Link>
              </Space>
            );
          })}
        </Space>
      </div>
      <div
        onMouseOver={() => {
          hideSoftWareSubMenu();
        }}
        style={{
          height: hideBoxHeight,
          width: "100vw",
          background: "rgba(0,0,0,0.50)",
          position: "fixed",
          top: 460,
          zIndex: 13,
        }}
      ></div>
    </>
  );
};

export default SoftwareSubMenu;
