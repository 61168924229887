import React from "react";
import ImageWithContent from "./component/ImageWithContent";
import SLIDER_TWO from "../static/mobile/slider/slider_two.png";
import LOGO from "../static/mobile/slider/slide_two/logo.png";
import Text from "./component/Text";
import { Image } from "antd-mobile";
import SliderGo from "./component/SliderGo";
import { Link } from "react-router-dom";

const SliderTwo = (props) => {
  const { height, top, className } = props;
  return (
    <Link to="/ecologyCreate">
      <ImageWithContent
        className={className}
        top={top}
        img={SLIDER_TWO}
        height={height}
        marginRight={8}
        paddingTop={60}
        paddingLeft={30}
        paddingRight={30}
        contentJustify="flex-start"
        contentDirection="column"
      >
        <Image src={LOGO} width={158} height={35} />
        <Text fontFamily="bold" fontSize={28} color="#FFFFFF" top={10}>
          社区一站式便捷养车连锁
        </Text>
        <Text fontFamily="bold" fontSize={14} color="#FFFFFF" top={15}>
          重新定义养车门店
        </Text>
      </ImageWithContent>
      <SliderGo href="/ecologyCreate" />
    </Link>
  );
};

export default SliderTwo;
