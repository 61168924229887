import React from "react";
import { Image, Space } from "antd";
import SubTitle from "../fontComponent/SubTitle";
import Title from "../fontComponent/Title";

import keyParamsOne from "../../static/menu/hardware/pages/key_params_one.png";
import keyParamsTwo from "../../static/menu/hardware/pages/key_params_two.png";
import keyParamsThree from "../../static/menu/hardware/pages/key_params_three.png";

const techologyParams = [
  {
    title: "名称",
    type: "fontText",
    content: ["壁挂钥匙柜G18", "立式钥匙柜X18", "立式钥匙柜X32"],
  },
  {
    title: "外观",
    type: "img",
    content: [keyParamsOne, keyParamsTwo, keyParamsThree],
  },
  {
    title: "尺寸",
    type: "size",
    content: [
      ["宽度 550mm", "高度 850mm", "厚度 170mm"],
      ["宽度 550mm", "高度 1428mm", "厚度 245mm"],
      ["宽度 501mm", "高度 1714mm", "厚度 228.5mm"],
    ],
  },
  {
    title: "显示屏",
    type: "size",
    content: [
      ["屏幕 18.5英寸", "分辨率 -"],
      ["屏幕 18.5英寸", "分辨率 1920*1080"],
      ["32英寸", "分辨率 1920*1080"],
    ],
  },
  {
    title: "系统与网络",
    type: "size",
    content: [
      ["系统 -", "网络 4G/WIFI"],
      ["系统 Android 5.1.1", "网络 4G/WIFI"],
      ["系统 Android 5.1.1", "网络 4G/WIFI"],
    ],
  },
  {
    title: "格口",
    content: ["20个", "20个", "20个"],
  },
];

const KeyParams = () => {
  const renderImg = (items) => {
    return items.map((item) => {
      return (
        <Image
          key={Math.random()}
          src={item}
          width={240}
          height={304}
          preview={false}
        />
      );
    });
  };

  const renderName = (items) => {
    return items.map((item) => {
      return (
        <div style={{ width: 240 }} key={Math.random()}>
          <SubTitle
            fontSize={24}
            color="#343744"
            fontWeight={400}
            textAlign="start"
          >
            {item}
          </SubTitle>
        </div>
      );
    });
  };

  const renderSize = (items) => {
    return items.map((item) => {
      return (
        <div style={{ width: 240 }} key={Math.random()}>
          <Space direction="vertical">
            <SubTitle
              color="#404558"
              fontWeight={400}
              lineHeight="36px"
              fontSize={18}
              textAlign="start"
            >
              {item[0]}
            </SubTitle>
            <SubTitle
              color="#404558"
              fontWeight={400}
              lineHeight="36px"
              textAlign="start"
              fontSize={18}
            >
              {item[1]}
            </SubTitle>
            <SubTitle
              color="#404558"
              fontWeight={400}
              lineHeight="36px"
              textAlign="start"
              fontSize={18}
            >
              {item[2]}
            </SubTitle>
          </Space>
        </div>
      );
    });
  };

  const switchItem = (ele) => {
    switch (ele.type) {
      case "fontText":
        return renderName(ele.content);
      case "img":
        return renderImg(ele.content);
      case "size":
        return renderSize(ele.content);
      default: {
        return ele.content.map((item) => {
          return (
            <div style={{ width: 240 }} key={Math.random()}>
              <SubTitle
                style={{
                  width: 240,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
                lineHeight="36px"
                textAlign="start"
                fontWeight={400}
                color="#404558"
                fontSize={18}
              >
                {item}
              </SubTitle>
            </div>
          );
        });
      }
    }
  };

  const renderSplit = () => {
    return (
      <div
        style={{
          width: 1200,
          height: "1px",
          opacity: 0.15,
          background: "#404558",
        }}
      ></div>
    );
  };

  return (
    <Space
      size={80}
      direction="vertical"
      id="params"
      style={{
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "70px",
      }}
    >
      <Title>技术参数</Title>
      <Space
        style={{
          width: 1200,
        }}
        direction="vertical"
        size={65}
        split={renderSplit()}
      >
        {techologyParams.map((item) => {
          return (
            <div
              key={Math.random()}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <SubTitle
                color="#343744"
                fontWeight={700}
                fontSize={24}
                textAlign="start"
                whiteSpace="nowrap"
                width={120}
                yuanTi={true}
              >
                {item.title}
              </SubTitle>
              {switchItem(item)}
            </div>
          );
        })}
        <div />
      </Space>
    </Space>
  );
};

export default KeyParams;
