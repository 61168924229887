import React from "react";
import "../../style/mobile.css";
import Text from "../Text";

const SplitLine = (props) => {
  const { name, top, bottom } = props;

  return (
    <div style={{ marginBottom: bottom, marginTop: top }}>
      <div
        style={{
          height: 0.5,
          opacity: 0.15,
          background: "#404558",
        }}
      ></div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          position: "relative",
        }}
      >
        {name && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              position: "relative",
              top: -10,
              width: 70,
              height: 20,
              background: "white",
            }}
          >
            <Text
              fontFamily="bold"
              fontSize={12}
              color="#343744"
              className="split_line_text"
            >
              {name}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default SplitLine;
