import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = (props) => {
  const { children } = props;
  const { pathname } = useLocation();

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, [pathname]);

  return <>{children}</>;
};

export default ScrollToTop;
