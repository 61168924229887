import { Space } from "antd";
import React from "react";
import Path from "./path/path";

const PageFooter = () => {
  return (
    <div
      style={{
        width: "100vw",
        height: "60px",
        background: "#343744",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Space
        style={{
          width: 1200,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            opacity: 0.4,
            fontSize: 14,
            color: "#FFFFFF",
            fontFamily: "STYuanti-SC-Regular",
            letterSpacing: 0,
            fontWeight: 400,
          }}
        >
          www.autocareai.com
        </div>
        <div
          style={{
            opacity: 0.4,
            fontSize: 10,
            color: "#FFFFFF",
            letterSpacing: 0,
            fontWeight: 400,
          }}
        >
          <Path />
        </div>
      </Space>
    </div>
  );
};

export default PageFooter;
