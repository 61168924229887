/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from "react";
import { Swiper } from "antd-mobile";
import "../style/mobile.css";
import SliderOne from "../SliderOne";
import SliderTwo from "../SliderTwo";
import SliderThree from "../SliderThree";
import SliderFour from "../SliderFour";
import SliderFive from "../SliderFive";
import SliderSix from "../SliderSix";

export default () => {
  const onSlideChange = (index) => {
    setIsChooseSwiper(index);
  };
  const [isChooseSwiper, setIsChooseSwiper] = useState(0);
  const [hideHeight, setHideHeight] = useState();

  useEffect(() => {
    const height = (document.documentElement.clientWidth - 30) * 1.695 - 19;
    setHideHeight(height);
  }, []);

  return (
    <Swiper
      onIndexChange={(index) => onSlideChange(index)}
      trackOffset={4}
      slideSize={94}
      autoplay
      autoplayInterval={5000}
      indicatorProps={{
        color: "white",
        style: {
          "--active-dot-size": "34px",
          "--dot-color": "rgba(52,55,68,0.5)",
          "--dot-spacing": "8px",
          "--dot-size": "10px",
        },
      }}
      stuckAtBoundary={false}
      style={{
        "--track-padding": " 0 0 35px",
      }}
    >
      <Swiper.Item>
        <SliderOne
          className={isChooseSwiper === 0 ? "scale-in-center" : ""}
          height={isChooseSwiper === 0 ? "" : hideHeight}
          top={isChooseSwiper === 0 ? 0 : 10}
        />
      </Swiper.Item>
      <Swiper.Item>
        <SliderThree
          className={isChooseSwiper === 1 ? "scale-in-center" : ""}
          height={isChooseSwiper === 1 ? "" : hideHeight}
          top={isChooseSwiper === 1 ? 0 : 10}
        />
      </Swiper.Item>
      <Swiper.Item>
        <SliderFour
          className={isChooseSwiper === 2 ? "scale-in-center" : ""}
          height={isChooseSwiper === 2 ? "" : hideHeight}
          top={isChooseSwiper === 2 ? 0 : 10}
        />
      </Swiper.Item>
      <Swiper.Item>
        <SliderSix
          className={isChooseSwiper === 3 ? "scale-in-center" : ""}
          height={isChooseSwiper === 3 ? "" : hideHeight}
          top={isChooseSwiper === 3 ? 0 : 10}
        />
      </Swiper.Item>
      <Swiper.Item>
        <SliderTwo
          className={isChooseSwiper === 4 ? "scale-in-center" : ""}
          height={isChooseSwiper === 4 ? "" : hideHeight}
          top={isChooseSwiper === 4 ? 0 : 10}
        />
      </Swiper.Item>
      <Swiper.Item>
        <SliderFive
          className={isChooseSwiper === 5 ? "scale-in-center" : ""}
          height={isChooseSwiper === 5 ? "" : hideHeight}
          top={isChooseSwiper === 5 ? 0 : 10}
        />
      </Swiper.Item>
    </Swiper>
  );
};
