import { Space, Image } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import LearnMoren from "../static/slider/learn_more.png";
import LearnMore_h from "../static/slider/learn_more_h.png";

const LearnMore = ({ href, top, className }) => {
  const [img, setImg] = useState(LearnMoren);
  const [hight, setHight] = useState(false);
  return (
    <Space
      onMouseOver={() => {
        setImg(LearnMore_h);
        setHight(true);
      }}
      onMouseLeave={() => {
        setImg(LearnMoren);
        setHight(false);
      }}
      height={60}
      className={className}
      size={5}
      style={{
        position: "relative",
        top,
      }}
    >
      <Link to={href}>
        <Space
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          <div className={`learn_more ${hight ? "learn_more_hover" : ""}`}>
            了解详情
          </div>
          <Image src={img} width={10} height={10} preview={false} />
        </Space>
      </Link>
    </Space>
  );
};

export default LearnMore;
