import React from "react";
import { Image } from "antd-mobile";
import SLIDER_GO from "../../static/mobile/slider/slider_go.png";
import { Link } from "react-router-dom";

const SliderGo = (props) => {
  const { href } = props;
  return (
    <Link to={href || ""}>
      <Image
        src={SLIDER_GO}
        width={44}
        height={44}
        style={{ position: "absolute", left: 20, bottom: 40 }}
      />
    </Link>
  );
};

export default SliderGo;
