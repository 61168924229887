import React from "react";

const fontF = {
  bold: "Yuanti SC",
  normal: "STYuanti-SC-Regular",
  pingFang: "",
};

const Text = (props) => {
  const {
    id,
    className,
    fontSize,
    color,
    fontFamily,
    opacity,
    children,
    top,
    bottom,
    fontWeight,
    nowrap,
    style,
  } = props;

  return (
    <div
      id={id}
      className={className}
      style={{
        ...style,
        opacity,
        fontWeight,
        fontSize,
        color,
        fontFamily: fontF[fontFamily],
        marginTop: top,
        marginBottom: bottom,
        whiteSpace: nowrap ? "nowrap" : "",
      }}
    >
      {children}
    </div>
  );
};

export default Text;
