import React, { useEffect, useState } from "react";
import Layout from "../../Layout";
import BG from "../../../static/mobile/pages/ecologyCreate/bg.png";
import ImageWithContent from "../ImageWithContent";
import { Image } from "antd-mobile";
import Text from "../Text";
import LOGO from "../../../static/mobile/pages/ecologyCreate/logo.png";
import STORE_ONE from "../../../static/mobile/pages/ecologyCreate/store_one.png";

import STORE_TWO from "../../../static/mobile/pages/ecologyCreate/store_two.png";
import STORE_THREE from "../../../static/mobile/pages/ecologyCreate/store_three.png";
import STORE_FOUR from "../../../static/mobile/pages/ecologyCreate/store_four.png";
import STORE_FIVE from "../../../static/mobile/pages/ecologyCreate/store_five.png";
import STORE_FOOTER from "../../../static/mobile/pages/ecologyCreate/store_footer.png";

import PaddingBox from "../PaddingBox";
import Footer from "../Footer";

const sl = [
  {
    img: STORE_TWO,
  },
  {
    img: STORE_THREE,
  },
  {
    img: STORE_FOUR,
  },
  {
    img: STORE_FIVE,
  },
];

const EclologyCreate = () => {
  //   const [osWidth, setOSWidth] = useState();
  const [bgWidth, setBgWidth] = useState();

  useEffect(() => {
    // setOSWidth(document.documentElement.clientWidth - 30);
    setBgWidth((document.documentElement.clientWidth - 40) / 2);
  }, []);

  return (
    <Layout>
      <ImageWithContent
        img={BG}
        paddingTop={135}
        paddingLeft={40}
        paddingRight={45}
        contentJustify="flex-start"
        contentDirection="column"
      >
        <Image src={LOGO} width={159} height={35} />
        <Text fontFamily="bold" fontSize={28} color="#FFFFFF" top={3}>
          社区一站式便捷养车连锁
        </Text>
        <Text fontFamily="bold" fontSize={14} color="#FFFFFF" top={3}>
          重新定义养车门店
        </Text>
      </ImageWithContent>

      <Text
        fontFamily="bold"
        fontSize={20}
        color="#343744"
        top={50}
        style={{ textAlign: "center" }}
      >
        门店形象创新
      </Text>
      <PaddingBox pad={25} top={15}>
        <Text
          fontFamily="normal"
          fontSize={10}
          color="#343744"
          opacity={0.7}
          style={{ textAlign: "center" }}
        >
          门店整体设计简洁、质感、时尚，突出高品质和专业性，对用户来说颜值即正义，容易获得年轻用户信任，和传统门店的形象形成差异化。
        </Text>
      </PaddingBox>
      <PaddingBox top={30}>
        <Image src={STORE_ONE} />
      </PaddingBox>

      <Text
        fontFamily="bold"
        fontSize={20}
        color="#343744"
        top={50}
        style={{ textAlign: "center" }}
      >
        门店选址
      </Text>

      <PaddingBox pad={25} top={15}>
        <Text
          fontFamily="normal"
          fontSize={10}
          color="#343744"
          opacity={0.7}
          style={{ textAlign: "center" }}
        >
          和有一定私域车流量的商业地产直属物业公司，成立合资公司或者合作，场地0租金或低租金，门店享受该社区私域车流量独家运营权，物业公司分享门店经营分红、股权增值收益、导流收益。
        </Text>
      </PaddingBox>

      <Text
        fontFamily="normal"
        fontSize={10}
        color="#343744"
        top={20}
        opacity={0.7}
        style={{ textAlign: "center" }}
      >
        已达成合作意向物业
      </Text>
      <PaddingBox top={20}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          {sl.map((item) => {
            return <Image src={item.img} width={bgWidth} key={Math.random()} />;
          })}
        </div>
      </PaddingBox>

      <Text
        fontFamily="bold"
        fontSize={20}
        color="#343744"
        top={50}
        style={{ textAlign: "center" }}
      >
        养车更简单
      </Text>

      <PaddingBox pad={25} top={15}>
        <Text
          fontFamily="normal"
          fontSize={10}
          color="#343744"
          opacity={0.7}
          style={{ textAlign: "center" }}
        >
          在停车库电梯停入口处部署钥匙柜，停车后车主可通过钥匙柜进行闲时全托管养车，无接触交接车钥匙，养车安全便捷。
        </Text>
      </PaddingBox>

      <PaddingBox top={30}>
        <Image src={STORE_FOOTER} />
      </PaddingBox>

      <Footer style={{ marginTop: 63, marginBottom: 15 }}></Footer>
    </Layout>
  );
};

export default EclologyCreate;
