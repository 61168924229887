/* eslint-disable react-hooks/exhaustive-deps */
import "./style/hardware.css";
import { Space, Image } from "antd";
import React, { useEffect, useState } from "react";
import PageImage from "../PageImage";
import backgroundImg from "../../static/slider/jpg/home_page_six@2x.jpg";

import subLogo from "../../static/slider/page_four_sub_logo@2x.png";
import contentImg from "../../static/menu/hardware/pages/living_background.png";
import SubMenu from "../SubMenu";
import livingOne from "../../static/menu/hardware/pages/living_one.png";
import livingTwo from "../../static/menu/hardware/pages/living_two.png";
import livingThree from "../../static/menu/hardware/pages/living_three.png";
import rightImg_one from "../../static/menu/hardware/pages/living_one_right_img.png";
import rightImg_two from "../../static/menu/hardware/pages/living_two_right_img.png";
import rightImg_three from "../../static/menu/hardware/pages/living_three_right_img.png";
import livingFour from "../../static/menu/hardware/pages/living_four.png";
import ImageWithContent from "../ImageWithContent";
import Title from "../fontComponent/Title";
import SubTitle from "../fontComponent/SubTitle";
import Description from "../fontComponent/Description";

import statistics_one from "../../static/menu/hardware/pages/seat_s_one.png";
import statistics_two from "../../static/menu/hardware/pages/seat_s_two.png";
import statistics_three from "../../static/menu/hardware/pages/seat_s_three.png";
import statistics_four from "../../static/menu/hardware/pages/seat_s_four.png";

import serve_mode_one from "../../static/menu/hardware/pages/living_serve_one.png";
import serve_mode_two from "../../static/menu/hardware/pages/living_serve_two.png";
import PageFooter from "../PageFooter";

const items = [
  {
    desc: "feature",
    name: "功能介绍",
  },
  {
    desc: "params",
    name: "技术参数",
  },
  {
    desc: "serve",
    name: "服务模式",
  },
];

const statisticsItmes = [
  {
    title: "施工时长",
    desc: "统计分析",
    img: statistics_one,
  },
  {
    title: "工位闲忙",
    desc: "统计分析",
    img: statistics_two,
  },
  {
    title: "技师施工",
    desc: "统计排名",
    img: statistics_three,
  },
  {
    title: "技师提成",
    desc: "自动计算",
    img: statistics_four,
  },
];

const techologyParams = [
  {
    title: "算法参数",
    type: "size",
    content: [
      [
        "综合识别率：99.93%",
        "新能源车牌识别率：≥99.9%",
        "适应车速范围：0~30Kmh",
        "车牌识别特征：车牌号码、车牌颜色、车辆类型、车标、车款",
      ],
      [
        "识别车牌类型：蓝牌、新能源、警车、武警、单层军牌、双层军牌、单层黄牌、双层黄牌、挂车、港牌、澳牌、使馆、领馆、教练牌、民航牌、应急车牌",
        "触发方式：视频触发、外部触发",
      ],
    ],
  },
  {
    title: "光学镜头",
    type: "size",
    content: [
      ["传感器:1\\2.8”CMOS", "宽动态：≥100db", "有效像素：2304*1296"],
      [
        "镜头：定焦6mm镜头",
        "最低照度：0.1LUX 彩色（补白光）",
        "信噪比：≥50db(AGC OFF)",
      ],
    ],
  },
  {
    title: "视频参数",
    type: "size",
    content: [
      [
        "压缩输出码率：512Kbps~5000Kbps",
        "OSD信息叠加：支持可定义时间，地点，码流信息等",
        "录像：支持PCSD卡NVR录像",
      ],
      ["视频压缩标准：H.264H.265MJPEG", "视频分辨率：1920*1080、2304*1296"],
    ],
  },
  {
    title: "通讯",
    type: "size",
    content: [
      [
        "通讯协议：SDK、ONVIF、HTTP、RTSP、TCPIP、UDP、RS485、IO、NTP",
        "FTP：支持通过FTP上传抓拍图片至服务器",
        "HTTP推送：支持上传识别结果、离线重传",
      ],
      [
        "UPNP端口映射：支持自动映射HTTPRSTP通讯端口",
        "4G扩展：通过USB扩展全网通4G模块",
        "云管理：远程管理单台相机、通过账号集中管理多台相机、支持云SDK开发管理平台",
      ],
    ],
  },
];

const serveMode = [
  {
    title: "硬件+标准应用",
    desc: "适用于汽服门店",
    img: serve_mode_one,
  },
  {
    title: "硬件+API接口",
    desc: "适用于具有开发能力的客户",
    img: serve_mode_two,
  },
];

function LiveStreming() {
  const handleScroll = (e) => {
    const rightOneEle = document.getElementById("right_one");
    const rightTwoEle = document.getElementById("right_two");
    const rightThreeEle = document.getElementById("right_three");

    isInViewPort(rightOneEle) && setAnimateOne(true);
    isInViewPort(rightTwoEle) && setAnimateTwo(true);
    isInViewPort(rightThreeEle) && setAnimateThree(true);
  };

  const isInViewPort = (element) => {
    // const viewWidth = window.innerWidth || document.documentElement.clientWidth;
    const viewHeight =
      window.innerHeight || document.documentElement.clientHeight;
    const { top } = element.getBoundingClientRect();
    return top < viewHeight;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
    return window.removeEventListener("scroll", handleScroll);
  }, []);

  const [rightOne, setAnimateOne] = useState(false);
  const [rightTwo, setAnimateTwo] = useState(false);
  const [rightThree, setAnimateThree] = useState(false);

  const renderSize = (items) => {
    return items.map((item) => {
      return (
        <div style={{ width: 450 }} key={Math.random()}>
          <Space direction="vertical" size={0}>
            <SubTitle
              color="#404558"
              fontWeight={400}
              lineHeight="36px"
              fontSize={16}
              className="ping_fang"
              textAlign="start"
            >
              {item[0]}
            </SubTitle>
            <SubTitle
              color="#404558"
              fontWeight={400}
              lineHeight="36px"
              textAlign="start"
              className="ping_fang"
              fontSize={16}
            >
              {item[1]}
            </SubTitle>
            <SubTitle
              color="#404558"
              fontWeight={400}
              lineHeight="36px"
              textAlign="start"
              className="ping_fang"
              fontSize={16}
            >
              {item[2]}
            </SubTitle>
            <SubTitle
              color="#404558"
              className="ping_fang"
              fontWeight={400}
              lineHeight="36px"
              textAlign="start"
              fontSize={16}
            >
              {item[3]}
            </SubTitle>
            <SubTitle
              color="#404558"
              fontWeight={400}
              lineHeight="36px"
              textAlign="start"
              className="ping_fang"
              fontSize={16}
            >
              {item[4]}
            </SubTitle>
          </Space>
        </div>
      );
    });
  };

  const renderSplit = () => {
    return (
      <div
        style={{
          width: 1200,
          height: "1px",
          opacity: 0.15,
          background: "#404558",
        }}
      ></div>
    );
  };

  return (
    <>
      <PageImage img={backgroundImg}>
        <Space
          direction="vertical"
          style={{ width: "426px" }}
          size={15}
          className="slide-right"
        >
          <Image src={subLogo} width={134} height={58} preview={false} />
          <Space direction="vertical" className="page_one_title" size={1}>
            <div>工位直播摄像头</div>
          </Space>
          <Space
            size={[30, 0]}
            wrap
            className="page_four_subTitle"
            style={{ width: "280px" }}
          >
            <div>施工实况直播</div>
            <div>施工技师识别</div>
            <div>车辆施工提醒</div>
            <div>智能统计分析</div>
          </Space>
        </Space>
        <Image
          className="slide-left"
          src={contentImg}
          width={508}
          preview={false}
          style={{ height: "680px" }}
        />
      </PageImage>
      <SubMenu items={items} title="工位直播摄像头" id="subMenu" />
      <div style={{ height: 120, marginTop: -120 }} id="feature"></div>

      <Space
        size={100}
        direction="vertical"
        style={{
          width: "100vw",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginBottom: 140,
        }}
      >
        <ImageWithContent
          width={1200}
          height={610}
          img={livingOne}
          id="right_one"
          paddingLeft={100}
          className={rightOne ? "slide-in-bottom" : ""}
          paddingRight={115}
          contentJustify="space-between"
        >
          <Space direction="vertical" size={30} id="feature">
            <Title>工位施工直播</Title>
            <Space direction="vertical" size={40}>
              <SubTitle lineHeight="0px" textAlign="start">
                施工实况直播
              </SubTitle>
              <SubTitle lineHeight="0px" textAlign="start">
                施工视频7天回放
              </SubTitle>
              <SubTitle lineHeight="0px" textAlign="start">
                施工监控
              </SubTitle>
            </Space>
          </Space>
          <Image src={rightImg_one} width={547} height={486} preview={false} />
        </ImageWithContent>
        <Space
          width={1200}
          size={40}
          id="right_two"
          className={rightTwo ? "slide-in-bottom" : ""}
        >
          <ImageWithContent
            width={580}
            height={800}
            img={livingTwo}
            containerAlignItems="flex-start"
            contentDirection="column"
            paddingTop={120}
            paddingLeft={100}
          >
            <Space direction="vertical" size={30}>
              <Title>施工技师识别</Title>
              <Space direction="vertical" size={20}>
                <SubTitle lineHeight="20px" textAlign="start">
                  人脸识别施工技师
                </SubTitle>
                <SubTitle lineHeight="20px" textAlign="start">
                  支持多人识别
                </SubTitle>
                <SubTitle lineHeight="20px" textAlign="start">
                  算法排除无关入场人员
                </SubTitle>
              </Space>
            </Space>
            <Image
              src={rightImg_two}
              width={380}
              height={475}
              preview={false}
            />
          </ImageWithContent>
          <ImageWithContent
            width={580}
            height={800}
            paddingLeft={65}
            img={livingThree}
            containerAlignItems="flex-start"
            contentDirection="column"
            paddingTop={120}
          >
            <Space
              direction="vertical"
              size={30}
              style={{ position: "relative", left: 40 }}
            >
              <Title>车辆施工提醒</Title>
              <Space direction="vertical" size={1}>
                <SubTitle textAlign="start">进出工位时间记录</SubTitle>
                <SubTitle textAlign="start">订单状态自动更新施工中 </SubTitle>
              </Space>
            </Space>
            <Image
              src={rightImg_three}
              width={410}
              height={480}
              preview={false}
            />
          </ImageWithContent>
        </Space>
        <ImageWithContent
          width="100vw"
          height={830}
          img={livingFour}
          paddingTop={100}
          contentHeight={830}
          contentAlignItems="center"
          contentJustify="space-between"
          contentDirection="column"
          id="right_three"
        >
          <Space direction="vertical" size={100}>
            <Title
              color="white"
              className={rightThree ? "slide-in-bottom" : ""}
            >
              工位智能统计分析
            </Title>
            <Space size={20} className={rightThree ? "slide-in-bottom" : ""}>
              {statisticsItmes.map((item) => {
                return (
                  <div
                    style={{
                      width: 285,
                      height: 365,
                      background: "#FFFFFF",
                      paddingTop: 84,
                      paddingBottom: 60,
                      borderRadius: 15,
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    key={item.title}
                  >
                    <Space size={5}>
                      <SubTitle fontWeight={700}>{item.title}</SubTitle>
                      <SubTitle fontWeight={200}>{item.desc}</SubTitle>
                    </Space>
                    <Image
                      src={item.img}
                      width={135}
                      height={135}
                      preview={false}
                    />
                  </div>
                );
              })}
            </Space>
          </Space>
        </ImageWithContent>
      </Space>
      <div style={{ height: 120, marginTop: -120 }} id="params"></div>

      <Space
        size={100}
        direction="vertical"
        style={{
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Title>技术参数</Title>
        <Space
          style={{
            width: 1200,
            position: "relative",
            top: -70,
          }}
          direction="vertical"
          size={70}
          split={renderSplit()}
        >
          <div></div>
          {techologyParams.map((item) => {
            return (
              <div
                key={Math.random()}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <SubTitle
                  color="#343744"
                  fontWeight="bold"
                  fontSize={18}
                  textAlign="start"
                  width={120}
                  yuanTi
                >
                  {item.title}
                </SubTitle>
                {renderSize(item.content)}
              </div>
            );
          })}
          <div />
        </Space>
      </Space>
      <div style={{ height: 120, marginTop: -120 }} id="serve"></div>

      <Space
        size={70}
        direction="vertical"
        style={{
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "140px",
        }}
      >
        <Title>服务模式</Title>
        <Space
          style={{
            width: 1200,
          }}
          wrap
          size={20}
        >
          {serveMode.map((item) => {
            return (
              <div className="serve_mode_container" key={item.desc}>
                <Space direction="vertical" size={25}>
                  <SubTitle
                    fontWeight={700}
                    fontSize="24px"
                    lineHeight="0px"
                    textAlign="start"
                  >
                    {item.title}
                  </SubTitle>
                  <Description textAlign="start">{item.desc}</Description>
                </Space>
                <Image
                  src={item.img}
                  width={200}
                  height={200}
                  preview={false}
                />
              </div>
            );
          })}
        </Space>
      </Space>
      <PageFooter />
    </>
  );
}

export default LiveStreming;
