import { Space, Image, Carousel } from "antd";
import React, { useRef, useEffect, useState } from "react";
import "./about.css";
import PageImage from "../PageImage";
import backgroundImg from "../../static/menu/about/about_background.png";
import backgroundImgSl from "../../static/menu/about/about_background_sl.jpg";

import subLogo from "../../static/slider/page_one_sub_logo@2x.png";
import contentImg from "../../static/menu/about/about_right_img.png";

import AboutOne from "../../static/menu/about/about_right_img_one.png";
import AboutTwo from "../../static/menu/about/about_right_img_two.png";
import AboutThree from "../../static/menu/about/about_right_img_three.png";

import AboutFour from "../../static/menu/about/about_right_img_four.png";
import AboutFive from "../../static/menu/about/about_right_img_five.png";

import AboutAsk from "../../static/menu/about/about_ask.png";
import logoImage from "../../static/menu/SEAYUN_logo@2x.png";
import ASK from "../../static/menu/about/ask.png";

import ImageWithContent from "../ImageWithContent";
import Title from "../fontComponent/Title";
import SubTitle from "../fontComponent/SubTitle";
import Description from "../fontComponent/Description";
import PageFooter from "../PageFooter";
import Gallery from "./Gallery";
import AboutForm from "./Form";

const header = [
  {
    img: AboutOne,
    title: "宗旨",
    color: "#4B83E4",
    content: ["让车主养车", "简简单单明明白白"],
  },
  {
    img: AboutTwo,
    title: "使命",
    color: "#12C194",
    content: ["引领汽车后市场从传统运营模式向数智化运营模式转型升级"],
  },
  {
    img: AboutThree,
    title: "愿景",
    color: "#F69120",
    content: ["做中国最关注车主体验的公司"],
  },
];

function About() {
  const handleScroll = (e) => {
    const rightOneEle = document.getElementById("right_one");
    const rightTwoEle = document.getElementById("right_two");
    const rightThreeEle = document.getElementById("right_three");

    const isInViewPort = (element) => {
      // const viewWidth = window.innerWidth || document.documentElement.clientWidth;
      const viewHeight =
        window.innerHeight || document.documentElement.clientHeight;
      const { top } = element?.getBoundingClientRect();
      return top < viewHeight;
    };

    isInViewPort(rightOneEle) && setAnimateOne(true);
    isInViewPort(rightTwoEle) && setAnimateTwo(true);
    isInViewPort(rightThreeEle) && setAnimateThree(true);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
    return window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    window.location.hash = window.decodeURIComponent(window.location.hash);
    const scrollToAnchor = () => {
      const hashParts = window.location.hash.split("#");
      if (hashParts.length > 1) {
        const offsetTop = document
          .getElementById(`${hashParts[1]}`)
          ?.getBoundingClientRect().top;
        if (offsetTop > 600) {
          document.body.scrollTo({
            top: offsetTop - 60,
          });
        }
      }
    };
    scrollToAnchor();
    window.onhashchange = scrollToAnchor;
  });

  const [rightOne, setAnimateOne] = useState(false);
  const [rightTwo, setAnimateTwo] = useState(false);
  const [rightThree, setAnimateThree] = useState(false);

  const galleryRef = useRef();
  const next = () => {
    galleryRef.current.next();
  };

  const prev = () => {
    galleryRef.current.prev();
  };
  return (
    <>
      <PageImage img={backgroundImg} slImg={backgroundImgSl}>
        <ImageWithContent
          img={contentImg}
          width={1200}
          height={680}
          imgWidth={801}
          imgHeight={680}
          imgOffset={100}
          imgJustify="flex-end"
          containerAlignItems="center"
          contentDirection="column"
          animate="slide-left"
        >
          <Space direction="vertical" size={30} className="slide-right">
            <Space direction="vertical" className="page_one_title" size={1}>
              <div>汽服生态系</div>
              <div>数智化全栈解决方案提供商</div>
            </Space>
            <Image src={subLogo} width={217} height={40} preview={false} />
          </Space>
        </ImageWithContent>
      </PageImage>

      <Space
        size={100}
        direction="vertical"
        style={{
          marginTop: 150,
          width: "100vw",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <ImageWithContent
          width={1200}
          height={220}
          id="right_one"
          className={rightOne ? "slide-in-bottom" : ""}
          contentJustify="space-between"
        >
          {header.map((item) => {
            return (
              <ImageWithContent
                width={373}
                key={item.title}
                height={220}
                img={item.img}
                containerAlignItems="center"
                paddingLeft={40}
                paddingTop={35}
                paddingRight={40}
                contentJustify="flex-start"
                contentDirection="column"
                contentHeight={220}
              >
                <div
                  style={{
                    width: 100,
                    height: 32,
                    background: item.color,
                    borderRadius: 17,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 18,
                    color: "#FFFFFF",
                    fontWeight: 400,
                    marginBottom: 15,
                    fontFamily: "STYuanti-SC-Regular",
                  }}
                >
                  {item.title}
                </div>
                <div>
                  {item.content.map((ele) => {
                    return (
                      <div
                        key={Math.random()}
                        style={{
                          fontSize: "23px",
                          color: item.color,
                          lineHeight: "37px",
                          fontWeight: 700,
                          fontFamily: "Yuanti SC",
                        }}
                      >
                        {ele}
                      </div>
                    );
                  })}
                </div>
              </ImageWithContent>
            );
          })}
        </ImageWithContent>
        <Space
          direction="vertical"
          style={{ width: 1200 }}
          size={50}
          id="right_two"
          className={rightTwo ? "slide-in-bottom" : ""}
        >
          <SubTitle
            textAlign="start"
            fontSize={20}
            color="#1F2129"
            fontWeight={400}
            lineHeight="32px"
          >
            成都深云智能科技有限公司成立于2018年10月，高新技术企业，定位是汽服生态系数智化全栈解决方案提供商，致力于使用大数据、算法、智能硬件、移动互联网等技术，围绕养车门店，以车主为中心，基于汽车全生命周期大数据对车辆养车需求进行精准诊断分级导流落地服务，大幅提升车主用车养车体验，提升行业的运营效率，实现汽车后市场产业链的标准化、在线化、数字化和智能化，引领汽车后市场从传统商业向智能商业转型升级。
          </SubTitle>
          <SubTitle
            textAlign="start"
            fontSize={20}
            color="#1F2129"
            fontWeight={400}
            lineHeight="32px"
          >
            深云智能创始团队来自平安金融科技、利宝保险等企业，具备丰富的互联网、金融、汽车后市场等领域跨界整合经验，拥有优秀的创新能力。
          </SubTitle>
        </Space>
        <Carousel
          className="about_carousel_container"
          infinite
          ref={galleryRef}
          slidesToShow={0}
          lazyLoad="progressive"
          effect="fade"
          dots={false}
        >
          <Gallery img={AboutFour} next={next} prev={prev} />
          <Gallery img={AboutFive} next={next} prev={prev} />
        </Carousel>
        <ImageWithContent
          width="100vw"
          height={1285}
          className={rightThree ? "slide-in-bottom" : ""}
          background="rgba(244,248,251,0.99)"
          containerAlignItems="center"
          id="right_three"
        >
          <Space size={40} id="ask">
            <div
              style={{
                width: 710,
                height: 1015,
                background: "#FFFFFF",
                borderRadius: 15,
                paddingLeft: 80,
                paddingTop: 80,
                paddingRight: 80,
              }}
            >
              <Space direction="vertical" size={20} style={{ width: 550 }}>
                <Title textAlign="start">我要咨询</Title>
                <Description lineHeight="0px" textAlign="start">
                  请在下方留下您的联系方式，我们将在1～3个工作日内与您联系
                </Description>
                <AboutForm />
              </Space>
            </div>
            <div
              style={{
                display: "flex",
                height: 1015,
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <ImageWithContent
                img={AboutAsk}
                width={450}
                height={700}
                paddingBottom={77}
                paddingLeft={60}
                paddingTop={60}
                contentHeight={700}
                contentDirection="column"
                contentJustify="space-between"
              >
                <Image
                  src={ASK}
                  width={94}
                  height={98}
                  style={{ position: "relative", left: 33 }}
                  className="slide-in-bottom"
                  preview={false}
                />
                <Space direction="vertical" size={10}>
                  <SubTitle
                    fontSize={18}
                    yuanTi
                    textAlign="start"
                    fontWeight={700}
                  >
                    咨询电话
                  </SubTitle>
                  <SubTitle
                    yuanTi
                    textAlign="start"
                    fontSize={32}
                    fontWeight={700}
                  >
                    028-61389005
                  </SubTitle>
                </Space>
              </ImageWithContent>
              <div
                style={{
                  width: 452,
                  height: 285,
                  background: "#FFFFFF",
                  borderRadius: 15,
                  paddingLeft: 60,
                  paddingRight: 60,
                  paddingBottom: 80,
                  paddingTop: 80,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <Image src={logoImage} width={161} height={32} preview={false} />
                <Space direction="vertical" size={15}>
                  <SubTitle fontSize={16} yuanTi textAlign="start">
                    成都深云数智科技有限公司
                  </SubTitle>
                  <Description lineHeight="0px" fontSize={14}>
                    成都市高新区交子大道333号中海国际中心E座5楼
                  </Description>
                </Space>
              </div>
            </div>
          </Space>
        </ImageWithContent>
      </Space>
      <PageFooter />
    </>
  );
}

export default About;
