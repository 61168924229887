import React from "react";
import ImageWithContent from "./component/ImageWithContent";
import SLIDER_ONE from "../static/mobile/slider/slider_one.png";
import LOGO from "../static/mobile/slider/slide_one/logo.png";
import Text from "./component/Text";
import { Image } from "antd-mobile";
import SliderGo from "./component/SliderGo";
import { Link } from "react-router-dom";

const SliderOne = (props) => {
  const { height, top, className } = props;
  return (
    <Link to="/about">
      <ImageWithContent
        className={className}
        top={top}
        img={SLIDER_ONE}
        height={height}
        marginRight={8}
        paddingTop={60}
        paddingLeft={30}
        paddingRight={30}
        contentJustify="flex-start"
        contentDirection="column"
      >
        <Text fontFamily="bold" fontSize={28} color="#FFFFFF">
          汽服生态系数智化
        </Text>
        <Text fontFamily="bold" fontSize={28} color="#FFFFFF" top={-6}>
          全栈解决方案提供商
        </Text>
        <Text
          fontFamily="bold"
          fontSize={14}
          color="#FFFFFF"
          top={15}
          bottom={20}
        >
          汽车后市场C2S2b产业互联网
        </Text>
        <Image src={LOGO} width={162} height={30} />
      </ImageWithContent>
      <SliderGo href={"/about"} />
    </Link>
  );
};

export default SliderOne;
