/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from "react";
import { Image } from "antd";
import OPEN from "../../static/mobile/menu/open.png";
import CLOSE from "../../static/mobile/menu/close.png";
import LOGO from "../../static/menu/SEAYUN_logo@2x.png";
import ASK from "../../static/mobile/menu/menu_check.png";
import SlideMenu from "./SlideMenu";
import { Link } from "react-router-dom";

const Menu = (props) => {
  const { children } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <div
        className="slide_menu_filter"
        style={{
          width: "100vw",
          height: "50px",
          background: "rgba(17,17,19,0.15)",
          position: "fixed",
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 5,
          zIndex: 9999,
          paddingBottom: 5,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Image
          src={isOpen ? CLOSE : OPEN}
          width={40}
          preview={false}
          height={40}
          className={isOpen ? "rotate-in-2-cw" : ""}
          onClick={() => setIsOpen(!isOpen)}
        />
        <Image
          src={LOGO}
          height={20}
          preview={false}
          style={{ position: "relative", top: 10 }}
        />
        <Link to="/about#ask">
          <Image src={ASK} width={40} height={40} preview={false} />
        </Link>
      </div>
      {isOpen ? (
        <SlideMenu
          close={() => {
            setIsOpen(false);
          }}
        />
      ) : (
        ""
      )}
      {children}
    </div>
  );
};

export default Menu;
