import { Image } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import LearnMore from "../static/menu/ecology-create/learn_more_arrow.png";

const LearnMoreRadius = ({ href }) => {
  return (
    <Link to={href}>
      <div
        className="learn_more_radius_container"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="learn_more_radius_text">了解详情</div>
        <Image
          src={LearnMore}
          width={20}
          height={20}
          preview={false}
          style={{ position: "relative", top: -2 }}
        />
      </div>
    </Link>
  );
};

export default LearnMoreRadius;
