import React from "react";
import Layout from "../../Layout";
import { Image } from "antd-mobile";
import { Space } from "antd";
import Text from "../Text";

import ONE from "../../../static/mobile/menu/sofoWare/soft_one.png";
import ONE_LOGO from "../../../static/mobile/menu/sofoWare/soft_one_logo.png";
import TWO from "../../../static/mobile/menu/sofoWare/soft_two.png";
import TWO_LOGO from "../../../static/mobile/menu/sofoWare/soft_two_logo.png";

import { Link } from "react-router-dom";

import Footer from "../Footer";
import PaddingBox from "../PaddingBox";

const SOFT_WARE_SUB_MENU = [
  {
    img: ONE,
    logo: ONE_LOGO,
    text: "汽服门店数智操作系统，满足你关于门店数智化的所有想象。洗美店、快修快保店、装潢店、综合修理厂...",
    href: "/youchelai",
  },
  {
    img: TWO,
    logo: TWO_LOGO,
    text: "数智汽配操作系统，汽配品牌商、分销商、汽配门店",
    href: "",
  },
];

const SoftWare = () => {
  return (
    <Layout background="#151618">
      <PaddingBox>
        <Space direction="vertical" size={20} style={{ marginTop: 105 }}>
          {SOFT_WARE_SUB_MENU.map((item) => {
            return (
              <Link
                key={Math.random()}
                to={item.href}
                style={{
                  background: "#FFFFFF",
                  borderRadius: 10,
                  paddingTop: 15,
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingBottom: 24,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Image src={item.img} width={219} height={160} />
                <Image
                  src={item.logo}
                  width={96}
                  height={30}
                  style={{ marginTop: 10 }}
                />
                <Text
                  fontSize={12}
                  fontFamily="pingFang"
                  color="#1F2129"
                  top={15}
                  style={{ textAlign: "center" }}
                  opacity={0.5}
                >
                  {item.text}
                </Text>
              </Link>
            );
          })}
        </Space>
      </PaddingBox>
      <Footer style={{ marginTop: 45, marginBottom: 15 }}></Footer>
    </Layout>
  );
};

export default SoftWare;
