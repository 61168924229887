/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import Layout from "./Layout";
import Swiper from "./component/Swiper";
import Footer from "./component/Footer";

const Mobile = () => {
  return (
    <Layout>
      <div
        style={{
          width: "100vw",
          background: "#151618",
          paddingTop: 105,
          paddingBottom: 15,
        }}
      >
        <Swiper />
        <Footer style={{ marginTop: 58 }} />
      </div>
    </Layout>
  );
};

export default Mobile;
