/* eslint-disable react-hooks/exhaustive-deps */
import { Space } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const SubMenu = (props) => {
  const [onChooseItem, setOnChooseItem] = useState();

  const handleScroll = (e) => {
    if (e.target.scrollTop >= 610) {
      setOnIsSubMenuFix(true);
    } else {
      setOnIsSubMenuFix(false);
    }
  };

  const [isSubMenuFix, setOnIsSubMenuFix] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
    return window.removeEventListener("scroll", handleScroll);
  });

  const { title, items, background, marginBottom } = props;
  return (
    <>
      <div
        className={`sub_menu_filter`}
        style={{
          marginBottom: marginBottom || "100px",
          width: "100vw",
          height: "60px",
          position: "relative",
          top: 0,
          zIndex: 11,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: background,
          boxShadow: "0px 8px 10px -15px #000",
        }}
      >
        <div
          style={{
            width: 1200,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontFamily: "Yuanti SC",
              fontSize: 18,
              color: "#343744",
              letterSpacing: 0,
              fontWeight: 700,
              zIndex: 15,
            }}
          >
            {title}
          </div>
          <Space size={58}>
            {items &&
              items.map((item) => {
                return (
                  <a
                    className={`sub_menu_items_text ${
                      onChooseItem === item.desc
                        ? "sub_menu_items_text_active"
                        : ""
                    }`}
                    href={`#${item.desc}`}
                    key={item.desc}
                    onClick={(e) => {
                      setOnIsSubMenuFix(true);
                      setOnChooseItem(item.desc);
                    }}
                  >
                    {item.name}
                  </a>
                );
              })}
            <div className="sub_menu_ask_button">
              <Link to={"/about#ask"} className="sub_menu_ask_text">
                咨询
              </Link>
            </div>
          </Space>
        </div>
      </div>
      {isSubMenuFix ? (
        <div
          className={`sub_menu_filter`}
          style={{
            marginBottom: marginBottom,
            width: "100vw",
            height: "60px",
            position: "fixed",
            top: 60,
            zIndex: 11,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(255,255,255,0.85)",
            boxShadow: "0px 8px 10px -15px #000",
          }}
        >
          <div
            style={{
              width: 1200,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontFamily: "Yuanti SC",
                fontSize: 18,
                color: "#343744",
                letterSpacing: 0,
                fontWeight: 700,
                zIndex: 15,
              }}
            >
              {title}
            </div>
            <Space size={58}>
              {items &&
                items.map((item) => {
                  return (
                    <a
                      className={`sub_menu_items_text ${
                        onChooseItem === item.desc
                          ? "sub_menu_items_text_active"
                          : ""
                      }`}
                      href={`#${item.desc}`}
                      key={item.desc}
                      onClick={(e) => {
                        setOnIsSubMenuFix(true);
                        setOnChooseItem(item.desc);
                      }}
                    >
                      {item.name}
                    </a>
                  );
                })}
              <div className="sub_menu_ask_button">
                <Link to={"/about#ask"} className="sub_menu_ask_text">
                  咨询
                </Link>
              </div>
            </Space>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default SubMenu;
