import React from "react";

const SubTitle = ({
  children,
  fontWeight,
  color,
  textAlign,
  fontSize,
  lineHeight,
  yuanTi,
  id,
  className,
  position,
  whiteSpace,
  top,
  width,
}) => {
  return (
    <div
      id={id}
      className={className}
      style={{
        position: position,
        top: top,
        fontFamily: yuanTi ? "Yuanti SC" : "STYuanti-SC-Regular",
        fontSize: fontSize || "20px",
        color: color || "#343744",
        lineHeight: lineHeight || "42px",
        textAlign: textAlign || "center",
        fontWeight: fontWeight || "400",
        letterSpacing: 0,
        width: width,
        whiteSpace: whiteSpace,
      }}
    >
      {children}
    </div>
  );
};

export default SubTitle;
