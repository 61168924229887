import "./style/pageStyle.css";
import "./style/homePage.css";
import { Space, Image } from "antd";
import SliderImage from "./SliderImage";
import React from "react";
import subLogo from "../static/slider/page_five_sub_logo@2x.png";
import contentImg from "../static/slider/page_five_right_img@2x.png";
// import SliderFive from "../static/slider/home_page_five@2x.png";
import SliderFive from "../static/slider/jpg/home_page_five@2x.jpg";
import ImageWithContent from "./ImageWithContent";
import sliderPreview from "../static/slider/home_page_five_preview.jpg";
import LearnMore from "./LearnMore";

const HomePageFive = (props) => {
  const { next, prev, animate } = props;
  return (
    <SliderImage
      img={SliderFive}
      placeholderImg={sliderPreview}
      next={next}
      prev={prev}
    >
      <ImageWithContent
        width={1200}
        height="100vh"
        img={contentImg}
        imgWidth={1109}
        imgHeight={860}
        imgJustify="flex-end"
        containerAlignItems="center"
        contentDirection="column"
        imgOffset={150}
        animate={animate ? "slide-left-d" : ""}
      >
        <Space direction="vertical" size={20}>
          <Image
            src={subLogo}
            width={117}
            height={58}
            preview={false}
            className={animate ? "slide-left-a" : ""}
          />
          <Space
            direction="vertical"
            className={`page_one_title ${animate ? "slide-left-c" : ""}`}
            size={1}
            style={{ whiteSpace: "nowrap" }}
          >
            <div>汽服大数据及算法服务</div>
          </Space>
          <div className={`page_one_subTitle ${animate ? "slide-left-d" : ""}`}>
            汽车后市场数智底座
          </div>
          <LearnMore
            href={"/data"}
            top="75px"
            className={animate ? "slide-left-e" : ""}
          />
        </Space>
      </ImageWithContent>
    </SliderImage>
  );
};

export default HomePageFive;
