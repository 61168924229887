import React, { useState } from "react";
import { Image } from "antd";
import arrowRight from "../../static/menu/about/about_right_d.png";
import hightLightArrowRight from "../../static/menu/about/about_right_h.png";

const RightArrow = React.memo(({ next }) => {
  const [isLight, setImgHightLight] = useState(false);
  return (
    <div
      onClick={() => next()}
      onMouseOver={() => {
        setImgHightLight(true);
      }}
      onMouseLeave={() => {
        setImgHightLight(false);
      }}
    >
      <Image
        src={isLight ? hightLightArrowRight : arrowRight}
        width={40}
        height={40}
        preview={false}
      />
    </div>
  );
});

export default RightArrow;
