import React from "react";
import { Image, Space } from "antd";

import LeftArrow from "./LeftArrow";
import RightArrow from "./RightArrow";

const Gallery = ({ img, next, prev }) => {
  return (
    <Space size={60}>
      <LeftArrow prev={prev} />
      <Image src={img} width={1200} height={400} preview={false} />
      <RightArrow next={next} />
    </Space>
  );
};

export default Gallery;
