import React, { useEffect, useState } from "react";
import Layout from "../../Layout";
import BG from "../../../static/mobile/pages/camera/bg.png";
import ImageWithContent from "../ImageWithContent";
import { Image } from "antd-mobile";
import { Space } from "antd";
import Text from "../Text";
import LOGO from "../../../static/mobile/slider/slide_four/logo.png";
import LivingParams from "./LivingParams";

import ONE from "../../../static/mobile/pages/camera/c_one.png";
import TWO from "../../../static/mobile/pages/camera/c_two.png";
import THREE from "../../../static/mobile/pages/camera/c_three.png";
import FOUR from "../../../static/mobile/pages/camera/c_st.png";
import S_ONE from "../../../static/mobile/pages/camera/serve_one.png";
import S_TWO from "../../../static/mobile/pages/camera/serve_two.png";

import PaddingBox from "../PaddingBox";
import Footer from "../Footer";

const serve = [
  {
    img: S_ONE,
    title: "硬件+标准应用",
    subTitle: "适用于汽服门店",
  },
  {
    img: S_TWO,
    title: "硬件+API接口",
    subTitle: "适用于具有开发能力的客户",
  },
];

const Living = () => {
  const [osWidth, setOSWidth] = useState();

  const [bgWidth, setBgWidth] = useState();

  useEffect(() => {
    setOSWidth(document.documentElement.clientWidth - 30);
    setBgWidth((document.documentElement.clientWidth - 40) / 2);
  }, []);

  return (
    <Layout>
      <ImageWithContent
        img={BG}
        paddingTop={135}
        paddingLeft={40}
        paddingRight={45}
        contentJustify="flex-start"
        contentDirection="column"
      >
        <Image src={LOGO} width={81} height={35} />
        <Text fontFamily="bold" fontSize={28} color="#FFFFFF" top={3}>
          工位直播摄像头
        </Text>
        <Text fontFamily="bold" fontSize={14} color="#FFFFFF" top={7}>
          施工实况直播&nbsp;&nbsp;施工技师识别
        </Text>
        <Text fontFamily="bold" fontSize={14} color="#FFFFFF" top={0}>
          车辆施工提醒&nbsp;&nbsp;智能统计分析
        </Text>
      </ImageWithContent>

      <PaddingBox top={20}>
        <ImageWithContent
          img={ONE}
          paddingTop={45}
          paddingLeft={33}
          contentDirection="column"
          contentJustify="flex-start"
        >
          <Text fontFamily="bold" fontSize={18} color="#343744">
            工位施工直播
          </Text>
          <Text
            fontFamily="normal"
            fontSize={12}
            top={10}
            color="#343744"
            opacity={0.65}
          >
            施工实况直播
          </Text>
          <Text
            fontFamily="normal"
            fontSize={12}
            color="#343744"
            opacity={0.65}
          >
            施工视频7天回放
          </Text>
          <Text
            fontFamily="normal"
            fontSize={12}
            color="#343744"
            opacity={0.65}
          >
            施工监控
          </Text>
        </ImageWithContent>
      </PaddingBox>

      <PaddingBox top={20} bottom={20}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <ImageWithContent
            img={TWO}
            imgWidth={bgWidth}
            paddingTop={40}
            paddingLeft={20}
            contentDirection="column"
            contentJustify="flex-start"
          >
            <Text fontSize={18} color="#343744" fontFamily="bold">
              施工技师识别
            </Text>
            <Text
              fontSize={10}
              top={10}
              color="#343744"
              fontFamily="normal"
              opacity={0.65}
            >
              人脸识别施工技师
            </Text>
            <Text
              fontSize={10}
              color="#343744"
              fontFamily="normal"
              opacity={0.65}
            >
              支持多人识别
            </Text>
            <Text
              fontSize={10}
              color="#343744"
              fontFamily="normal"
              opacity={0.65}
            >
              算法排除无关入场人员
            </Text>
          </ImageWithContent>
          <ImageWithContent
            img={THREE}
            imgWidth={bgWidth}
            paddingTop={40}
            paddingLeft={20}
            contentDirection="column"
            contentJustify="flex-start"
          >
            <Text fontSize={18} color="#343744" fontFamily="bold">
              车辆施工提醒
            </Text>
            <Text
              fontSize={10}
              top={5}
              color="#343744"
              fontFamily="normal"
              opacity={0.65}
            >
              进出工位时间记录
            </Text>
            <Text
              fontSize={10}
              color="#343744"
              fontFamily="normal"
              opacity={0.65}
            >
              订单状态自动更新施工中
            </Text>
          </ImageWithContent>
        </div>
      </PaddingBox>
      <Image src={FOUR} />
      <Text
        color="#343744"
        fontFamily="bold"
        fontSize={20}
        top={50}
        style={{ textAlign: "center" }}
      >
        技术参数
      </Text>

      <LivingParams />
      <Text
        color="#343744"
        fontFamily="bold"
        fontSize={20}
        top={50}
        style={{ textAlign: "center" }}
      >
        服务模式
      </Text>
      <PaddingBox top={30}>
        <Space size={20} direction="vertical">
          {serve.map((item) => {
            return (
              <div
                style={{
                  background: "#FFFFFF",
                  width: osWidth,
                  border: "3px solid rgba(244,248,251,1)",
                  borderRadius: 10,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingBottom: 20,
                  paddingTop: 20,
                  paddingLeft: 35,
                  paddingRight: 25,
                }}
              >
                <Space direction="vertical" size={0}>
                  <Text
                    fontFamily="bold"
                    fontSize={16}
                    color="#343744"
                    top={36}
                  >
                    {item.title}
                  </Text>
                  <Text
                    fontFamily="pingFang"
                    fontSize={12}
                    color="#343744"
                    top={8}
                  >
                    {item.subTitle}
                  </Text>
                </Space>
                <Image src={item.img} width={120} height={120} />
              </div>
            );
          })}
        </Space>
      </PaddingBox>

      <Footer style={{ marginTop: 63, marginBottom: 15 }}></Footer>
    </Layout>
  );
};

export default Living;
