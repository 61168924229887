import { Image } from "antd";
import React from "react";

const ImageWithContent = (props) => {
  const {
    id,
    img,
    children,
    width,
    height,
    containerAlignItems,
    contentJustify,
    contentAlignItems,
    contentDirection,
    imgJustify,
    imgWidth,
    imgHeight,
    imgOffset,
    paddingTop,
    paddingLeft,
    paddingRight,
    paddingBottom,
    contentHeight,
    background,
    animate,
    className,
    fwidth,
  } = props;
  return (
    <div
      id={id}
      className={className}
      style={{
        width: width,
        position: "relative",
        height: height,
        display: "flex",
        flexDirection: "row",
        justifyContent: imgJustify || "center",
        alignItems: "center",
        background: background || "",
      }}
    >
      {img && (
        <Image
          className={animate}
          src={img}
          width={imgWidth || width}
          style={{
            width: fwidth || "",
            height: imgHeight || height,
            position: "relative",
            top: 0,
            left: imgOffset || 0,
          }}
          preview={false}
        />
      )}
      <div
        style={{
          position: "absolute",
          width: width,
          height: height,
          top: "0",
          display: "flex",
          justifyContent: "center",
          alignItems: containerAlignItems || "flex-end",
        }}
      >
        <div
          style={{
            width: "1200px",
            display: "flex",
            height: contentHeight,
            paddingLeft: paddingLeft || 0,
            paddingRight: paddingRight || 0,
            paddingTop: paddingTop || 0,
            paddingBottom: paddingBottom || 0,
            flexDirection: contentDirection || "row",
            justifyContent: contentJustify || "space-around",
            alignItems: contentAlignItems || "flex-start",
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default ImageWithContent;
