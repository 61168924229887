/* eslint-disable react-hooks/exhaustive-deps */
import "./style/hardware.css";
import { Space, Image } from "antd";
import React, { useEffect, useState } from "react";
import PageImage from "../PageImage";
import backgroundImg from "../../static/menu/hardware/pages/camera_background.png";
import backgroundImgSl from "../../static/menu/hardware/pages/camera_background_sl.jpg";

import subLogo from "../../static/slider/page_four_sub_logo@2x.png";
import contentImg from "../../static/menu/hardware/pages/camera_right_img@2x.png";
import SubMenu from "../SubMenu";
import cameraOne from "../../static/menu/hardware/pages/camera_one_background@2x.png";
import cameraTwo from "../../static/menu/hardware/pages/camera_two_background@2x.png";
import cameraThree from "../../static/menu/hardware/pages/camera_three_background@2x.png";
import rightImg_one from "../../static/menu/hardware/pages/camera_one_background_right_img2x.png";
import rightImg_two from "../../static/menu/hardware/pages/camera_two_background_right_img2x.png";
import ImageWithContent from "../ImageWithContent";
import Title from "../fontComponent/Title";
import SubTitle from "../fontComponent/SubTitle";
import Description from "../fontComponent/Description";
import statistics_one from "../../static/menu/hardware/pages/statistics_one.png";
import statistics_two from "../../static/menu/hardware/pages/statistics_two.png";
import statistics_three from "../../static/menu/hardware/pages/statistics_three.png";
import statistics_four from "../../static/menu/hardware/pages/statistics_four.png";
import statistics_five from "../../static/menu/hardware/pages/statistics_five.png";
import statistics_six from "../../static/menu/hardware/pages/statistics_six.png";
import serve_mode_one from "../../static/menu/hardware/pages/serve_mode_one.png";
import serve_mode_two from "../../static/menu/hardware/pages/serve_mode_two.png";
import PageFooter from "../PageFooter";

const items = [
  {
    desc: "feature",
    name: "功能介绍",
  },
  {
    desc: "params",
    name: "技术参数",
  },
  {
    desc: "serve",
    name: "服务模式",
  },
];

const statisticsItmes = [
  {
    title: "车辆进场时段",
    desc: "分布统计",
    img: statistics_one,
  },
  {
    title: "进场车辆数",
    desc: "统计分析",
    img: statistics_two,
  },
  {
    title: "车辆品牌、车型、车系、车龄",
    desc: "统计分析",
    img: statistics_three,
  },
  {
    title: "进场车辆新老客户",
    desc: "统计分析",
    img: statistics_four,
  },
  {
    title: "进场车辆办卡客户",
    desc: "统计分析",
    img: statistics_five,
  },
  {
    title: "进店转化漏斗分析",
    desc: "进场量、订单量、施工量",
    img: statistics_six,
  },
];

const techologyParams = [
  {
    title: "算法参数",
    type: "size",
    content: [
      [
        "综合识别率：99.93%",
        " 黄牌货车识别率：99.90%",
        "适应车速范围：0~60Kmh",
        "触发方式：视频触发、外部触发",
        "车牌防伪：支持异常车牌（手机拍照、打印）告警",
      ],
      [
        "识别车牌类型：蓝牌、新能源、警车、武警、单层军牌、双层军牌、单层黄牌、双层黄牌、挂车、港牌、澳牌、使馆、领馆、教练牌、民航牌、应急车牌",
        "车型识别：支持主流车型识别",
        "无牌车触发：支持无牌车视频触发",
      ],
    ],
  },
  {
    title: "光学镜头",
    type: "size",
    content: [
      [
        "压缩输出码率：512Kbps~5000Kbps",
        "OSD信息叠加：支持，可定义时间，地点，码流信息等",
        "录像：支持PCSD卡NVR录像",
        "视频压缩标准：H.264H.265MJPEG",
      ],
      [
        "语音对讲：支持云本地双向语音对讲",
        "屏显协议：支持对接主流品牌的LED屏显，输出识别计费结果",
        " 音频输出：支持外接喇叭播报识别计费结果",
      ],
    ],
  },
  {
    title: "通讯",
    type: "size",
    content: [
      [
        "通讯协议：SDK、ONVIF、HTTP、RTSP、TCPIP、UDP、RS485、IO、NTP",
        " FTP上传图片：支持通过FTP上传抓拍图片至服务器",
        " VPN虚拟专网：支持通过OpenVPN组建虚拟专网",
        " HTTP推送：支持上传识别结果、离线重传",
      ],
      [
        "动态域名：支持对接3322动态域名服务",
        " UPNP端口映射：支持自动映射HTTPRSTP通讯端口",
        " 4G扩展：通过USB扩展全网通4G模块（选配）、支持链路冗余备份",
        " 云管理 ：远程管理单台相机、通过账号集中管理多台相机、支持云SDK开发管理平台",
      ],
    ],
  },
];

const serveMode = [
  {
    title: "硬件+标准应用",
    desc: "适用于汽服门店",
    img: serve_mode_one,
  },
  {
    title: "硬件+API接口",
    desc: "适用于具有开发能力的客户",
    img: serve_mode_two,
  },
];

function Camera() {
  const handleScroll = (e) => {
    const rightOneEle = document.getElementById("right_one");
    const rightTwoEle = document.getElementById("right_two");
    const rightThreeEle = document.getElementById("right_three");

    isInViewPort(rightOneEle) && setAnimateOne(true);
    isInViewPort(rightTwoEle) && setAnimateTwo(true);
    isInViewPort(rightThreeEle) && setAnimateThree(true);
  };

  const isInViewPort = (element) => {
    // const viewWidth = window.innerWidth || document.documentElement.clientWidth;
    const viewHeight =
      window.innerHeight || document.documentElement.clientHeight;
    const { top } = element.getBoundingClientRect();
    return top < viewHeight;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
    return window.removeEventListener("scroll", handleScroll);
  }, []);

  const [rightOne, setAnimateOne] = useState(false);
  const [rightTwo, setAnimateTwo] = useState(false);
  const [rightThree, setAnimateThree] = useState(false);

  const renderSize = (items) => {
    return items.map((item) => {
      return (
        <div style={{ width: 450 }} key={Math.random()}>
          <Space direction="vertical" size={0}>
            <SubTitle
              color="#404558"
              fontWeight={400}
              lineHeight="36px"
              fontSize={16}
              className="ping_fang"
              textAlign="start"
            >
              {item[0]}
            </SubTitle>
            <SubTitle
              color="#404558"
              fontWeight={400}
              lineHeight="36px"
              textAlign="start"
              className="ping_fang"
              fontSize={16}
            >
              {item[1]}
            </SubTitle>
            <SubTitle
              color="#404558"
              fontWeight={400}
              lineHeight="36px"
              textAlign="start"
              className="ping_fang"
              fontSize={16}
            >
              {item[2]}
            </SubTitle>
            <SubTitle
              color="#404558"
              className="ping_fang"
              fontWeight={400}
              lineHeight="36px"
              textAlign="start"
              fontSize={16}
            >
              {item[3]}
            </SubTitle>
            <SubTitle
              color="#404558"
              fontWeight={400}
              lineHeight="36px"
              textAlign="start"
              className="ping_fang"
              fontSize={16}
            >
              {item[4]}
            </SubTitle>
          </Space>
        </div>
      );
    });
  };

  const renderSplit = () => {
    return (
      <div
        style={{
          width: 1200,
          height: "1px",
          opacity: 0.15,
          background: "#404558",
        }}
      ></div>
    );
  };

  return (
    <>
      <PageImage img={backgroundImg} slImg={backgroundImgSl}>
        <ImageWithContent
          img={contentImg}
          width={1200}
          height={680}
          imgWidth={1378}
          imgHeight={680}
          imgOffset={200}
          imgJustify="flex-end"
          containerAlignItems="center"
          contentDirection="column"
          animate="slide-left"
        >
          <Space
            direction="vertical"
            style={{ width: "470px" }}
            size={20}
            className="slide-right"
          >
            <Image src={subLogo} width={134} height={58} preview={false} />
            <Space
              direction="vertical"
              className="page_one_title"
              size={1}
              style={{ width: "470px" }}
            >
              <div>车辆画像摄像头4G版</div>
            </Space>
            <Space
              size={[30, 0]}
              wrap
              className="page_four_subTitle"
              style={{ width: "280px" }}
            >
              <div>车辆进场提醒</div>
              <div>客户精准画像</div>
              <div>智能统计分析</div>
            </Space>
          </Space>
        </ImageWithContent>
      </PageImage>

      <SubMenu items={items} title="车辆识别摄像头" id="subMenu" />
      <div style={{ height: 120, marginTop: -120 }} id="feature"></div>

      <Space
        size={100}
        direction="vertical"
        style={{
          width: "100vw",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginBottom: 140,
        }}
      >
        <ImageWithContent
          width={1200}
          height={610}
          img={cameraOne}
          className={rightOne ? "slide-in-bottom" : ""}
          paddingLeft={100}
          paddingRight={180}
          contentJustify="space-between"
          id="right_one"
        >
          <Space direction="vertical" size={30}>
            <Title nowrap>即时提醒，机会不容错过</Title>
            <Space direction="vertical" size={0}>
              <SubTitle>车辆进场车牌识别</SubTitle>
              <SubTitle>车辆进场即时提醒</SubTitle>
            </Space>
          </Space>
          <Image src={rightImg_one} width={420} height={483} preview={false} />
        </ImageWithContent>
        <ImageWithContent
          width={1200}
          height={610}
          img={cameraTwo}
          contentJustify="space-between"
          paddingLeft={188}
          paddingRight={120}
          id="right_two"
          className={rightTwo ? "slide-in-bottom" : ""}
        >
          <Image src={rightImg_two} width={298} height={463} preview={false} />
          <Space direction="vertical" size={70}>
            <Title>精准画像，快速洞悉客户</Title>
            <Space direction="vertical" size={58}>
              <Space direction="vertical" size={16}>
                <SubTitle lineHeight="0px" textAlign="start">
                  进场车辆精准画像
                </SubTitle>
                <Description height={20} lineHeight={0}>
                  车牌、品牌、车系、车龄
                </Description>
              </Space>
              <Space direction="vertical" size={16}>
                <SubTitle lineHeight="0px" textAlign="start">
                  客户精准画像
                </SubTitle>
                <Description height={20} lineHeight={0}>
                  是否会员、进场次数、订单数、商机数、消费频次
                </Description>
              </Space>
            </Space>
          </Space>
        </ImageWithContent>
        <ImageWithContent
          width="100vw"
          id="right_three"
          height={1020}
          contentHeight={1020}
          img={cameraThree}
          paddingBottom={194}
          containerAlignItems="flex-start"
          paddingTop={120}
          contentAlignItems="center"
          contentJustify="space-between"
          contentDirection="column"
          className={rightThree ? "slide-in-bottom" : ""}
        >
          <Title>车流量智能统计分析</Title>
          <Space
            className={rightThree ? "slide-in-bottom" : ""}
            wrap
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
            size={[0, 40]}
          >
            {statisticsItmes.map((item) => {
              return (
                <div className="statistics_container" key={Math.random()}>
                  <Space size={5} direction="vertical">
                    <SubTitle
                      fontWeight={700}
                      textAlign="start"
                      lineHeight="32px"
                    >
                      {item.title}
                    </SubTitle>
                    <Description textAlign="start" lineHeight="32px">
                      {item.desc}
                    </Description>
                  </Space>
                  <div style={{ alignSelf: "flex-end" }}>
                    <Image
                      src={item.img}
                      width={120}
                      height={120}
                      preview={false}
                    />
                  </div>
                </div>
              );
            })}
          </Space>
        </ImageWithContent>
      </Space>
      <div style={{ height: 120, marginTop: -120 }} id="params"></div>

      <Space
        size={100}
        direction="vertical"
        style={{
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "75px",
        }}
      >
        <Title>技术参数</Title>
        <Space
          style={{
            width: 1200,
            position: "relative",
            top: -70,
          }}
          direction="vertical"
          size={70}
          split={renderSplit()}
        >
          <div></div>
          {techologyParams.map((item) => {
            return (
              <div
                key={Math.random()}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <SubTitle
                  color="#343744"
                  fontWeight="bold"
                  fontSize={18}
                  textAlign="start"
                  width={120}
                  yuanTi
                >
                  {item.title}
                </SubTitle>
                {renderSize(item.content)}
              </div>
            );
          })}
          <div />
        </Space>
      </Space>

      <div style={{ height: 120, marginTop: -120 }} id="serve"></div>
      <Space
        size={70}
        direction="vertical"
        style={{
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "140px",
        }}
      >
        <Title>服务模式</Title>
        <Space
          style={{
            width: 1200,
          }}
          wrap
          size={20}
        >
          {serveMode.map((item) => {
            return (
              <div className="serve_mode_container" key={Math.random()}>
                <Space direction="vertical" size={25}>
                  <SubTitle
                    fontWeight={700}
                    fontSize="24px"
                    lineHeight="0px"
                    textAlign="start"
                  >
                    {item.title}
                  </SubTitle>
                  <Description textAlign="start">{item.desc}</Description>
                </Space>
                <Image
                  src={item.img}
                  width={200}
                  height={200}
                  preview={false}
                />
              </div>
            );
          })}
        </Space>
      </Space>
      <PageFooter />
    </>
  );
}

export default Camera;
