import "./style/pageStyle.css";
import React, { useEffect, useState } from "react";
import cameraOne from "../static/menu/hardware/camera_one@2x.png";
import cameraTwo from "../static/menu/hardware/camera_two@2x.png";
import cameraThree from "../static/menu/hardware/camera_three@2x.png";
import keyCabinetOne from "../static/menu/hardware/key_cabinet_one@2x.png";
import keyCabinetTwo from "../static/menu/hardware/key_cabinet_two@2x.png";
import keyCabinetThree from "../static/menu/hardware/key_cabinet_three@2x.png";
import electronicKanBan from "../static/menu/hardware/electronic_kanban@2x.png";
import BlurNow from "../static/menu/hardware/blur_now.png";

import printer from "../static/menu/hardware/printer@2x.png";
import { Image, Space } from "antd";
import { Link } from "react-router-dom";
import ImageWithContent from "./ImageWithContent";

const HARD_WARE_SUB_MENU = [
  {
    img: cameraOne,
    text: "车辆画像摄像头",
    subText: "4G版",
    href: "/hardWara/camera",
  },
  {
    img: cameraTwo,
    text: "工位直播摄像头",
    href: "/hardWara/living",
  },
  {
    img: printer,
    text: "云打印机",
    href: "",
  },
  {
    img: cameraThree,
    text: "门店实况摄像头",
    subText: "P20A2",
    href: "",
  },
  {
    img: keyCabinetOne,
    text: "智能养车钥匙柜",
    subText: "G18",
    href: "/hardWara/key",
  },
  {
    img: keyCabinetTwo,
    text: "智能养车钥匙柜",
    subText: "X18",
    href: "/hardWara/key",
  },
  {
    img: keyCabinetThree,
    text: "智能养车钥匙柜",
    subText: "X32",
    href: "/hardWara/key",
  },
  {
    img: electronicKanBan,
    text: "商机/任务看板",
    subText: "Y32",
    href: "",
  },
];

const HardwareSubMenu = (props) => {
  const { hideHardWareSubMenu } = props;
  const [showHover, setShowHover] = useState();
  const [hideBoxHeight, setHideBoxHeight] = useState();

  useEffect(() => {
    setHideBoxHeight(document.documentElement.clientHeight - 400);
  }, []);

  return (
    <>
      <div className="hard_ware_sub_menu_container fade-in-top">
        <div
          style={{
            width: "1240px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          {HARD_WARE_SUB_MENU.map((item, index) => {
            return (
              <Link
                to={item.href}
                key={item.img}
                onMouseOver={() => {
                  setShowHover(index);
                }}
                onMouseLeave={() => {
                  setShowHover();
                }}
                style={{ cursor: item.href ? "pointer" : "auto" }}
              >
                <ImageWithContent
                  img={showHover === index && item.href ? BlurNow : ""}
                  width={150}
                  imgWidth={showHover === index && item.href ? 210 : ""}
                  fwidth={190}
                  height={320}
                  containerAlignItems="center"
                >
                  <Space direction="vertical" size={30}>
                    <Image
                      src={item.img}
                      width={110}
                      height={150}
                      preview={false}
                    />
                    <Space
                      direction="vertical"
                      size={7}
                      style={{ height: "50px" }}
                    >
                      <div className="hard_ware_sub_menu_text">{item.text}</div>
                      <div className="hard_ware_sub_menu_subText">
                        {item.subText}
                      </div>
                    </Space>
                  </Space>
                </ImageWithContent>
              </Link>
            );
          })}
        </div>
      </div>
      <div
        onMouseOver={() => {
          hideHardWareSubMenu();
        }}
        style={{
          height: hideBoxHeight,
          width: "100vw",
          background: "rgba(0,0,0,0.50)",
          position: "fixed",
          top: 460,
          zIndex: 13,
        }}
      ></div>
    </>
  );
};

export default HardwareSubMenu;
