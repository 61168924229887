import "./style/pageStyle.css";
import React, { useState, useEffect } from "react";
import { Image, Space } from "antd";
import logoImage from "../static/menu/SEAYUN_logo@2x.png"
import HardwareSubMenu from "./HardwareSubMenu";
import SoftwareSubMenu from "./SoftwareSubMenu";
import { Link } from "react-router-dom";
const menuItems = [
  {
    title: "硬件产品",
    path: "",
    desc: "/hardWara",
  },
  {
    title: "软件服务",
    path: "",
    desc: "/softWara",
  },
  {
    title: "生态创新",
    path: "/ecology/ecology",
    desc: "/ecology/ecology",
  },
  {
    title: "数据产品",
    path: "/data",
    desc: "/data",
  },
  {
    title: "关于我们",
    path: "/about",
    desc: "/about",
  },
];
const hasSubMenuItems = ["硬件产品", "软件服务"];
const normalMenuBackground = "rgba(17,17,19,0.15)";
const hoverMenuBackground = "rgba(255,255,255,0.85)";

const Menu = () => {
  const renderMenuItem = () => {
    return menuItems.map((item) => {
      return (
        <Link
          to={item.path}
          className={"menu-item"}
          key={item.desc}
          style={{
            opacity:
              href?.indexOf(item.desc) > -1
                ? 1
                : onHoverMenu === item.title
                ? 1
                : 0.5,
            color:
              href?.indexOf(item.desc) > -1
                ? "#12c194"
                : onHoverMenu === item.title
                ? "#12c194"
                : menuBackground
                ? "#4D4F55"
                : isMenuFix
                ? "#4D4F55"
                : "#FFFFFF",
          }}
          onMouseOver={(menu) => {
            setOnHoverMenu(menu.target.textContent);
            if (hasSubMenuItems.includes(item.title)) {
              if (item.title === hasSubMenuItems[0]) {
                setShowHardWareSubMenu(true);
                setShowSoftWareSubMenu(false);
              }
              if (item.title === hasSubMenuItems[1]) {
                setShowSoftWareSubMenu(true);
                setShowHardWareSubMenu(false);
                // TODO
              }
              setIslogoFocus(true);
              setMenuBackground(true);
              return;
            }
            setShowHardWareSubMenu(false);
            setShowSoftWareSubMenu(false);
            setIslogoFocus(false);
            setMenuBackground(false);
          }}
          onMouseLeave={() => {
            setOnHoverMenu("");
          }}
        >
          {item.title}
        </Link>
      );
    });
  };

  useEffect(() => {
    var pathName = document.location.pathname;
    setHref(pathName);
    window.addEventListener("scroll", handleScroll, true);
    return window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = (e) => {
    if (e.target.scrollTop > 610) {
      setOnIsMenuFix(true);
    } else {
      setOnIsMenuFix(false);
    }
  };

  const [isLogoFocus, setIslogoFocus] = useState(false);
  const [menuBackground, setMenuBackground] = useState(false);
  const [showHardWareSubMenu, setShowHardWareSubMenu] = useState(false);
  const [showSoftWareSubMenu, setShowSoftWareSubMenu] = useState(false);
  const [isMenuFix, setOnIsMenuFix] = useState(false);

  const [onHoverMenu, setOnHoverMenu] = useState("");

  const hideHardWareSubMenu = () => {
    setIslogoFocus(false);
    setMenuBackground(false);
    setShowHardWareSubMenu(false);
    setOnHoverMenu("");
  };

  const hideSoftWareSubMenu = () => {
    setIslogoFocus(false);
    setMenuBackground(false);
    setShowSoftWareSubMenu(false);
    setOnHoverMenu("");
  };

  const [href, setHref] = useState();

  return (
    <>
      <div
        className="menu-container sub_menu_filter"
        style={{
          background: isMenuFix
            ? hoverMenuBackground
            : menuBackground
            ? "white"
            : normalMenuBackground,
          // background: menuBackground
          //   ? hoverMenuBackground
          //   : normalMenuBackground,
        }}
      >
        <div className="menu_container_item">
          <a
            href="/"
            onMouseOver={() => {
              setIslogoFocus(true);
            }}
            onMouseLeave={() => {
              !showSoftWareSubMenu &&
                !showHardWareSubMenu &&
                setIslogoFocus(false);
            }}
          >
            <Image
              src={logoImage}
              width={161}
              height={32}
              preview={false}
            />
          </a>
          <Space size={68}>{renderMenuItem()}</Space>
        </div>
      </div>
      <div className="split_line"></div>
      {showHardWareSubMenu ? (
        <HardwareSubMenu hideHardWareSubMenu={hideHardWareSubMenu} />
      ) : null}
      {showSoftWareSubMenu ? (
        <SoftwareSubMenu hideSoftWareSubMenu={hideSoftWareSubMenu} />
      ) : null}
    </>
  );
};

export default Menu;
