import "./App.css";
import { Carousel } from "antd";
import React, { useRef, useState } from "react";
import HomePageOne from "./component/HomePageOne";
import HomePageTwo from "./component/HomePageTwo";
import HomePageThree from "./component/HomePageThree";
import HomePageFour from "./component/HomePageFour";
import HomePageFive from "./component/HomePageFive";
import HomePageSix from "./component/HomePageSix";
import Layout from "./Layout";
import CountDown from "./component/CountDown";

function App() {
  const carouselRef = useRef();
  const countDownRef = useRef();
  const [two, setTwo] = useState(false);
  const [three, setThree] = useState(false);
  const [four, setFour] = useState(false);
  const [five, setFive] = useState(false);
  const [six, setSix] = useState(false);

  const next = () => {
    carouselRef.current.next();
  };

  const prev = () => {
    carouselRef.current.prev();
  };

  const goTo = (number) => {
    startAnimate(number);
    carouselRef.current.goTo(number);
  };

  const go = (index) => {
    countDownRef.current.Go(index);
  };

  const startAnimate = (index) => {
    switch (index) {
      case 1:
        setTwo(true);
        break;
      case 2:
        setThree(true);
        break;
      case 3:
        setFour(true);
        break;
      case 4:
        setFive(true);
        break;
      case 5:
        setSix(true);
        break;
      default:
        return;
    }
  };

  return (
    <Layout>
      <CountDown next={next} prev={prev} goTo={goTo} ref={countDownRef} />
      <Carousel
        className="container"
        ref={carouselRef}
        infinite
        slidesToShow={0}
        lazyLoad="progressive"
        effect="fade"
        dots={false}
      >
        <HomePageOne
          next={() => {
            go(1);
          }}
          prev={() => {
            go(5);
          }}
        />
        <HomePageThree
          animate={two}
          next={() => {
            go(2);
          }}
          prev={() => {
            go(0);
          }}
        />
        <HomePageFour
          animate={three}
          next={() => {
            go(3);
          }}
          prev={() => {
            go(1);
          }}
        />
        <HomePageSix
          animate={four}
          next={() => {
            go(4);
          }}
          prev={() => {
            go(2);
          }}
        />
        <HomePageTwo
          animate={five}
          next={() => {
            go(5);
          }}
          prev={() => {
            go(3);
          }}
        />
        <HomePageFive
          animate={six}
          next={() => {
            go(0);
          }}
          prev={() => {
            go(4);
          }}
        />
      </Carousel>
    </Layout>
  );
}

export default App;
