import React from "react";

const Title = ({
  children,
  color,
  height,
  textAlign,
  fontSize,
  nowrap,
  className,
  id,
}) => {
  return (
    <div
      id={id}
      className={`title_font_family ${className}`}
      style={{
        fontSize: fontSize || "46px",
        color: color || "#343744",
        fontWeight: "700",
        letterSpacing: 0,
        textAlign: textAlign || "center",
        height: height || "",
        whiteSpace: nowrap ? "nowrap" : "",
      }}
    >
      {children}
    </div>
  );
};

export default Title;
