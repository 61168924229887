import React from "react";

const PaddingBox = (props) => {
  const { top, bottom, children, pad, pt, pb, style } = props;
  return (
    <div
      style={{
        ...style,
        paddingLeft: pad || 15,
        paddingRight: pad || 15,
        marginTop: top,
        marginBottom: bottom,
        paddingTop: pt,
        paddingBottom: pb,
      }}
    >
      {children}
    </div>
  );
};

export default PaddingBox;
