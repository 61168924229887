import React, { useEffect, useState } from "react";
import Text from "./Text";
import "../style/animate.css";
import { Mask } from "antd-mobile";
import { Link } from "react-router-dom";

const items = [
  {
    title: "首页",
    href: "/",
  },
  {
    title: "硬件产品",
    href: "/hard_ware",
  },
  {
    title: "软件服务",
    href: "/soft_ware",
  },
  {
    title: "生态创新",
    href: "/ecology",
  },
  {
    title: "数据产品",
    href: "/data",
  },
  {
    title: "关于我们",
    href: "/about",
  },
];

const SlideMenu = (props) => {
  const { close } = props;

  useEffect(() => {
    setOnHover(localStorage.getItem("menu"));
  }, []);

  const [onHover, setOnHover] = useState();

  return (
    <Mask onMaskClick={() => close()} className="slide_menu_filter" opacity={0}>
      <div
        className="slide-in-top"
        style={{
          width: "100vw",
          height: "555px",
          paddingLeft: 45,
          paddingTop: 100,
          paddingRight: 45,
          top: 0,
          position: "fixed",
          background: "rgba(17,17,19,0.75)",
          zIndex: 3,
          borderRadius: "0px 0px 15px 15px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          {items.map((item) => {
            return (
              <Link
                to={item.href}
                key={Math.random()}
                style={{
                  height: 60,
                  display: "flex",
                  flexDirection: "column",
                }}
                onClick={(e) => {
                  localStorage.setItem("menu", e.target.textContent);
                }}
              >
                <Text
                  fontSize={16}
                  color="#FFFFFF"
                  opacity={onHover === item.title ? "1" : "0.5"}
                  fontFamil="pingFang"
                  fontWeighr={400}
                >
                  {item.title}
                </Text>
                <div
                  style={{
                    opacity: onHover === item.title ? "1" : "0.2",
                    height: 0.5,
                    background: "#FFFFFF",
                    marginTop: 15,
                  }}
                ></div>
              </Link>
            );
          })}
        </div>
      </div>
    </Mask>
  );
};

export default SlideMenu;
