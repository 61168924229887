import React from "react";
import Layout from "../../Layout";
import BG from "../../../static/mobile/pages/ecology/bg.png";
import ImageWithContent from "../ImageWithContent";
import { Image } from "antd-mobile";
import { Space } from "antd";
import Text from "../Text";
import LOGO from "../../../static/mobile/pages/ecology/logo.png";

import ONE_BG from "../../../static/mobile/pages/ecology/one.png";
import TWO_BG from "../../../static/mobile/pages/ecology/two.png";
import THREE_BG from "../../../static/mobile/pages/ecology/three.png";
import FOUR_BG from "../../../static/mobile/pages/ecology/four.png";
import FIVE_BG from "../../../static/mobile/pages/ecology/five.png";

import ONE_LOGO from "../../../static/mobile/pages/ecology/one_logo.png";
import TWO_LOGO from "../../../static/mobile/pages/ecology/two_logo.png";
import THREE_LOGO from "../../../static/mobile/pages/ecology/three_logo.png";
import FOUR_LOGO from "../../../static/mobile/pages/ecology/four_logo.png";
import FIVE_LOGO from "../../../static/mobile/pages/ecology/five_logo.png";

import PaddingBox from "../PaddingBox";
import Footer from "../Footer";
import { Link } from "react-router-dom";

const Ecology = () => {
  return (
    <Layout>
      <ImageWithContent
        img={BG}
        paddingTop={135}
        paddingLeft={40}
        paddingRight={20}
        contentJustify="flex-start"
        contentDirection="column"
      >
        <Space>
          <Text fontFamily="bold" fontSize={28} color="#FFFFFF">
            基于
          </Text>
          <Image src={LOGO} width={72} height={29} />
          <Text fontFamily="bold" fontSize={28} color="#FFFFFF">
            构建以车主
          </Text>
        </Space>
        <Text fontFamily="bold" fontSize={28} color="#FFFFFF">
          为中心的网络即时协同 C2S2b汽服生态系
        </Text>
      </ImageWithContent>
      <PaddingBox top={20}>
        <ImageWithContent
          paddingTop={45}
          paddingLeft={30}
          contentDirection="column"
          img={ONE_BG}
        >
          <Image src={ONE_LOGO} width={93} height={22} />
          <Text fontFamily="bold" fontSize={18} color="#343744" top={15}>
            养车智能顾问APP
          </Text>
          <Text fontFamily="normal" fontSize={11} color="#343744" top={11}>
            让用车养车明明白白
          </Text>
        </ImageWithContent>
      </PaddingBox>
      <PaddingBox top={20}>
        <Link to="/ecologyCreate">
          <ImageWithContent
            paddingTop={45}
            paddingLeft={30}
            contentDirection="column"
            img={TWO_BG}
          >
            <Image src={TWO_LOGO} width={101} height={22} />
            <Text fontFamily="bold" fontSize={18} color="#FFFFFF" top={11}>
              社区一站式便捷养车连锁
            </Text>
            <Text fontFamily="normal" fontSize={11} color="#FFFFFF" top={11}>
              重新定义养车门店
            </Text>
          </ImageWithContent>
        </Link>
      </PaddingBox>
      <PaddingBox top={20}>
        <ImageWithContent
          paddingTop={45}
          paddingLeft={30}
          contentDirection="column"
          img={THREE_BG}
        >
          <Image src={THREE_LOGO} width={80} height={22} />
          <Text
            fontFamily="bold"
            fontSize={18}
            color="#343744"
            top={15}
            style={{ width: 147 }}
          >
            办公极致洗护上门服务
          </Text>
          <Text fontFamily="normal" fontSize={11} color="#343744" top={11}>
            洗美社区店增收创收
          </Text>
        </ImageWithContent>
      </PaddingBox>
      <PaddingBox top={20}>
        <ImageWithContent
          paddingTop={45}
          paddingLeft={30}
          contentDirection="column"
          img={FOUR_BG}
        >
          <Image src={FOUR_LOGO} width={93} height={22} />
          <Text
            fontFamily="bold"
            fontSize={18}
            color="#343744"
            top={15}
            style={{ width: 147 }}
          >
            本地共享服务中央工厂
          </Text>
          <Text fontFamily="normal" fontSize={11} color="#343744" top={11}>
            改装、装潢、大修、钣金喷漆
          </Text>
        </ImageWithContent>
      </PaddingBox>
      <PaddingBox top={20}>
        <ImageWithContent
          paddingTop={45}
          paddingLeft={30}
          contentDirection="column"
          img={FIVE_BG}
        >
          <Image src={FIVE_LOGO} width={48.3} height={22} />
          <Text fontFamily="bold" fontSize={18} color="#343744" top={15}>
            配件先人一步
          </Text>
          <Text fontFamily="normal" fontSize={11} color="#343744" top={11}>
            易损件前置服务
          </Text>
        </ImageWithContent>
      </PaddingBox>

      <Footer style={{ marginTop: 65, marginBottom: 15 }} />
    </Layout>
  );
};

export default Ecology;
