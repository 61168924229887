/* eslint-disable react-hooks/exhaustive-deps */
import "./style/software.css";
import { Space, Image } from "antd";
import React, { useEffect, useState } from "react";
import PageImage from "../PageImage";
import backgroundImg from "../../static/slider/jpg/home_page_three@2x.jpg";
// import backgroundImg from "../../static/menu/ecology/ecology_background.png";

import subLogo from "../../static/slider/page_three_sub_logo@2x.png";
import menuLogo from "../../static/menu/software/pages/youchelai_os.png";

import contentImg from "../../static/slider/page_three_right_img@2x.png";

import SubMenu from "../SubMenu";
import youchelaiOne from "../../static/menu/software/pages/youchelai_one_background.png";
import youchelaiTwo from "../../static/menu/software/pages/youchelai_two_background.png";
import rightImg_one from "../../static/menu/software/pages/youchelai_one_right_img.png";
import rightImg_two from "../../static/menu/software/pages/youchelai_one_right_img_two.png";
import rightImg_three from "../../static/menu/software/pages/youchelai_two_right_img.png";

import ImageWithContent from "../ImageWithContent";
import Title from "../fontComponent/Title";
import SubTitle from "../fontComponent/SubTitle";
import descOne from "../../static/menu/software/pages/desc_one.png";
import descTwo from "../../static/menu/software/pages/desc_two.png";
import descThree from "../../static/menu/software/pages/desc_three.png";
import descFour from "../../static/menu/software/pages/desc_four.png";
import descFive from "../../static/menu/software/pages/desc_five.png";
import descSix from "../../static/menu/software/pages/desc_six.png";

import PageFooter from "../PageFooter";
import Description from "../fontComponent/Description";

import SubMenuLogo from "../../static/menu/software/pages/youchelai_logo.png";

const descItems = [
  {
    title: "操作简单",
    desc: ["只要点点点，还有些不用点", "免基础信息录入", "AI智能养车方案"],
    img: descOne,
  },
  {
    title: "软硬结合替代人工",
    desc: [
      "无需前台、收银、客服、财务、采购、仓管、营销",
      "店长职能减少，门槛降低",
    ],
    img: descTwo,
  },
  {
    title: "前所未有的用户体验",
    desc: ["养车更简单", "养车更透明", "服务更透明"],
    img: descThree,
  },
  {
    title: "AI驱动门店业绩良性增长",
    desc: ["AI养车顾问", "AI电话客服"],
    img: descFour,
  },
  {
    title: "横纵向打通产业链",
    desc: ["门店专属车主小程序", "异业流量互助", "配件智能报价"],
    img: descFive,
  },
  {
    title: "智能数据报表",
    desc: ["门店经营一目了然"],
    img: descSix,
  },
];

function YouCheLai() {
  const handleScroll = (e) => {
    const rightOneEle = document.getElementById("right_one");
    const rightTwoEle = document.getElementById("right_two");

    isInViewPort(rightOneEle) && setAnimateOne(true);
    isInViewPort(rightTwoEle) && setAnimateTwo(true);

    if (e.target.scrollTop > 610) {
      setOnIsSubMenuFix(true);
    } else {
      setOnIsSubMenuFix(false);
    }
  };

  const isInViewPort = (element) => {
    // const viewWidth = window.innerWidth || document.documentElement.clientWidth;
    const viewHeight =
      window.innerHeight || document.documentElement.clientHeight;
    const { top } = element.getBoundingClientRect();
    return top < viewHeight;
  };

  const [rightOne, setAnimateOne] = useState(false);
  const [rightTwo, setAnimateTwo] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
    return window.removeEventListener("scroll", handleScroll);
  }, []);

  const [isSubMenuFix, setOnIsSubMenuFix] = useState(false);

  return (
    <>
      <PageImage img={backgroundImg}>
        <ImageWithContent
          img={contentImg}
          width={1200}
          height={860}
          imgWidth={895}
          imgHeight={860}
          imgOffset={100}
          imgJustify="flex-end"
          containerAlignItems="center"
          contentDirection="column"
          animate="slide-left"
        >
          <Space direction="vertical" size={20} className="slide-right">
            <Image src={subLogo} width={168} height={55} preview={false} />
            <Space
              direction="vertical"
              className="page_one_title"
              size={1}
              style={{ whiteSpace: "nowrap" }}
            >
              <div>数智汽配操作系统</div>
            </Space>
            <Space size={[30, 0]} wrap style={{ whiteSpace: "nowrap" }}>
              <SubTitle fontSize={16} color="#F1F4F6" fontWeight={400}>
                洗美店、快修快保店、轮胎店、装潢店、综合修理厂
              </SubTitle>
            </Space>
          </Space>
        </ImageWithContent>
      </PageImage>
      <SubMenu
        title={
          <Image
            src={isSubMenuFix ? menuLogo : SubMenuLogo}
            width={78}
            height={22}
            preview={false}
          />
        }
        background="rgba(17,17,19,0.15)"
        id="subMenu"
        marginBottom="0px"
      />
      <Space
        size={100}
        direction="vertical"
        style={{
          position: "relative",
          top: -60,
          width: "100vw",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginBottom: 140,
        }}
      >
        <ImageWithContent
          id="right_one"
          width="100vw"
          height={810}
          img={youchelaiOne}
          paddingTop={150}
          paddingBottom={110}
          containerAlignItems="auto"
          contentDirection="column"
          contentAlignItems="center"
          contentJustify="space-between"
        >
          <Space
            direction="vertical"
            size={0}
            id="feature"
            className={rightOne ? "tracking-in-expand-fwd" : ""}
          >
            <Title color="white">汽服门店全场景OS</Title>
            <SubTitle color="#FFFFFF" yuanTi>
              你要的功能这里都有，你没想到的这里也有
            </SubTitle>
          </Space>
          <Image src={rightImg_one} width={1200} height={285} preview={false} />
          <Image src={rightImg_two} width={460} height={40} preview={false} />
        </ImageWithContent>
        <Space width={1200} size={40}>
          <ImageWithContent
            width={1200}
            height={680}
            img={youchelaiTwo}
            contentDirection="column"
            contentAlignItems="center"
            id="right_two"
            className={rightTwo ? "slide-in-bottom" : ""}
          >
            <Title>基于AutoCare AI引擎开发</Title>
            <Space direction="vertical" size={1} style={{ marginBottom: 70 }}>
              <SubTitle>实现汽服门店从半信息化时代到数智化时代的跨越</SubTitle>
              <SubTitle>Powered by AutoCare AI</SubTitle>
            </Space>
            <Image
              src={rightImg_three}
              width={900}
              height={339}
              preview={false}
            />
          </ImageWithContent>
        </Space>
        <Space
          wrap
          size={[0, 40]}
          style={{
            width: 1200,
            display: "flex",
            flexBasis: "row",
            justifyContent: "space-between",
          }}
        >
          {descItems.map((item) => {
            return (
              <div
                key={Math.random()}
                style={{
                  width: 370,
                  height: 440,
                  background: "#FFFFFF",
                  border: "4px solid rgba(244,248,251,1)",
                  borderRadius: 15,
                  display: "flex",
                  paddingTop: "60px",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Image
                  src={item.img}
                  width={160}
                  height={160}
                  preview={false}
                />
                <Space
                  direction="vertical"
                  size={20}
                  width={370}
                  style={{
                    marginTop: 38,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <SubTitle yuanTi fontSize={24}>
                    {item.title}
                  </SubTitle>
                  <Space direction="vertical" size={25}>
                    {item.desc.map((ele) => {
                      return (
                        <Description lineHeight="0px" key={Math.random()}>
                          {ele}
                        </Description>
                      );
                    })}
                  </Space>
                </Space>
              </div>
            );
          })}
        </Space>
      </Space>
      <PageFooter />
    </>
  );
}

export default YouCheLai;
