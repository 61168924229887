import React from "react";

const Description = ({
  children,
  lineHeight,
  textAlign,
  height,
  fontSize,
  color,
  opacity,
  position,
  top,
}) => {
  return (
    <div
      style={{
        position: position,
        top: top,
        height: height || 0,
        fontSize: fontSize || "14px",
        color: color || "#343744",
        lineHeight: lineHeight || "42px",
        fontWeight: "400",
        letterSpacing: 0,
        textAlign: textAlign || "center",
        opacity: opacity || 0.6,
      }}
    >
      {children}
    </div>
  );
};

export default Description;
