import React, { useState } from "react";
import { Image } from "antd";

import arrowLeft from "../../static/menu/about/about_left_d.png";
import hightLightArrowLeft from "../../static/menu/about/about_left_h.png";

const LeftArrow = React.memo(({ prev }) => {
  const [isLight, setImgHightLight] = useState(false);
  return (
    <div
      onClick={() => prev()}
      onMouseOver={() => {
        setImgHightLight(true);
      }}
      onMouseLeave={() => {
        setImgHightLight(false);
      }}
    >
      <Image
        src={isLight ? hightLightArrowLeft : arrowLeft}
        width={40}
        height={40}
        preview={false}
      />
    </div>
  );
});

export default LeftArrow;
