import React from "react";
import ImageWithContent from "./component/ImageWithContent";
import SLIDER_FIVE from "../static/mobile/slider/slider_five.png";
import LOGO from "../static/mobile/slider/slide_five/logo.png";
import Text from "./component/Text";
import { Image } from "antd-mobile";
import SliderGo from "./component/SliderGo";
import { Link } from "react-router-dom";

const SliderFive = (props) => {
  const { height, top, className } = props;
  return (
    <Link to="/data">
      <ImageWithContent
        className={className}
        top={top}
        img={SLIDER_FIVE}
        marginRight={8}
        height={height}
        paddingTop={60}
        paddingLeft={30}
        paddingRight={30}
        contentJustify="flex-start"
        contentDirection="column"
      >
        <Image src={LOGO} width={81} height={35} />
        <Text fontFamily="bold" fontSize={28} color="#FFFFFF" top={10}>
          汽服大数据及算法服务
        </Text>
        <Text fontFamily="bold" fontSize={14} color="#FFFFFF" top={15}>
          汽车后市场数智底座
        </Text>
      </ImageWithContent>
      <SliderGo href="/data" />
    </Link>
  );
};

export default SliderFive;
