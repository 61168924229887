import React from "react";
import ImageWithContent from "./component/ImageWithContent";
import SLIDER_SIX from "../static/mobile/slider/slider_six.png";
import LOGO from "../static/mobile/slider/slide_four/logo.png";
import Text from "./component/Text";
import { Image } from "antd-mobile";
import SliderGo from "./component/SliderGo";
import { Link } from "react-router-dom";

const SliderFive = (props) => {
  const { height, top, className } = props;
  return (
    <Link to="/living">
      <ImageWithContent
        className={className}
        top={top}
        img={SLIDER_SIX}
        height={height}
        marginRight={8}
        paddingTop={60}
        paddingLeft={30}
        paddingRight={30}
        contentJustify="flex-start"
        contentDirection="column"
      >
        <Image src={LOGO} width={81} height={35} />
        <Text fontFamily="bold" fontSize={28} color="#FFFFFF" top={10}>
          工位直播摄像头
        </Text>
        <Text fontFamily="bold" fontSize={14} color="#FFFFFF" top={15}>
          施工实况直播&nbsp;&nbsp;&nbsp;施工技师识别
        </Text>
        <Text fontFamily="bold" fontSize={14} color="#FFFFFF">
          车辆施工提醒&nbsp;&nbsp;&nbsp;智能统计分析
        </Text>
      </ImageWithContent>
      <SliderGo href="/living" />
    </Link>
  );
};

export default SliderFive;
