import "./style/pageStyle.css";
import "./style/homePage.css";
import { Space, Image } from "antd";
import SliderImage from "./SliderImage";
import React from "react";
import subLogo from "../static/slider/page_one_sub_logo@2x.png";
import contentImg from "../static/slider/page_one_right_img@2x.png";
// import SliderOne from "../static/slider/home_page_one@2x.png";
import SliderOne from "../static/slider/jpg/home_page_one@2x.jpg";
import ImageWithContent from "./ImageWithContent";

import sliderPreview from "../static/slider/home_page_one_preview.jpg";
import LearnMore from "./LearnMore";

const HomePageOne = (props) => {
  const { next, prev } = props;

  return (
    <SliderImage
      img={SliderOne}
      placeholderImg={sliderPreview}
      next={next}
      prev={prev}
    >
      <ImageWithContent
        width={1200}
        height="100vh"
        img={contentImg}
        imgWidth={801}
        imgHeight={860}
        imgJustify="flex-end"
        containerAlignItems="center"
        contentDirection="column"
        imgOffset={100}
        animate="slide-left-d"
      >
        <Space direction="vertical" size={25}>
          <Space
            direction="vertical"
            className="page_one_title slide-left-a"
            size={1}
            style={{ whiteSpace: "nowrap" }}
          >
            <div>汽服生态系数智化</div>
            <div>全栈解决方案提供商</div>
          </Space>
          <div className="page_one_subTitle slide-left-c">
            汽车后市场C2S2b产业互联网
          </div>
          <Image
            src={subLogo}
            width={217}
            height={40}
            preview={false}
            className="slide-left-d"
          />
          <LearnMore href={"/about"} top="75px" className="slide-left-e" />
        </Space>
      </ImageWithContent>
    </SliderImage>
  );
};

export default HomePageOne;
