import React, { useEffect, useState } from "react";
import Layout from "../../Layout";
import BG from "../../../static/mobile/pages/youchelai/bg.png";
import ImageWithContent from "../ImageWithContent";
import { Image } from "antd-mobile";
import Text from "../Text";
import LOGO from "../../../static/mobile/slider/slide_three/logo.png";
import OSBG from "../../../static/mobile/pages/youchelai/os_bg.png";
import OS_ICON from "../../../static/mobile/pages/youchelai/os_icon.png";
import OS_ONE from "../../../static/mobile/pages/youchelai/os_bg_one.png";

import DESC_ONE from "../../../static/mobile/pages/youchelai/os_one.png";
import DESC_TWO from "../../../static/mobile/pages/youchelai/os_two.png";
import DESC_THREE from "../../../static/mobile/pages/youchelai/os_three.png";
import DESC_FOUR from "../../../static/mobile/pages/youchelai/os_four.png";
import DESC_FIVE from "../../../static/mobile/pages/youchelai/os_five.png";
import DESC_SIX from "../../../static/mobile/pages/youchelai/os_six.png";

import PaddingBox from "../PaddingBox";
import Footer from "../Footer";

const descItems = [
  {
    title: "操作简单",
    desc: ["只要点点点，还有些不用点", "免基础信息录入", "AI智能养车方案"],
    img: DESC_ONE,
  },
  {
    title: "软硬结合替代人工",
    desc: ["无需前台、收银、客服、财务等", "店长职能减少，门槛降低"],
    img: DESC_TWO,
  },
  {
    title: "前所未有的用户体验",
    desc: ["养车更简单", "养车更透明", "服务更透明"],
    img: DESC_THREE,
  },
  {
    title: "AI驱动门店业绩良性增长",
    desc: ["AI养车顾问", "AI电话客服"],
    img: DESC_FOUR,
  },
  {
    title: "横纵向打通产业链",
    desc: ["门店专属车主小程序", "异业流量互助", "配件智能报价"],
    img: DESC_FIVE,
  },
  {
    title: "智能数据报表",
    desc: ["门店经营一目了然"],
    img: DESC_SIX,
  },
];

const YouCheLai = () => {
  const [osWidth, setOSWidth] = useState();

  useEffect(() => {
    setOSWidth((document.documentElement.clientWidth - 40) / 2);
  }, []);

  return (
    <Layout>
      <ImageWithContent
        img={BG}
        paddingTop={135}
        paddingLeft={40}
        paddingRight={45}
        contentJustify="flex-start"
        contentDirection="column"
      >
        <Image src={LOGO} width={107} height={35} />
        <Text fontFamily="bold" fontSize={28} color="#FFFFFF" top={5}>
          数智汽服操作系统
        </Text>
        <Text fontFamily="bold" fontSize={14} color="#FFFFFF" top={10}>
          洗美店、快修快保店、轮胎店
        </Text>
        <Text fontFamily="bold" fontSize={14} color="#FFFFFF" top={0}>
          装潢店、综合修理厂
        </Text>
      </ImageWithContent>
      <ImageWithContent
        img={OSBG}
        paddingTop={55}
        contentDirection="column"
        contentAlignItems="center"
      >
        <Text fontSize={28} color="#FFFFFF" fontFamily="bold">
          汽服门店全场景OS
        </Text>
        <Text
          top={10}
          fontSize={12}
          color="#FFFFFF"
          fontFamily="pingFang"
          opacity={0.5}
        >
          你要的功能这里都有，你没想到的这里也有
        </Text>
        <PaddingBox pad={30} top={40}>
          <Image src={OS_ICON} />
        </PaddingBox>
      </ImageWithContent>
      <PaddingBox top={20}>
        <ImageWithContent
          img={OS_ONE}
          paddingTop={35}
          contentDirection="column"
          contentAlignItems="center"
        >
          <Text fontFamily="bold" fontSize={20} color="#343744" nowrap>
            基于AutoCare AI引擎开发
          </Text>
          <Text
            fontFamily="normal"
            fontSize={10}
            color="#343744"
            nowrap
            top={10}
          >
            实现汽服门店从半信息化时代到数智化时代的跨越
          </Text>
          <Text fontFamily="normal" fontSize={10} color="#343744" nowrap>
            Powered by AutoCare AI
          </Text>
        </ImageWithContent>
      </PaddingBox>
      <PaddingBox top={20}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          {descItems.map((item) => {
            return (
              <div
                key={Math.random()}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingTop: 30,
                  marginBottom: 9,
                  paddingBottom: 9,
                  width: osWidth,
                  border: "2px solid rgba(244,248,251,1)",
                  borderRadius: 10,
                }}
              >
                <Image src={item.img} width={78} height={78} />
                <Text
                  fontFamily="bold"
                  fontSize={14}
                  color="#343744"
                  top={8}
                  bottom={8}
                >
                  {item.title}
                </Text>
                <div style={{ paddingLeft: 14, paddingRight: 14 }}>
                  {item.desc.map((el) => {
                    return (
                      <Text
                        key={Math.random()}
                        fontFamily="pingFang"
                        fontSize={10}
                        color="#343744"
                        opacity={0.5}
                        style={{ textAlign: "center" }}
                        nowrap
                      >
                        {el}
                      </Text>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </PaddingBox>
      <Footer style={{ marginTop: 63, marginBottom: 15 }}></Footer>
    </Layout>
  );
};

export default YouCheLai;
