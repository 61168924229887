import "./style/pageStyle.css";
import React from "react";
import SliderImage from "./SliderImage";
import { Space, Image } from "antd";
// import SliderThree from "../static/slider/home_page_three@2x.png";
import SliderThree from "../static/slider/jpg/home_page_three@2x.jpg";
import ImageWithContent from "./ImageWithContent";

import subLogo from "../static/slider/page_three_sub_logo@2x.png";
import contentImg from "../static/slider/page_three_right_img@2x.png";
import sliderPreview from "../static/slider/home_page_three_preview.jpg";
import LearnMore from "./LearnMore";

const HomePageThree = (props) => {
  const { next, prev, animate } = props;
  return (
    <SliderImage
      img={SliderThree}
      placeholderImg={sliderPreview}
      next={next}
      prev={prev}
    >
      <ImageWithContent
        width={1200}
        height="100vh"
        img={contentImg}
        imgWidth={895}
        imgHeight={860}
        imgJustify="flex-end"
        containerAlignItems="center"
        contentDirection="column"
        imgOffset={100}
        animate={animate ? "slide-left-d" : ""}
      >
        <Space direction="vertical" size={15}>
          <Image
            src={subLogo}
            width={168}
            height={55}
            preview={false}
            className={animate ? "slide-left-a" : ""}
          />
          <Space
            direction="vertical"
            className={`page_one_title ${animate ? "slide-left-c" : ""}`}
            size={1}
            style={{ whiteSpace: "nowrap" }}
          >
            <div>数智汽服操作系统</div>
          </Space>
          <div
            className={`page_one_subTitle ${animate ? "slide-left-d" : ""}`}
            style={{ width: "398px" }}
          >
            洗美店、快修快保店、轮胎店、装潢店、综合修理厂
          </div>
          <LearnMore
            href={"/softWara/youchelai"}
            top="75px"
            className={animate ? "slide-left-e" : ""}
          />
        </Space>
      </ImageWithContent>
    </SliderImage>
  );
};

export default HomePageThree;
