import { Image } from "antd";
import React from "react";

const ImageWithContent = (props) => {
  const {
    img,
    id,
    imgWidth,
    children,
    marginRight,
    height,
    contentJustify,
    contentAlignItems,
    contentDirection,
    paddingTop,
    paddingLeft,
    paddingRight,
    paddingBottom,
    className,
    contentHeight,
    top,
  } = props;
  return (
    <div
      id={id}
      className={className}
      style={{
        position: "relative",
        height: height,
        top: top,
        marginRight: marginRight,
      }}
    >
      {img && (
        <Image
          src={img}
          width={imgWidth || "100%"}
          style={{
            height: height,
            position: "relative",
          }}
          preview={false}
        />
      )}
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: height,
          top: 0,
          bottom: 0,
        }}
      >
        <div
          style={{
            display: "flex",
            height: contentHeight,
            paddingLeft: paddingLeft || 0,
            paddingRight: paddingRight || 0,
            paddingTop: paddingTop || 0,
            paddingBottom: paddingBottom || 0,
            flexDirection: contentDirection || "row",
            justifyContent: contentJustify || "space-around",
            alignItems: contentAlignItems || "flex-start",
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default ImageWithContent;
