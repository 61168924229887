import React, { useState } from "react";
import { Image } from "antd";

import arrowLeft from "../../static/slider/default_arrow_left.png";
import hightLightArrowLeft from "../../static/slider/hightlight_arrow_left.png";

const LeftNav = React.memo(({ disabled, onClick }) => {
  const [isLight, setImgHightLight] = useState(false);
  return (
    <div
      style={{
        paddingLeft: "40px",
        top: "50%",
        left: 0,
        position: "absolute",
        zIndex: 4,
      }}
      onClick={() => onClick()}
      disabled={disabled}
      onMouseOver={() => {
        setImgHightLight(true);
      }}
      onMouseLeave={() => {
        setImgHightLight(false);
      }}
    >
      <Image
        src={isLight ? hightLightArrowLeft : arrowLeft}
        width={46}
        height={46}
        preview={false}
      />
    </div>
  );
});

export default LeftNav;
