/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import { Form, Input, message, Select } from "antd";
import SubTitle from "../fontComponent/SubTitle";
import { useForm } from "antd/lib/form/Form";
import { extend } from "umi-request";
import { LoadingOutlined } from "@ant-design/icons";

const { Option } = Select;

const AboutForm = () => {
  const request = extend({
    prefix:
      process.env.NODE_ENV === "development"
        ? "http://192.168.10.43:8001/"
        : "",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });

  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    const { service_name, count, name, phone } = values;
    if (!service_name) {
      message.error({
        content: '"请填入您感兴趣的产品/服务"',
      });
      setLoading(false);
      return;
    }
    if (!count) {
      message.error({
        content: '"请填入需求数量"',
      });
      setLoading(false);
      return;
    }
    if (!name) {
      message.error({
        content: '"请填入您的称呼"',
      });
      setLoading(false);
      return;
    }
    if (!phone) {
      message.error({
        content: '"请填入您的联系方式"',
      });
      setLoading(false);
      return;
    }
    request
      .post("api/autocareai/message", {
        data: formateObjToParamStr(values),
      })
      .then((res) => {
        if (res && res.code === 0) {
          message.success("提交成功!");
          setLoading(false);
          form.resetFields();
        }
      });
  };

  function filter(str) {
    return encodeURIComponent(str);
  }

  function formateObjToParamStr(paramObj) {
    const sdata = [];
    for (let attr in paramObj) {
      sdata.push(`${attr}=${filter(paramObj[attr] || "")}`);
    }
    return sdata.join("&");
  }

  const [form] = useForm();

  return (
    <Form form={form} onFinish={onFinish} style={{ marginTop: 60 }}>
      <Form.Item name="service_name">
        <Input placeholder="您感兴趣的产品/服务是什么 *" />
      </Form.Item>

      <Form.Item name="count">
        <Select placeholder="需求数量 *" allowClear>
          <Option value="10以内">10以内</Option>
          <Option value="10-100">10-100</Option>
          <Option value="100以上">100以上</Option>
        </Select>
      </Form.Item>

      <Form.Item name="name">
        <Input placeholder="怎么称呼您 *" />
      </Form.Item>

      <Form.Item name="phone">
        <Input placeholder="联系电话/手机 *" />
      </Form.Item>

      <Form.Item name="email">
        <Input placeholder="邮箱" />
      </Form.Item>

      <Form.Item name="position">
        <Select placeholder="您的角色是" allowClear>
          <Option value="参与收集决策信息">参与收集决策信息</Option>
          <Option value="参与决策过程">参与决策过程</Option>
          <Option value="最终决策者">最终决策者</Option>
        </Select>
      </Form.Item>

      <Form.Item name="company">
        <Input placeholder="企业/公司名称" />
      </Form.Item>

      <Form.Item name="scale">
        <Select placeholder="企业规模" allowClear>
          <Option value="10人以内">10人以内</Option>
          <Option value="10-50人">10-50人</Option>
          <Option value="50-100人">50-100人</Option>
          <Option value="100-300人">100-300人</Option>
          <Option value="300人以上">300人以上</Option>
        </Select>
      </Form.Item>

      <Form.Item name="industry">
        <Select placeholder="所属行业" allowClear>
          <Option value="交通出行">交通出行</Option>
          <Option value="生活服务">生活服务</Option>
          <Option value="金融及理财">金融及理财</Option>
          <Option value="供应链">供应链</Option>
          <Option value="政府或社会机构">政府或社会机构</Option>
          <Option value="零售">零售</Option>
          <Option value="其他">其他</Option>
        </Select>
      </Form.Item>

      <div
        onClick={() => {
          form.submit();
          setLoading(true);
        }}
        style={{
          width: 550,
          height: 50,
          background: "#12C194",
          borderRadius: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 30,
        }}
      >
        <SubTitle color="white">
          {loading ? <LoadingOutlined /> : "提交"}
        </SubTitle>
      </div>
    </Form>
  );
};

export default AboutForm;
