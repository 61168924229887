import React, { useEffect, useState } from "react";
import { Image, Space } from "antd";
import Text from "../Text";
import SplitLine from "./SplitLine";

import keyParamsOne from "../../../static/mobile/pages/key/p_one.png";
import keyParamsTwo from "../../../static/mobile/pages/key/p_two.png";
import keyParamsThree from "../../../static/mobile/pages/key/p_three.png";
import PaddingBox from "../PaddingBox";

const techologyParams = [
  {
    type: "fontText",
    content: ["壁挂钥匙柜G18", "立式钥匙柜X18", "立式钥匙柜X32"],
  },
  {
    title: "尺寸",
    type: "img",
    content: [keyParamsOne, keyParamsTwo, keyParamsThree],
  },
  {
    title: "显示屏",
    type: "size",
    content: [
      ["宽度 550mm", "高度 850mm", "厚度 170mm"],
      ["宽度 550mm", "高度 1428mm", "厚度 245mm"],
      ["宽度 501mm", "高度 1714mm", "厚度 228.5mm"],
    ],
  },
  {
    title: "系统与网络",
    type: "size",
    content: [
      ["屏幕 18.5英寸", "分辨率 -"],
      ["屏幕 18.5英寸", "分辨率 1920*1080"],
      ["32英寸", "分辨率 1920*1080"],
    ],
  },
  {
    title: "格口",
    type: "size",
    content: [
      ["系统 -", "网络 4G/WIFI"],
      ["系统 Android 5.1.1", "网络 4G/WIFI"],
      ["系统 Android 5.1.1", "网络 4G/WIFI"],
    ],
  },
  {
    content: ["20个", "20个", "20个"],
  },
];

const KeyParams = () => {
  const [osWidth, setOSWidth] = useState();

  useEffect(() => {
    setOSWidth((document.documentElement.clientWidth - 64) / 3);
  }, []);

  const renderImg = (items) => {
    return (
      <>
        <PaddingBox>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {items.map((item) => {
              return (
                <Image
                  key={Math.random()}
                  src={item}
                  height={152}
                  preview={false}
                />
              );
            })}
          </div>
        </PaddingBox>
        <SplitLine top={30} bottom={30} name="尺寸" />
      </>
    );
  };

  const renderName = (items) => {
    return (
      <>
        <PaddingBox key={Math.random()} pad={15}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {items.map((item) => {
              return (
                <Text
                  fontSize={12}
                  color="#343744"
                  fontFamily="bold"
                  style={{ width: osWidth, textAlign: "center" }}
                >
                  {item}
                </Text>
              );
            })}
          </div>
        </PaddingBox>
        <SplitLine top={25} bottom={30} />
      </>
    );
  };

  const renderSize = (items) => {
    return (
      <>
        <PaddingBox pad={15}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {items.content.map((item) => {
              return (
                <div
                  key={Math.random()}
                  style={{
                    width: osWidth,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Space direction="vertical" size={0}>
                    <Text color="#404558" fontSize={10} fontFamily="pingFang">
                      {item[0]}
                    </Text>
                    <Text color="#404558" fontSize={10} fontFamily="pingFang">
                      {item[1]}
                    </Text>
                    <Text color="#404558" fontSize={10} fontFamily="pingFang">
                      {item[2]}
                    </Text>
                  </Space>
                </div>
              );
            })}
          </div>
        </PaddingBox>
        <SplitLine name={items.title} top={40} bottom={40} />
      </>
    );
  };

  const switchItem = (ele) => {
    switch (ele.type) {
      case "fontText":
        return renderName(ele.content);
      case "img":
        return renderImg(ele.content);
      case "size":
        return renderSize(ele);
      default: {
        return (
          <>
            <PaddingBox pad={59}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                {ele.content.map((item) => {
                  return (
                    <div key={Math.random()}>
                      <Text color="#404558" fontSize={10} fontFamily="pingFang">
                        {item}
                      </Text>
                    </div>
                  );
                })}
              </div>
            </PaddingBox>
            <SplitLine bottom={40} top={40} />
          </>
        );
      }
    }
  };

  return techologyParams.map((item) => {
    return <div key={Math.random()}>{switchItem(item)}</div>;
  });
};

export default KeyParams;
