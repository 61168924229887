import React, { useState } from "react";
import { Image } from "antd";
import arrowRight from "../../static/slider/default_arrow_right.png";
import hightLightArrowRight from "../../static/slider/hightlight_arrow_right.png";

const RightNav = React.memo(({ disabled, onClick }) => {
  const [isLight, setImgHightLight] = useState(false);
  return (
    <div
      style={{
        paddingRight: "40px",
        top: "50%",
        right: 0,
        position: "absolute",
        zIndex: 4,
      }}
      onClick={() => onClick()}
      disabled={disabled}
      onMouseOver={() => {
        setImgHightLight(true);
      }}
      onMouseLeave={() => {
        setImgHightLight(false);
      }}
    >
      <Image
        src={isLight ? hightLightArrowRight : arrowRight}
        width={46}
        height={46}
        preview={false}
      />
    </div>
  );
});

RightNav.displayName = "RightNav";

export default RightNav;
