import "./style/pageStyle.css";
import React from "react";
import SliderImage from "./SliderImage";
import { Space, Image } from "antd";
import SliderFour from "../static/slider/jpg/home_page_four@2x.jpg";
import LearnMore from "./LearnMore";
import ImageWithContent from "./ImageWithContent";

import subLogo from "../static/slider/page_four_sub_logo@2x.png";
import contentImg from "../static/slider/page_four_right_img@2x.png";
import sliderPreview from "../static/slider/home_page_four_preview.jpg";
import SubTitle from "./fontComponent/SubTitle";

const HomePageFour = (props) => {
  const { next, prev, animate } = props;
  return (
    <SliderImage
      img={SliderFour}
      placeholderImg={sliderPreview}
      next={next}
      prev={prev}
    >
      <ImageWithContent
        width={1200}
        height="100vh"
        img={contentImg}
        imgWidth={842}
        imgHeight={860}
        imgJustify="flex-end"
        containerAlignItems="center"
        contentDirection="column"
        imgOffset={100}
        animate={animate ? "slide-left-d" : ""}
      >
        <Space direction="vertical" size={20}>
          <Image
            src={subLogo}
            width={134}
            height={58}
            preview={false}
            className={animate ? "slide-left-a" : ""}
          />
          <Space
            direction="vertical"
            className={`page_one_title ${animate ? "slide-left-c" : ""}`}
            size={1}
            style={{ whiteSpace: "nowrap" }}
          >
            <div>智能养车钥匙柜</div>
          </Space>
          <SubTitle color="#FFFFFF" className={animate ? "slide-left-d" : ""}>
            取代收银前台，门店无人值守接还车
          </SubTitle>
          <LearnMore
            href={"/hardWara/key"}
            top="75px"
            className={animate ? "slide-left-e" : ""}
          />
        </Space>
      </ImageWithContent>
    </SliderImage>
  );
};

export default HomePageFour;
