import React, { useEffect, useState } from "react";
import Layout from "../../Layout";
import BG from "../../../static/mobile/pages/living/bg.png";
import ImageWithContent from "../ImageWithContent";
import { Image } from "antd-mobile";
import { Space } from "antd";
import Text from "../Text";
import CameraParams from "./CameraParams";
import LOGO from "../../../static/mobile/pages/living/logo.png";

import ONE from "../../../static/mobile/pages/living/one.png";
import TWO from "../../../static/mobile/pages/living/two.png";
import C_BG from "../../../static/mobile/pages/living/c_bg.png";

import C_ONE from "../../../static/mobile/pages/living/c_one.png";
import C_TWO from "../../../static/mobile/pages/living/c_two.png";
import C_THREE from "../../../static/mobile/pages/living/c_three.png";
import C_FOUR from "../../../static/mobile/pages/living/c_four.png";
import C_FIVE from "../../../static/mobile/pages/living/c_five.png";
import C_SIX from "../../../static/mobile/pages/living/c_six.png";

import S_ONE from "../../../static/mobile/pages/living/serve_one.png";
import S_TWO from "../../../static/mobile/pages/living/serve_two.png";

import PaddingBox from "../PaddingBox";
import Footer from "../Footer";

const serve = [
  {
    img: S_ONE,
    title: "硬件+标准应用",
    subTitle: "适用于汽服门店",
  },
  {
    img: S_TWO,
    title: "硬件+API接口",
    subTitle: "适用于具有开发能力的客户",
  },
];

const statisticsItmes = [
  {
    title: "车辆进场时段",
    desc: "分布统计",
    img: C_ONE,
  },
  {
    title: "进场车辆数",
    desc: "统计分析",
    img: C_TWO,
  },
  {
    title: "车辆品牌/车系/车龄",
    desc: "统计分析",
    img: C_THREE,
  },
  {
    title: "进场车辆新老客户",
    desc: "统计分析",
    img: C_FOUR,
  },
  {
    title: "进场车辆办卡客户",
    desc: "统计分析",
    img: C_FIVE,
  },
  {
    title: "进店转化漏斗分析",
    desc: "进场量、订单量、施工量",
    img: C_SIX,
  },
];

const Camera = () => {
  const [osWidth, setOSWidth] = useState();

  const [bgWidth, setBgWidth] = useState();

  useEffect(() => {
    setOSWidth(document.documentElement.clientWidth - 30);
    setBgWidth((document.documentElement.clientWidth - 40) / 2);
  }, []);

  return (
    <Layout>
      <ImageWithContent
        img={BG}
        paddingTop={135}
        paddingLeft={40}
        paddingRight={45}
        contentJustify="flex-start"
        contentDirection="column"
      >
        <Image src={LOGO} width={81} height={35} />
        <Text fontFamily="bold" fontSize={28} color="#FFFFFF" top={3}>
          车辆画像摄像头4G版
        </Text>
        <Text fontFamily="bold" fontSize={14} color="#FFFFFF" top={5}>
          车辆进场提醒 客户精准画像
        </Text>
        <Text fontFamily="bold" fontSize={14} color="#FFFFFF" top={0}>
          智能统计分析
        </Text>
      </ImageWithContent>

      <PaddingBox top={20}>
        <ImageWithContent
          img={ONE}
          paddingTop={45}
          paddingLeft={33}
          contentDirection="column"
          contentJustify="flex-start"
        >
          <Text
            fontFamily="bold"
            fontSize={18}
            color="#343744"
            style={{ width: 147 }}
          >
            即时提醒，机会不容错过
          </Text>
          <Text
            fontFamily="normal"
            fontSize={12}
            top={10}
            color="#343744"
            opacity={0.65}
          >
            车辆进场车牌识别
          </Text>
          <Text
            fontFamily="normal"
            fontSize={12}
            color="#343744"
            opacity={0.65}
          >
            车辆进场即时提醒
          </Text>
        </ImageWithContent>
      </PaddingBox>

      <PaddingBox top={20}>
        <ImageWithContent
          img={TWO}
          paddingTop={45}
          paddingRight={41}
          contentDirection="column"
          contentAlignItems="flex-end"
        >
          <Text
            fontFamily="bold"
            fontSize={18}
            color="#343744"
            style={{ width: 147, textAlign: "start" }}
          >
            精准画像，快速洞悉客户
          </Text>
          <Text
            fontFamily="normal"
            fontSize={12}
            top={10}
            color="#343744"
            opacity={0.65}
            style={{ width: 147, textAlign: "start" }}
          >
            进场车辆精准画像
          </Text>
          <Text
            fontFamily="normal"
            fontSize={12}
            color="#343744"
            opacity={0.65}
            style={{ width: 147, textAlign: "start" }}
          >
            客户精准画像
          </Text>
        </ImageWithContent>
      </PaddingBox>

      <ImageWithContent
        img={C_BG}
        paddingTop={50}
        top={20}
        contentDirection="column"
        contentAlignItems="center"
      >
        <Text fontSize={20} fontFamily="bold" color="#343744">
          车流量智能统计分析
        </Text>
        <PaddingBox top={28}>
          <Space
            direction="vertical"
            size={10}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            {statisticsItmes.map((item) => {
              return (
                <div
                  style={{
                    width: bgWidth,
                    paddingTop: 20,
                    paddingBottom: 10,
                    paddingRight: 15,
                    paddingLeft: 15,
                    background: "#FFFFFF",
                    borderRadius: 5,
                    position: "relative",
                  }}
                >
                  <Text fontSize={14} fontFamily="bold" color="#343744">
                    {item.title}
                  </Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text
                      fontSize={10}
                      fontFamily="pingFang"
                      color="#343744"
                      opacity={0.5}
                    >
                      {item.desc}
                    </Text>
                    <Image src={item.img} width={50} height={50} />
                  </div>
                </div>
              );
            })}
          </Space>
        </PaddingBox>
      </ImageWithContent>

      <Text
        color="#343744"
        fontFamily="bold"
        fontSize={20}
        top={60}
        style={{ textAlign: "center" }}
      >
        技术参数
      </Text>
      <CameraParams />
      <Text
        color="#343744"
        fontFamily="bold"
        fontSize={20}
        top={50}
        style={{ textAlign: "center" }}
      >
        服务模式
      </Text>
      <PaddingBox top={30}>
        <Space size={20} direction="vertical">
          {serve.map((item) => {
            return (
              <div
                style={{
                  background: "#FFFFFF",
                  width: osWidth,
                  border: "3px solid rgba(244,248,251,1)",
                  borderRadius: 10,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingTop: 20,
                  paddingBottom: 20,
                  paddingLeft: 35,
                  paddingRight: 25,
                }}
              >
                <Space direction="vertical" size={0}>
                  <Text
                    fontFamily="bold"
                    fontSize={16}
                    color="#343744"
                    top={36}
                  >
                    {item.title}
                  </Text>
                  <Text
                    fontFamily="pingFang"
                    fontSize={12}
                    color="#343744"
                    top={8}
                  >
                    {item.subTitle}
                  </Text>
                </Space>
                <Image src={item.img} width={120} height={120} />
              </div>
            );
          })}
        </Space>
      </PaddingBox>

      <Footer style={{ marginTop: 63, marginBottom: 15 }}></Footer>
    </Layout>
  );
};

export default Camera;
