import React, { useEffect, useState } from "react";

import "./path.css";
const Path = (props) => {
  const [icpNo, setIcpNo] = useState("");
  useEffect(() => {
    judgePath();
  }, []);

  const judgePath = () => {
    const {hostname} = window.location;
    let icpMap={
        "www.sydip.com":"蜀ICP备19012382号-1",
        "www.youchelai.cn":"蜀ICP备19012382号-2",
        "www.xiaochebai.com":"蜀ICP备19012382号-3",
        "www.autocareai.com":"蜀ICP备19012382号-4",
        "www.autocareai.cn":"蜀ICP备19012382号-5",
        "www.xiaochebai.cn":"蜀ICP备19012382号-7",
    }
    if(icpMap[hostname]){
        setIcpNo(icpMap[hostname])
    }else {
        setIcpNo("蜀ICP备19012382号")
    }
  };

  return (
    <>
      <a href="https://beian.miit.gov.cn/#/Integrated/index"
        className="path_style">
        {icpNo}
      </a>
      | © 2022 AUTOCARE AI
    </>
  );
};

export default Path;
