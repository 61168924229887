import React, { useEffect } from "react";
import Layout from "../../Layout";
import BG from "../../../static/mobile/pages/about/bg.png";
import ImageWithContent from "../ImageWithContent";
import { Image, Space, Swiper } from "antd-mobile";
import Text from "../Text";
import LOGO from "../../../static/mobile/slider/slide_one/logo.png";
import ABOUT_ONE from "../../../static/mobile/pages/about/about_one.png";
import ABOUT_TWO from "../../../static/mobile/pages/about/about_two.png";
import ABOUT_THREE from "../../../static/mobile/pages/about/about_three.png";
import ABOUT_US from "../../../static/mobile/pages/about/about_us.png";
import ABOUT_US_TWO from "../../../static/mobile/pages/about/about_us_two.png";

import ASK from "../../../static/mobile/pages/about/ask_us.png";
import ABOUT_LOGO from "../../../static/menu/SEAYUN_logo@2x.png";

import PaddingBox from "../PaddingBox";
import AboutForm from "./Form";
import Footer from "../Footer";

const header = [
  {
    img: ABOUT_ONE,
    title: "宗旨",
    color: "#4B83E4",
    content: ["让车主养车简简单单明明白白"],
  },
  {
    img: ABOUT_TWO,
    title: "使命",
    color: "#12C194",
    content: ["引领汽车后市场从传统运营模式向数智化运营模式转型升级"],
  },
  {
    img: ABOUT_THREE,
    title: "愿景",
    color: "#F69120",
    content: ["做中国最关注车主体验的公司"],
  },
];

const About = () => {
  useEffect(() => {
    window.location.hash = window.decodeURIComponent(window.location.hash);
    const scrollToAnchor = () => {
      const hashParts = window.location.hash.split("#");
      if (hashParts.length > 1) {
        // const offsetTop = document
        //   .getElementById("ask")
        //   ?.getBoundingClientRect().top;
        // console.log("offsetTop", offsetTop);
        // if (offsetTop) {
        //   window.scrollTo({
        //     top: offsetTop,
        //   });
        //   document.documentElement.scrollTop({});
        //   //   document.body.scrollTo({
        //   //     top: offsetTop,
        //   //   });
        // }
        setTimeout(() => {
          document.getElementById("ask").scrollIntoView();
        }, 100);
      }
    };
    scrollToAnchor();
    window.onhashchange = scrollToAnchor;
  });

  return (
    <Layout>
      <ImageWithContent
        img={BG}
        paddingTop={135}
        paddingLeft={40}
        paddingRight={20}
        contentJustify="flex-start"
        contentDirection="column"
      >
        <Text fontFamily="bold" fontSize={28} color="#FFFFFF">
          汽服生态系数智化
        </Text>
        <Text
          fontFamily="bold"
          fontSize={28}
          color="#FFFFFF"
          top={-6}
          bottom={11}
        >
          全栈解决方案提供商
        </Text>
        <Image src={LOGO} width={162} height={30} />
      </ImageWithContent>
      <PaddingBox top={20}>
        <Space direction="vertical" style={{ "--gap": "20px" }}>
          {header.map((item) => {
            return (
              <ImageWithContent
                img={item.img}
                paddingLeft={30}
                paddingRight={22}
                paddingBottom={21}
                paddingTop={21}
                contentJustify="flex-start"
                contentDirection="column"
              >
                <div
                  style={{
                    width: 68,
                    height: 28,
                    background: item.color,
                    borderRadius: 17,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text fontFamily="normal" fontSize={16} color="#FFFFFF">
                    {item.title}
                  </Text>
                </div>
                <div style={{ marginTop: 12 }}>
                  {item.content.map((ele) => {
                    return (
                      <Text color={item.color} fontSize={18} fontFamily="bold">
                        {ele}
                      </Text>
                    );
                  })}
                </div>
              </ImageWithContent>
            );
          })}
        </Space>
      </PaddingBox>
      <PaddingBox top={50} pad={25}>
        <Text fontSize={14} color="#343744" fontFamily="bold">
          成都深云智能科技有限公司
        </Text>
        <Text
          top={21}
          fontFamily="normal"
          fontSize={12}
          fontWeight={400}
          color="#343744"
        >
          成立于2018年10月，高新技术企业，定位是汽服生态系数智化全栈解决方案提供商，致力于使用大数据、算法、智能硬件、移动互联网等技术，围绕养车门店，以车主为中心，基于汽车全生命周期大数据对车辆养车需求进行精准诊断分级导流落地服务，大幅提升车主用车养车体验，提升行业的运营效率，实现汽车后市场产业链的标准化、在线化、数字化和智能化，引领汽车后市场从传统商业向智能商业转型升级。
        </Text>
        <Text
          top={20}
          fontFamily="normal"
          fontSize={12}
          fontWeight={400}
          color="#343744"
        >
          深云智能创始团队来自平安金融科技、利宝保险等企业，具备丰富的互联网、金融、汽车后市场等领域跨界整合经验，拥有优秀的创新能力。
        </Text>
      </PaddingBox>

      <PaddingBox top={30} pad={25}>
        <Swiper
          style={{
            "--border-radius": "8px",
            "--track-padding": " 0 0 25px",
          }}
          defaultIndex={0}
          indicatorProps={{
            style: {
              "--active-dot-color": "#12C194",
              "--active-dot-size": "34px",
              "--dot-color": "rgba(52,55,68,0.1)",
              "--dot-spacing": "8px",
              "--dot-size": "10px",
            },
          }}
        >
          <Swiper.Item>
            <Image src={ABOUT_US} />
          </Swiper.Item>
          <Swiper.Item>
            <Image src={ABOUT_US_TWO} />
          </Swiper.Item>
        </Swiper>
      </PaddingBox>

      <ImageWithContent
        img={ASK}
        top={40}
        id="ask"
        paddingLeft={30}
        paddingTop={103}
        contentJustify="flex-start"
        contentDirection="column"
      >
        <Text fontFamily="bold" fontSize={28} fontWeight={400} color="#343744">
          我要咨询
        </Text>
        <Text
          fontFamily="normal"
          fontSize={12}
          top={10}
          fontWeight={400}
          color="#343744"
          opacity={0.7}
        >
          请在下方留下您的联系方式
        </Text>
        <Text
          fontFamily="normal"
          fontSize={12}
          fontWeight={400}
          color="#343744"
          opacity={0.7}
        >
          我们将在1-3个工作日内与您联系
        </Text>
      </ImageWithContent>

      <PaddingBox
        pad={30}
        style={{ borderRadius: "0px 0px 15px 15px" }}
        top={70}
      >
        <AboutForm />
      </PaddingBox>

      <PaddingBox
        top={50}
        style={{ background: "rgba(244,248,251,0.99)" }}
        pt={15}
        pb={16}
      >
        <PaddingBox
          pt={50}
          pb={38}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            height: 190,
            width: "100%",
            borderRadius: 12,
            background: "#FFFFFF",
          }}
        >
          <Image src={ABOUT_LOGO}  height={25} />
          <Space style={{ "--gap": 10 }} direction="vertical">
            <Text
              fontSize={14}
              fontFamily="bold"
              color="#343744"
              style={{ textAlign: "center" }}
            >
              成都深云数智科技有限公司
            </Text>
            <Text
              fontSize={12}
              color="#343744"
              fontFamily="pingFang"
              style={{ textAlign: "center" }}
            >
              成都市高新区交子大道333号中海国际中心E座5楼
            </Text>
          </Space>
        </PaddingBox>
        <Footer style={{ marginTop: 63 }}></Footer>
      </PaddingBox>
    </Layout>
  );
};

export default About;
