import Menu from "./component/Menu";
import "./App.css";
import React from "react";
const Layout = (props, ref) => {
  const { children } = props;

  return (
    <>
      <Menu />
      {children}
    </>
  );
};

export default React.forwardRef(Layout);
