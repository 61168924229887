/* eslint-disable react-hooks/exhaustive-deps */
import { Space, Image } from "antd";
import React, { useEffect, useState } from "react";
import PageImage from "../PageImage";
import backgroundImg from "../../static/slider/jpg/home_page_two@2x.jpg";
import subLogo from "../../static/slider/page_two_sub_logo@2x.png";
import contentImg from "../../static/slider/page_two_right_img@2x.png";

import modelOne from "../../static/menu/ecology-create/model_one.png";
import modelTwo from "../../static/menu/ecology-create/model_two.png";
import modelThree from "../../static/menu/ecology-create/model_three.png";
import modelFour from "../../static/menu/ecology-create/model_four.png";

import storeOne from "../../static/menu/ecology-create/store_one.png";
import storeTwo from "../../static/menu/ecology-create/store_two.png";
import storeThree from "../../static/menu/ecology-create/store_three.png";
import storeFour from "../../static/menu/ecology-create/store_four.png";

import storeKeyOne from "../../static/menu/ecology-create/store_key_one.png";
import storeKeyTwo from "../../static/menu/ecology-create/store_key_two.png";

import Title from "../fontComponent/Title";

import PageFooter from "../PageFooter";
import Description from "../fontComponent/Description";

const createBgOne = [
  {
    img: modelOne,
    width: 700,
  },
  {
    img: modelTwo,
    width: 480,
  },
  {
    img: modelThree,
    width: 700,
  },
  {
    img: modelFour,
    width: 480,
  },
];

const store = [
  {
    img: storeOne,
  },
  {
    img: storeTwo,
  },
  {
    img: storeThree,
  },
  {
    img: storeFour,
  },
];

const key = [
  {
    img: storeKeyOne,
    width: 640,
  },
  {
    img: storeKeyTwo,
    width: 540,
  },
];

function EclologyCreate() {
  const handleScroll = (e) => {
    const rightOneEle = document.getElementById("right_one");
    const rightTwoEle = document.getElementById("right_two");
    const rightThreeEle = document.getElementById("right_three");

    isInViewPort(rightOneEle) && setAnimateOne(true);
    isInViewPort(rightTwoEle) && setAnimateTwo(true);
    isInViewPort(rightThreeEle) && setAnimateThree(true);
  };

  const isInViewPort = (element) => {
    // const viewWidth = window.innerWidth || document.documentElement.clientWidth;
    const viewHeight =
      window.innerHeight || document.documentElement.clientHeight;
    const { top } = element?.getBoundingClientRect();
    return top < viewHeight;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
    return window.removeEventListener("scroll", handleScroll);
  }, []);

  const [rightOne, setAnimateOne] = useState(false);
  const [rightTwo, setAnimateTwo] = useState(false);
  const [rightThree, setAnimateThree] = useState(false);

  return (
    <>
      <PageImage img={backgroundImg}>
        <Space direction="vertical" size={15} className="slide-right">
          <Image src={subLogo} width={250} height={55} preview={false} />
          <Space direction="vertical" className="page_one_title" size={1}>
            <div>社区一站式便捷养车连锁</div>
          </Space>
          <div className={`page_one_subTitle`}>重新定义养车门店</div>
        </Space>
        <Image
          className="slide-left"
          src={contentImg}
          width={492}
          preview={false}
          style={{ height: "860px" }}
        />
      </PageImage>
      <Space
        size={80}
        direction="vertical"
        style={{
          marginTop: 150,
          width: "100vw",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginBottom: 140,
        }}
      >
        <Space direction="vertical" size={1}>
          <Title fontSize={36}>门店形象创新</Title>
          <Description fontSize="16px">
            门店整体设计简洁、质感、时尚，突出高品质和专业性，对用户来说颜值即正义，容易获得年轻用户信任，和传统门店的形象形成差异化。
          </Description>
        </Space>
        <Space
          size={[0, 20]}
          id="right_one"
          className={rightOne ? "slide-in-bottom" : ""}
          style={{
            width: 1200,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
          wrap
        >
          {createBgOne.map((item) => {
            return (
              <Image
                key={Math.random()}
                src={item.img}
                width={item.width}
                height={218}
                preview={false}
              />
            );
          })}
        </Space>

        <Space
          direction="vertical"
          size={80}
          style={{
            marginTop: 70,
            width: 1200,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Space
            direction="vertical"
            size={1}
            style={{ width: 1012, display: "flex", alignItems: "center" }}
          >
            <Title fontSize={36}>门店选址</Title>
            <Description fontSize="16px" lineHeight="28px" height={28}>
              和有一定私域车流量的商业地产直属物业公司，成立合资公司或者合作，场地0租金或低租金，门店享受该社区私域车流量独家运营权，物业公司分享门店经营分红、股权增值收益、导流收益。
            </Description>
            <Description
              fontSize="12px"
              lineHeight="17px"
              height={17}
              top={80}
              position="relative"
            >
              已达成合作意向
            </Description>
          </Space>
        </Space>
        <Space
          id="right_two"
          className={rightTwo ? "slide-in-bottom" : ""}
          style={{
            width: 1200,
            marginTop: 10,

            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {store.map((item) => {
            return (
              <Image
                src={item.img}
                width={285}
                height={260}
                preview={false}
                key={Math.random()}
              />
            );
          })}
        </Space>

        <Space
          direction="vertical"
          width={1200}
          id="right_three"
          className={rightThree ? "slide-in-bottom" : ""}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Title fontSize={36}>养车更简单</Title>
          <Description fontSize={16}>
            在停车库电梯停入口处部署钥匙柜，停车后车主可通过钥匙柜进行闲时全托管养车，无接触交接车钥匙，养车安全便捷。
          </Description>
          <Space
            style={{
              marginTop: 100,
              width: "1200px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {key.map((item) => {
              return (
                <Image
                  key={Math.random()}
                  src={item.img}
                  width={item.width}
                  height={390}
                  preview={false}
                />
              );
            })}
          </Space>
        </Space>
      </Space>
      <PageFooter />
    </>
  );
}

export default EclologyCreate;
